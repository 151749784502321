import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { events } from '@tellonym/core/events'
import 'antd/dist/antd.css'
import { Style, StyleRoot as StyleProvider } from 'radium'
import React from 'react'
import ReactDOM from 'react-dom'
import Helmet from 'react-helmet'
import { Provider as StateProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import './globals'
import { globalStylesheet } from './modules/common'
import { setQueryClient } from './modules/common/queries'
import { setStore } from './modules/common/store'
import { Router } from './modules/navigation/components/Router'
import { configureStore } from './modules/store/configureStore'

const queryClient = new QueryClient()
setQueryClient(queryClient)

const [store, persistor] = configureStore()
setStore(store)
store.dispatch(events.init())

const App = () => {
  React.useEffect(() => {
    store.dispatch(events.mount())
  }, [])

  return (
    <StateProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <PersistGate loading={null} persistor={persistor}>
          <StyleProvider>
            <Style rules={globalStylesheet} />
            <Helmet
              title="Tellonym Modcp"
              meta={[
                { name: 'description', content: 'Tellonym Modcp' },
                { name: 'keywords', content: 'Tellonym, Modcp' },
                {
                  name: 'viewport',
                  content:
                    'width=device-width, initial-scale=1, minimum-scale=1.0, user-scalable=no',
                },
              ]}
              link={[
                { rel: 'stylesheet', href: '/main.css', type: 'text/css' },
              ]}
            />
            <Router />
          </StyleProvider>
        </PersistGate>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </StateProvider>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))

window.tnym.renderApp = () => {
  ReactDOM.render(null, document.getElementById('root'))
  ReactDOM.render(<App />, document.getElementById('root'))
}
