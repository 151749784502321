import { ClearOutlined, TagsOutlined } from '@ant-design/icons'
import { langDetectObjectsByType1 } from '@tellonym/enums/lib/Language'
import { ARTIFICIAL_TELL_GROUP_LANG_STATUS } from '@tellonym/enums/lib/Tell'
import { Button, Collapse, Input, Space } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { Container, View } from '../../common'
import { queryElementHeight } from '../../common/helpers'
import {
  addGroup,
  fetchGroups,
  refreshArtificialTells,
  refreshArtTellGroups,
  refreshGroups,
  setSearchString,
  setSearchStringArtTells,
  setSearchTags,
  setTableState,
} from '../actions'
import { camelizeString } from '../helpers'
import {
  getGroupsData,
  getHasMoreGroups,
  getIsFetchingGroups,
  getIsRefreshingGroups,
  getIsSaving,
  getSelectedLanguage,
  getTableState,
} from '../selectors'
import { ArtificalTellTable } from './ArtificialTellTable'
import { SelectorsLanguage } from './SelectorsLanguage'
import { TagCloud } from './TagCloud'

const Header = ({ actions, language, filters }) => {
  const [input, setInput] = useState(filters?.name?.[0] ?? '')

  const onSearch = useCallback(() => {
    actions.setTableState({
      filters: {
        name: [input],
      },
    })
  }, [input, filters])

  const onCreate = useCallback(() => {
    if (input) {
      actions.addGroup({
        language,
        groupLanguages: [
          {
            language: langDetectObjectsByType1[language],
            type: ARTIFICIAL_TELL_GROUP_LANG_STATUS.ACTIVE,
          },
        ],
        name: camelizeString(input),
        tags: filters?.id,
        title: input,
      })
    }
  }, [input, language, filters])

  const onPressTag = useCallback(
    (pressedTags) => {
      const filteredTags = new Set(filters?.id ?? [])
      // eslint-disable-next-line no-unused-vars
      for (const tag of pressedTags) {
        if (filteredTags.has(tag)) {
          filteredTags.delete(tag)
        } else {
          filteredTags.add(tag)
        }
      }

      actions.setTableState({
        filters: {
          id: Array.from(filteredTags),
        },
      })
    },
    [filters]
  )

  useEffect(() => {
    onSearch()
  }, [input])

  return (
    <View id="Header">
      <Collapse
        bordered={false}
        defaultActiveKey={[
          /** The .$ prefix fixes an undocumented antd library issue. */
          '.$Input',
          'Input',
          ...(filters?.id?.length ? ['.$TagCloud', 'TagCloud'] : []),
        ]}>
        <Collapse.Panel
          key="Input"
          showArrow={false}
          collapsible="disabled"
          style={{
            borderWidth: 0,
            borderColor: 'transparent',
          }}>
          <View
            style={{
              flex: 1,
              justifyContent: 'space-between',
              flexDirection: 'row',
              overflow: 'overlay',
            }}>
            <View style={{ flex: 1, flexDirection: 'row', paddingRight: 16 }}>
              <Input
                placeholder="Search..."
                allowClear
                size="large"
                onPressEnter={onSearch}
                onChange={(e) => {
                  setInput(e.target.value)
                }}
                value={input}
              />
              <Button
                disabled={input.length < 4}
                size="large"
                style={{ textAlign: 'center' }}
                onClick={onCreate}>
                Create
              </Button>
            </View>

            <Space />

            <View style={{ alignItems: 'flex-end' }}>
              <SelectorsLanguage />
            </View>
          </View>
        </Collapse.Panel>

        <Collapse.Panel
          key="TagCloud"
          header="Tags"
          extra={
            <View style={{ flexDirection: 'row' }}>
              <ClearOutlined
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  actions.setTableState({
                    filters: {
                      id: [],
                    },
                  })
                }}
                style={{
                  color: filters?.id?.length ? undefined : 'transparent',
                  marginRight: 16,
                }}
              />

              <TagsOutlined
                style={{
                  color: filters?.id?.length ? '#1890ff' : undefined,
                  marginRight: 8,
                }}
              />
            </View>
          }>
          <TagCloud
            onPressTag={onPressTag}
            selectedTagIds={filters?.id ?? []}
          />
        </Collapse.Panel>
      </Collapse>
    </View>
  )
}

const _PageArtificialTellsGroups = ({ actions, data, language, table }) => {
  const dataSource = useMemo(
    () => data.ids.map((id) => data.data[id]),
    [data.ids]
  )

  const [offset, setOffset] = useState(0)

  useEffect(() => {
    setOffset(queryElementHeight('#Header'))
  }, [])

  useEffect(() => {
    actions.refresh({ language })
  }, [language])

  const onTableChange = useCallback((pagination, filters, sorter) => {
    actions.setTableState({ pagination, filters, sorter })
  }, [])

  return (
    <Container>
      <Header actions={actions} language={language} filters={table?.filters} />
      <ArtificalTellTable
        dataSource={dataSource}
        language={language}
        offset={offset}
        onChange={onTableChange}
        filters={table?.filters}
        pagination={table?.pagination}
        sorter={table?.sorter}
      />
    </Container>
  )
}

const mapStateToProps = createStructuredSelector({
  data: getGroupsData,
  hasMore: getHasMoreGroups,
  isFetching: getIsFetchingGroups,
  isRefreshing: getIsRefreshingGroups,
  isSaving: getIsSaving,
  language: getSelectedLanguage,
  table: getTableState,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      addGroup,
      fetch: fetchGroups,
      refresh: refreshGroups,
      refreshArtificialTells,
      refreshArtTellGroups,
      setSearchString,
      setSearchStringArtTells,
      setSearchTags,
      setTableState,
    },
    dispatch
  ),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const PageArtificialTellsGroups = withConnect(_PageArtificialTellsGroups)
