import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from 'antd'
import { compose } from 'ramda'
import React, { useMemo } from 'react'
import { Icon, styleSheets, Text, theme, View } from '../../common'
import { shortenNumber } from '../services'

const RELEVANT_CHANGE_DELTA = 0.03

const CHANGE = {
  INCREASE: 'increase',
  DECREASE: 'decrease',
  NO_CHANGE: 'no-change',
  SMALL_DECREASE: 'small-decrease',
  SMALL_INCREASE: 'small-increase',
}

const styles = {
  changePercentage: {
    fontSize: theme.fontSizes.note,
    width: 55,
    textAlign: 'left',
  },
  changePercentageContainer: {
    marginLeft: 8,
  },
  changePercentageIcon: {
    alignSelf: 'center',
    fontSize: theme.fontSizes.note,
    marginRight: 4,
  },
  displayValue: { width: 60, textAlign: 'right' },
  missingValues: {
    backgroundColor: theme.colors.veryLightYellow,
    borderRadius: 6,
  },
}

const percentageMultiplyer = (isPercentage) => (num) =>
  isPercentage ? num * 100 : num

const withFixed = (isPercentage) => (num) => num.toFixed(isPercentage ? 1 : 2)

const withShorten = (isPercentage) => (num) =>
  isPercentage || num < 99 ? num : shortenNumber(num)

const getDisplayValue = (value, isPercentage = false) => {
  if (typeof value === 'undefined' || value === null) {
    return '-'
  }

  const _value = compose(
    withShorten(isPercentage),
    withFixed(isPercentage),
    percentageMultiplyer(isPercentage)
  )(value)

  return isPercentage ? `${_value}%` : _value
}

const getChange = ({ value, compareValue }) => {
  const increase = value - compareValue
  const percentageChange =
    compareValue === 0
      ? increase
      : value === 0
      ? -compareValue
      : increase / compareValue

  const changePercentage = Math.abs(percentageChange)

  // When no value was generated for the given day don't indicate a change
  if ((value > 0 && compareValue === 0) || (value === 0 && compareValue > 0)) {
    return { status: CHANGE.NO_CHANGE, changePercentage: undefined }
  }

  switch (true) {
    case percentageChange >= RELEVANT_CHANGE_DELTA:
      return { status: CHANGE.INCREASE, changePercentage }

    case percentageChange <= -RELEVANT_CHANGE_DELTA:
      return { status: CHANGE.DECREASE, changePercentage }

    case percentageChange > 0:
      return { status: CHANGE.SMALL_INCREASE, changePercentage }

    case percentageChange < 0:
      return { status: CHANGE.SMALL_DECREASE, changePercentage }

    default:
      return { status: CHANGE.NO_CHANGE, changePercentage }
  }
}

const getChangeConfig = ({
  value,
  compareValue,
  isHigherBetter,
  isPercentage,
}) => {
  if (typeof compareValue === 'undefined' || typeof value === 'undefined') {
    return {
      displayValue: '',
      icon: undefined,
      color: undefined,
    }
  }

  const { status, changePercentage } = getChange({
    value,
    compareValue,
    isPercentage,
  })

  const displayValue = getDisplayValue(changePercentage, true)

  switch (true) {
    case status === CHANGE.DECREASE && !isHigherBetter:
    case status === CHANGE.INCREASE && isHigherBetter:
      return { color: theme.colors.green, icon: faCaretUp, displayValue }

    case status === CHANGE.DECREASE && isHigherBetter:
    case status === CHANGE.INCREASE && !isHigherBetter:
      return { color: theme.colors.appleRed, icon: faCaretDown, displayValue }

    case status === CHANGE.SMALL_DECREASE && !isHigherBetter:
    case status === CHANGE.SMALL_INCREASE && isHigherBetter:
      return { color: theme.colors.placeholder, icon: faCaretUp, displayValue }

    case status === CHANGE.SMALL_DECREASE && isHigherBetter:
    case status === CHANGE.SMALL_INCREASE && !isHigherBetter:
      return {
        color: theme.colors.placeholder,
        icon: faCaretDown,
        displayValue,
      }

    default: {
      return { color: theme.colors.placeholder, icon: undefined, displayValue }
    }
  }
}

export const StatsItem = ({
  isPercentage,
  compareValue,
  isHigherBetter,
  value,
  warning,
}) => {
  const hasWarning = typeof warning === 'string'
  const displayValue = useMemo(
    () => getDisplayValue(value, isPercentage),
    [value, isPercentage]
  )
  const changeConfig = useMemo(
    () =>
      getChangeConfig({
        value,
        compareValue,
        isHigherBetter,
        isPercentage,
      }),
    [value, compareValue, isHigherBetter, isPercentage]
  )

  return (
    <Tooltip title={warning}>
      <View style={[styleSheets.flex.direction.row, styleSheets.center]}>
        <Text.Ant
          ellipsis={{ tooltip: displayValue }}
          style={{
            ...(hasWarning ? styles.missingValues : {}),
            ...styles.displayValue,
          }}>
          {displayValue}
        </Text.Ant>
        <View
          style={[
            styleSheets.flex.direction.row,
            styles.changePercentageContainer,
          ]}>
          {changeConfig.icon && (
            <Icon
              icon={changeConfig.icon}
              style={{
                ...styles.changePercentageIcon,
                color: changeConfig.color,
              }}
            />
          )}
          <Text.Ant
            ellipsis={{ tooltip: changeConfig.displayValue }}
            style={{
              ...styles.changePercentage,
              color: changeConfig.color,
            }}>
            {changeConfig.displayValue}
          </Text.Ant>
        </View>
      </View>
    </Tooltip>
  )
}
