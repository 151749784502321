import { API_CODE } from '@tellonym/enums/lib/ApiCode'
import { parseJson } from '../../api'
import { getIsLoggedIn } from '../../app/selectors'
import { isError, isResponse } from '../../validations'

export const errorHandlerMiddleware =
  ({ badConnectionHandler = () => {}, errorHandler = () => {} }) =>
  (store) =>
  (next) =>
  (action) => {
    if (action.type.includes('ERROR') === false) {
      return next(action)
    }

    const handle = (code, message, errors = []) =>
      errorHandler({
        code,
        dispatch: store.dispatch,
        errors,
        isLoggedIn: getIsLoggedIn(store.getState()),
        message,
        meta: action.meta,
        payload: action.payload,
        type: action.type,
      })

    if (isResponse(action.payload) && action.payload.status >= 500) {
      handle(API_CODE.INTERNAL_SERVER_ERROR)
    } else if (isResponse(action.payload)) {
      parseJson(action.payload.clone()).then(({ err }) => {
        handle(err?.code ?? 'NO_CODE', err?.msg ?? '', err?.errors)
      })
    } else if (
      isError(action.payload) &&
      /(Network request failed|Failed to fetch)/.test(action.payload.message)
    ) {
      badConnectionHandler({
        error: action.payload,
        dispatch: store.dispatch,
        getState: store.getState,
        meta: action.meta,
        type: action.type,
      })
    }

    return next(action)
  }
