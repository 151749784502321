import {
  COHORTS_CH,
  countriesToTrack,
  INTERVAL_TYPE,
} from '@tellonym/enums/lib/Stats'
import { Button, message, Typography } from 'antd'
import React from 'react'
import { Box, colors, styleSheets, View } from '../../common'
import { convertToOptions, getStringsFromEnums } from '../../common/helpers'
import { useQueryParams } from '../../common/hooks'
import { dateTypes } from '../constants'
import { useDiffMultiCountryMutation, useShortnamesQuery } from '../queries'
import {
  dateTypeToTimespan,
  downloadCsv,
  transformDiffDataToArray,
} from '../services'
import { InputQueryParam } from './InputQueryParam'
import { ShortNamesSelector } from './ShortNamesSelector'
import { TimeFramePickerQueryParam } from './TimeFramePicker'

const defaultQueryParams = {
  dateType: dateTypes.LAST_14_DAYS,
  startDate: undefined,
  endDate: undefined,
  countryCodes: ['DE'],
  experimentId: undefined,
  intervalType: INTERVAL_TYPE.DAILY,
  shortNames: [],
  cohort: undefined,
}

const styles = {
  container: { flex: 1, padding: 16, backgroundColor: colors.background },
  section: { marginBottom: 24, borderBottomWidth: 1 },
  timeSectionContainer: { marginBottom: 24 },
  paramBuilderContainer: {
    flexDirection: 'row',
    paddingHorizontal: '2%',
    paddingVertical: '1%',
  },
  metricsContainer: { flex: 3 },
  bottomMargin: { marginBottom: '2%' },
  rangePicker: { maxWidth: 300 },
  flex1: { flex: 1 },
  download: { alignSelf: 'center', marginTop: '4%' },
  uploadContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
}

const countryOptions = convertToOptions(countriesToTrack)

const cohortOptions = convertToOptions(getStringsFromEnums(COHORTS_CH))

export const PageCsv = () => {
  const [queryParams] = useQueryParams(defaultQueryParams)

  const { data: shortnames, isFetching: isFetchingShortnames } =
    useShortnamesQuery()

  const { isLoading, mutate } = useDiffMultiCountryMutation()

  const params = React.useMemo(() => {
    const { endDate, timespan } = dateTypeToTimespan(queryParams) ?? {}

    return {
      ...queryParams,
      countryCodes: Object.values(queryParams.countryCodes ?? {}), // fix countryCodes being an object when too long
      endDate,
      intervalType: Number(queryParams.intervalType),
      shortNames: Object.values(queryParams.shortNames ?? {}),
      timespan,
    }
  }, [queryParams])

  const refreshAndDownload = () => {
    const isValid =
      queryParams.shortNames.length > 0 &&
      Object.values(queryParams.countryCodes ?? {}).length > 0 // using Object.values to fix countryCodes being an object when too long

    if (!isValid) {
      message.error('At least one country and one shortname is required')
      return
    }

    mutate(params, {
      onSuccess: (data) => {
        try {
          const arrayData = data.reduce((acc, curr, index) => {
            const array = transformDiffDataToArray(curr, {
              additionalHeader: ['country'],
              additionalItems: [curr.countryCode],
            })

            // remove header from consecutive arrays
            if (index > 0) {
              array.shift()
            }

            acc.push(...array)

            return acc
          }, [])
          const csvData = arrayData.map((e) => e.join(',')).join('\n')

          downloadCsv(csvData)
        } catch (e) {
          console.error(e)
        }
      },
    })
  }

  return (
    <View style={styles.container}>
      <Typography.Title>CSV Builder</Typography.Title>

      <Box flexDirection="row">
        <View style={styleSheets.flex[1]}>
          <View style={styleSheets.margin.left[12]}>
            <TimeFramePickerQueryParam
              defaultQueryParams={defaultQueryParams}
              isLoading={isLoading}
            />

            <InputQueryParam
              defaultQueryParams={defaultQueryParams}
              isOptional
              isDisabled={isLoading}
              queryParamName="experimentId"
              text="Experiment Id"
              validateValue={(value) => Number.isInteger(Number(value))}
            />

            <InputQueryParam
              defaultQueryParams={defaultQueryParams}
              options={cohortOptions}
              isOptional
              isDisabled={isLoading}
              queryParamName="cohort"
              text="Cohort"
            />

            <InputQueryParam
              hasMultipleValues
              hasSelectAll
              defaultQueryParams={defaultQueryParams}
              options={countryOptions}
              isDisabled={isLoading}
              queryParamName="countryCodes"
              text="Countries"
            />
          </View>
        </View>

        <View style={[styleSheets.flex[2], styleSheets.margin.left[24]]}>
          <ShortNamesSelector
            groups={shortnames}
            isRefreshing={isFetchingShortnames}
          />
        </View>
      </Box>

      <Button
        type="primary"
        disabled={isLoading}
        loading={isLoading}
        onClick={refreshAndDownload}
        style={styles.download}>
        Download CSV
      </Button>
    </View>
  )
}
