export const CHANGE_STATE = 'posts/CHANGE_STATE'

export const CREATE_POST = 'posts/CREATE_POST'
export const CREATE_POST_ERROR = 'posts/CREATE_POST_ERROR'
export const CREATE_POST_SUCCESS = 'posts/CREATE_POST_SUCCESS'

export const FETCH_TAGGED_ANSWERS = 'posts/FETCH_TAGGED_ANSWERS'
export const FETCH_TAGGED_ANSWERS_ERROR = 'posts/FETCH_TAGGED_ANSWERS_ERROR'
export const FETCH_TAGGED_ANSWERS_SUCCESS = 'posts/FETCH_TAGGED_ANSWERS_SUCCESS'

export const FETCH_TAGGED_TELLS = 'posts/FETCH_TAGGED_TELLS'
export const FETCH_TAGGED_TELLS_ERROR = 'posts/FETCH_TAGGED_TELLS_ERROR'
export const FETCH_TAGGED_TELLS_SUCCESS = 'posts/FETCH_TAGGED_TELLS_SUCCESS'

export const PIN_POST = 'posts/PIN_POST'
export const PIN_POST_ERROR = 'posts/PIN_POST_ERROR'
export const PIN_POST_SUCCESS = 'posts/PIN_POST_SUCCESS'

export const REFRESH_POST = 'posts/REFRESH_POST'
export const REFRESH_POST_ERROR = 'posts/REFRESH_POST_ERROR'
export const REFRESH_POST_SUCCESS = 'posts/REFRESH_POST_SUCCESS'

export const REFRESH_TAGGED_ANSWERS = 'posts/REFRESH_TAGGED_ANSWERS'
export const REFRESH_TAGGED_ANSWERS_ERROR = 'posts/REFRESH_TAGGED_ANSWERS_ERROR'
export const REFRESH_TAGGED_ANSWERS_SUCCESS = 'posts/REFRESH_TAGGED_ANSWERS_SUCCESS' // prettier-ignore

export const REFRESH_TAGGED_TELLS = 'posts/REFRESH_TAGGED_TELLS'
export const REFRESH_TAGGED_TELLS_ERROR = 'posts/REFRESH_TAGGED_TELLS_ERROR'
export const REFRESH_TAGGED_TELLS_SUCCESS = 'posts/REFRESH_TAGGED_TELLS_SUCCESS'

export const REMOVE_POST = 'posts/REMOVE_POST'
export const REMOVE_POST_ERROR = 'posts/REMOVE_POST_ERROR'
export const REMOVE_POST_SUCCESS = 'posts/REMOVE_POST_SUCCESS'

export const UNPIN_POST = 'posts/UNPIN_POST'
export const UNPIN_POST_ERROR = 'posts/UNPIN_POST_ERROR'
export const UNPIN_POST_SUCCESS = 'posts/UNPIN_POST_SUCCESS'
