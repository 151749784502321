import { langDetectObjectsByType1 } from '@tellonym/enums/lib/Language'
import { normalize } from '@tellonym/core/helpers'
import {
  assocPath,
  concat,
  evolve,
  identity,
  mergeDeepLeft,
  mergeDeepRight,
  mergeRight,
  pathOr,
  pick,
  __,
} from 'ramda'
import { getNavigationTableState } from '../common/components/TableEndlessScroll'
import { languageEnumAsString } from '../common/helpers'
import { name, supportedLanguages } from './constants'
import * as t from './types'

export { name }

export const groupInitialState = {
  id: undefined,
  name: undefined,
  hasMore: false,
  isFetching: false,
  isRefreshing: false,
  tags: [],
  ids: [],
  data: {},
}

export const languageGroupsInitialState = {
  hasMore: false,
  hasRefreshed: false,
  isFetching: false,
  isRefreshing: false,
  ids: [],
  data: {},
  suggestions: { hasMore: false, ids: [], data: {} },
}

const initialLanguage = (() => {
  const [, , language = 'en'] = window.location.pathname.split('/')
  const isValidLanguage = langDetectObjectsByType1[language]

  return isValidLanguage ? language : 'en'
})()

export const initialState = {
  answers: {
    artificialTell: {},
    hasMore: false,
    isFetching: false,
    isRefreshing: false,
    data: { ids: [], data: {} },
  },
  groups: {
    orderTypes: [],
    en: languageGroupsInitialState,
    [initialLanguage]: languageGroupsInitialState,
  },
  artificialTellGroups: {
    hasMore: false,
    isFetching: false,
    isRefreshing: false,
    searchString: undefined,
    searchTags: undefined,
    data: { ids: [], data: {} },
  },
  tags: {
    groups: { ids: [], data: [] },
    hasMore: false,
    isFetching: false,
    isRefreshing: false,
    orderTypes: [],
    selectedTagId: undefined,
    selectedTagIds: [],
    data: { ids: [], data: {} },
    tags: [],
  },
  artificialTells: {
    hasMore: false,
    isFetching: false,
    isRefreshing: false,
    searchString: undefined,
    data: { data: {}, ids: [] },
  },
  error: null,
  hasSearched: false,
  hasSearchedGroups: false,
  isLoading: false,
  isSaving: false,
  selectedGroupId: undefined,
  selectedLanguage: initialLanguage,
  table: (() => {
    let state = {
      filters: {},
      pagination: {},
      sorter: {},
    }

    if (window.location.pathname.includes('artificialtells/')) {
      state = {
        ...state,
        ...getNavigationTableState(),
      }
    }

    return state
  })(),
}

const persistKeys = ['groups']

export const persistence = {
  in: pick(persistKeys),
  out: identity,
}

export const reducer = (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case t.ADD:
    case t.ADD_GROUP:
    case t.DECLINE:
    case t.REVIEW:
      return {
        ...state,
        error: undefined,
        isSaving: true,
      }

    case t.ADD_ERROR:
    case t.ADD_GROUP_ERROR:
    case t.EDIT_ERROR:
    case t.DECLINE_ERROR:
    case t.REVIEW_ERROR:
      return {
        ...state,
        error: payload,
        isSaving: false,
      }

    case t.ADD_SUCCESS:
    case t.ADD_GROUP_SUCCESS:
    case t.EDIT_SUCCESS:
    case t.DECLINE_SUCCESS:
      return {
        ...state,
        isSaving: false,
      }

    case t.ADD_LANGUAGE:
    case t.REMOVE_LANGUAGE:
      return mergeDeepRight(state, {
        groups: {
          [payload.groupLanguage]: {
            data: {
              [payload.groupId]: {
                languages:
                  type === t.ADD_LANGUAGE
                    ? (
                        state.groups[payload.groupLanguage]?.data?.[
                          payload.groupId
                        ]?.languages ?? []
                      ).concat(payload.languages)
                    : state.groups[payload.groupLanguage]?.data?.[
                        payload.groupId
                      ]?.languages?.filter(
                        (lang) =>
                          !(
                            lang.type === payload.languages[0]?.type &&
                            lang.language === payload.languages[0]?.language
                          )
                      ),
              },
            },
          },
        },
      })

    case t.FETCH: {
      const language = languageEnumAsString[meta.language]

      return mergeDeepRight(state, {
        groups: {
          [language]: {
            data: { [meta.groupId]: { isFetching: true } },
          },
        },
        error: undefined,
      })
    }

    case t.FETCH_SUCCESS: {
      const { group, artificialTells } = payload
      const { data, hasMore } = artificialTells
      const { groupId, language: _language } = meta
      const language = languageEnumAsString[_language]

      const normalized = normalize(data)

      const mergedData = evolve({
        data: mergeDeepLeft(normalized.data),
        ids: concat(__, normalized.ids),
      })(state.groups[language].data[groupId])

      const modifiedState = mergeDeepRight(state, {
        groups: {
          [language]: {
            data: {
              [groupId]: {
                ...group,
                ...mergedData,
                hasMore,
              },
            },
          },
        },
      })

      return assocPath(
        ['groups', language, 'data', groupId, 'isFetching'],
        false,
        modifiedState
      )
    }

    case t.FETCH_ERROR: {
      const language = languageEnumAsString[meta.language]

      return mergeDeepRight(state, {
        groups: {
          [language]: {
            data: { [meta.groupId]: { isFetching: false } },
          },
        },
        error: payload,
      })
    }

    case t.FETCH_ANSWERS:
      return {
        ...state,
        answers: {
          ...state.answers,
          isFetching: true,
        },
        error: undefined,
      }

    case t.FETCH_ANSWERS_ERROR:
      return {
        ...state,
        answers: {
          ...state.answers,
          isFetching: false,
        },
        error: payload,
      }

    case t.FETCH_ANSWERS_SUCCESS: {
      const { data, hasMore } = payload.answers
      const normalized = normalize(data)

      return {
        ...state,
        answers: {
          ...state.answers,
          isFetching: false,
          hasMore,
          data: {
            ids: [...state.answers.data.ids, ...normalized.ids],
            data: { ...state.answers.data.data, ...normalized.data },
          },
        },
      }
    }

    case t.FETCH_GROUP_SUGGESTIONS:
    case t.FETCH_GROUPS: {
      const language = languageEnumAsString[meta.language]

      return assocPath(['groups', language, 'isFetching'], true, state)
    }

    case t.FETCH_GROUP_SUGGESTIONS_ERROR:
    case t.FETCH_GROUPS_ERROR: {
      const language = languageEnumAsString[meta.language]

      return mergeDeepRight(state, {
        groups: { [language]: { isFetching: false }, error: payload },
      })
    }

    case t.FETCH_GROUPS_SUCCESS: {
      const { data, hasMore } = payload.groups
      const normalized = normalize(data)
      const language = languageEnumAsString[meta.language]

      return mergeDeepRight(state, {
        groups: {
          [language]: {
            hasMore,
            isFetching: false,
            ids: [...state.groups[language].ids, ...normalized.ids],
            data: normalized.data,
          },
        },
      })
    }

    case t.FETCH_GROUP_SUGGESTIONS_SUCCESS: {
      const { data, hasMore } = payload.suggestedGroups
      const normalized = normalize(data)
      const language = languageEnumAsString[meta.language]

      return mergeDeepRight(state, {
        groups: {
          [language]: {
            isFetching: false,
            suggestions: {
              hasMore,
              ids: [
                ...state.groups[language].suggestions.ids,
                ...normalized.ids,
              ],
              data: normalized.data,
            },
          },
        },
      })
    }

    case t.FETCH_ART_TELL_GROUPS:
      return assocPath(['artificialTellGroups', 'isFetching'], true, state)

    case t.FETCH_ART_TELL_GROUPS_SUCCESS: {
      const { data, hasMore } = payload.artificialTellGroups
      const normalized = normalize(data)

      const mergedData = evolve({
        data: mergeDeepLeft(normalized.data),
        ids: concat(__, normalized.ids),
      })(state.artificialTellGroups.data)

      return mergeDeepRight(state, {
        artificialTellGroups: {
          hasMore,
          isFetching: false,
          data: mergedData,
        },
      })
    }

    case t.FETCH_ART_TELL_GROUPS_ERROR:
      return mergeDeepRight(state, {
        artificialTellGroups: {
          isFetching: false,
        },
        error: payload,
      })

    case t.REFRESH:
    case t.REFRESH_SUGGESTIONS:
      return mergeDeepRight(state, {
        groups: {
          [languageEnumAsString[meta.language]]: {
            data: {
              [meta.groupId]: { isRefreshing: true },
            },
          },
          [meta.originLanguage]: {
            data: {
              [meta.groupId]: { isRefreshing: true },
            },
          },
        },
        error: undefined,
      })

    case t.EDIT: {
      const {
        artificialTellIds,
        groupId,
        language: _language,
        tellContent: content,
      } = payload
      const language = languageEnumAsString[_language]
      const artificialTellId = artificialTellIds[0]

      return mergeDeepRight(state, {
        error: undefined,
        isSaving: true,
        groups: {
          [language]: {
            data: {
              [groupId]: {
                data: {
                  [artificialTellId]: {
                    content,
                  },
                },
              },
            },
          },
        },
      })
    }

    case t.EDIT_GROUP_SUCCESS:
      return mergeDeepRight(state, {
        groups: {
          [state.selectedLanguage]: {
            data: {
              [meta.payload.id]: meta.payload,
            },
          },
        },
      })

    case t.REFRESH_SUCCESS:
    case t.REFRESH_SUGGESTIONS_SUCCESS: {
      const { group, artificialTells } = payload
      const { data, hasMore } = artificialTells
      const { groupId, language: _language } = meta
      const language = languageEnumAsString[_language]
      const refreshLanguage = meta.originLanguage || language

      const normalized = normalize(data)

      const mergedGroup = mergeRight(groupInitialState, {
        ...group,
        ...normalized,
        hasMore,
      })

      const modifiedState = mergeDeepRight(state, {
        groups: {
          [language]: {
            data: {
              [groupId]: mergedGroup,
            },
          },
        },
      })

      return assocPath(
        ['groups', refreshLanguage, 'data', groupId, 'isRefreshing'],
        false,
        modifiedState
      )
    }

    case t.REFRESH_ERROR:
    case t.REFRESH_SUGGESTIONS_ERROR: {
      return mergeDeepRight(state, {
        groups: {
          [languageEnumAsString[meta.language]]: {
            data: { [meta.groupId]: { isRefreshing: false } },
          },
          [meta.originLanguage]: {
            data: { [meta.groupId]: { isRefreshing: false } },
          },
        },
        error: payload,
      })
    }

    case t.REFRESH_ANSWERS:
      return {
        ...state,
        answers: {
          ...state.answers,
          isRefreshing: true,
        },
        error: undefined,
      }

    case t.REFRESH_ANSWERS_SUCCESS: {
      const { data, hasMore } = payload.answers

      return {
        ...state,
        answers: {
          ...state.answers,
          artificialTell: payload.artificialTell,
          isRefreshing: false,
          hasMore,
          data: normalize(data),
        },
      }
    }

    case t.REFRESH_ANSWERS_ERROR:
      return {
        ...state,
        answers: {
          ...state.answers,
          isRefreshing: false,
        },
        error: payload,
      }

    case t.REFRESH_GROUP_SUGGESTIONS:
    case t.REFRESH_GROUPS: {
      const language = languageEnumAsString[meta.language]

      return mergeDeepRight(state, {
        groups: { [language]: { isRefreshing: true } },
        error: undefined,
      })
    }

    case t.REFRESH_GROUPS_SUCCESS: {
      const { data, hasMore } = payload.groups
      const language = languageEnumAsString[meta.language]

      const normalized = normalize(data)

      const normalizedDataWithInitialState = normalized.ids.reduce(
        (acc, id) => ({
          ...acc,
          [id]: {
            ...groupInitialState,
            ...pathOr({}, ['groups', language, 'data', id], state),
            ...normalized.data[id],
          },
        }),
        {}
      )

      const groupLanguageState = {
        hasMore,
        hasRefreshed: true,
        isRefreshing: false,
        ids: normalized.ids,
        data: normalizedDataWithInitialState,
      }

      return mergeDeepRight(state, {
        groups: {
          [language]: groupLanguageState,
        },
      })
    }

    case t.REFRESH_GROUP_SUGGESTIONS_ERROR:
    case t.REFRESH_GROUPS_ERROR: {
      const language = languageEnumAsString[meta.language]

      return mergeDeepRight(state, {
        groups: {
          [language]: { isRefreshing: false },
        },
        error: payload,
      })
    }

    case t.REFRESH_GROUP_SUGGESTIONS_SUCCESS: {
      const { amount, suggestedGroups = {} } = payload
      const { data, hasMore } = suggestedGroups
      const normalized = normalize(data)

      const groupLanguageState = {
        isRefreshing: false,
        suggestions: {
          amount,
          hasMore,
          ids: normalized.ids,
          data: normalized.data,
        },
      }

      const language = languageEnumAsString[meta.language]

      return mergeDeepRight(state, {
        groups: {
          [language]: groupLanguageState,
        },
      })
    }

    case t.REFRESH_ART_TELL_GROUPS:
      return {
        ...state,
        artificialTellGroups: {
          ...initialState.artificialTellGroups,
          ...state.artificialTellGroups,
          isRefreshing: true,
        },
        hasSearched: false,
        hasSearchedGroups: true,
      }

    case t.REFRESH_ART_TELL_GROUPS_SUCCESS: {
      const { data, hasMore } = payload.artificialTellGroups
      const normalized = normalize(data)

      return {
        ...state,
        artificialTellGroups: {
          ...state.artificialTellGroups,
          hasMore,
          isRefreshing: false,
          data: normalized,
        },
      }
    }

    case t.REFRESH_ART_TELL_GROUPS_ERROR:
      return {
        ...state,
        artificialTellGroups: {
          ...initialState.artificialTellGroups,
          isRefreshing: false,
        },
        error: payload,
        isSaving: false,
      }

    /**
     * Artificial Tells
     */

    case t.FETCH_ARTIFICIAL_TELLS:
      return assocPath(['artificialTells', 'isFetching'], true, state)

    case t.FETCH_ARTIFICIAL_TELLS_ERROR:
      return mergeDeepRight(state, {
        artificialTells: {
          isFetching: false,
        },
        error: payload,
      })

    case t.FETCH_ARTIFICIAL_TELLS_SUCCESS: {
      const { data, hasMore } = payload.artificialTells
      const normalized = normalize(data)

      const mergedData = evolve({
        data: mergeDeepLeft(normalized.data),
        ids: concat(__, normalized.ids),
      })(state.artificialTells.data)

      return mergeDeepRight(state, {
        artificialTells: {
          hasMore,
          isFetching: false,
          data: mergedData,
        },
      })
    }

    case t.REFRESH_ARTIFICIAL_TELLS: {
      return {
        ...state,
        artificialTells: {
          ...initialState.artificialTells,
          ...state.artificialTells,
          isRefreshing: true,
        },
        hasSearched: true,
        hasSearchedGroups: false,
      }
    }

    case t.REFRESH_ARTIFICIAL_TELLS_ERROR:
      return {
        ...state,
        artificialTells: {
          ...initialState.artificialTells,
          isRefreshing: false,
        },
        error: payload,
        isSaving: false,
      }

    case t.REFRESH_ARTIFICIAL_TELLS_SUCCESS: {
      const { data, hasMore } = payload.artificialTells
      const normalized = normalize(data)

      return {
        ...state,
        artificialTells: {
          ...state.artificialTells,
          hasMore,
          isRefreshing: false,
          data: normalized,
        },
        hasSearched: true,
      }
    }

    case t.SET_SEARCH_STRING_ART_TELLS:
      return assocPath(['artificialTells', 'searchString'], payload, state)

    case t.REFRESH_TAGS:
      return assocPath(['tags', 'isRefreshing'], true, state)

    case t.REFRESH_TAGS_ERROR:
      return mergeDeepRight(state, {
        tags: { isRefreshing: false },
        error: payload,
      })

    case t.REFRESH_TAGS_SUCCESS: {
      const { data, hasMore } = payload.groups

      const normalizedGroups = normalize(data)

      const tagData = normalizedGroups.ids.reduce(
        (acc, groupId) => {
          const group = normalizedGroups.data[groupId]

          if (group.tags.length === 0) {
            if (acc.none?.groups) {
              acc.none.groups.push(group)
            } else {
              acc.none = { groups: [group] }
            }
            return acc
          }

          const accWithCurrentGroup = group.tags.reduce((groupAcc, tag) => {
            groupAcc[tag] = groupAcc[tag] ?? {}
            groupAcc[tag].id = tag
            groupAcc[tag].name = tag

            if (groupAcc[tag].groups) {
              groupAcc[tag].groups.push(group)
            } else {
              groupAcc[tag].groups = [group]
            }

            return groupAcc
          }, acc)

          return accWithCurrentGroup
        },
        { none: { groups: [] } }
      )

      const result = {
        ids: Object.keys(tagData),
        data: tagData,
      }

      return {
        ...state,
        tags: { ...state.tags, data: result, hasMore, isRefreshing: false },
      }
    }

    case t.GET_GROUPS_FOR_TAGS: {
      return {
        ...state,
        tags: {
          ...state.tags,
          selectedTagIds: payload.tags,
        },
      }
    }

    case t.GET_GROUPS_FOR_TAGS_SUCCESS: {
      const { data } = payload.artificialTellGroups
      const normalized = normalize(data)

      return {
        ...state,
        tags: {
          ...state.tags,
          groups: normalized,
        },
      }
    }

    case t.GET_TAGS_SUCCESS:
      return mergeDeepRight(state, {
        tags: {
          isRefreshing: false,
          tags: payload.tags.data,
        },
      })

    case t.DECLINE_GROUP:
      return state

    case t.DECLINE_GROUP_ERROR:
      return { ...state, error: payload }

    case t.DECLINE_GROUP_SUCCESS:
      return state

    case t.REVIEW_SUCCESS: {
      const language = languageEnumAsString[meta.language]

      return mergeDeepRight(state, {
        isSaving: false,
        groups: {
          [language]: {
            data: {
              [meta.groupId]: {
                isReviewed: meta.isReviewed,
              },
            },
          },
        },
      })
    }

    case t.SET_GROUPS_ORDER_TYPES:
      return assocPath(['groups', 'orderTypes'], payload, state)

    case t.SET_SEARCH_STRING:
      return assocPath(['artificialTellGroups', 'searchString'], payload, state)

    case t.SET_SEARCH_TAGS:
      return assocPath(['artificialTellGroups', 'searchTags'], payload, state)

    case t.SET_SELECTED_GROUP_ID:
      return { ...state, selectedGroupId: payload }

    case t.SET_SELECTED_LANGUAGE: {
      if (supportedLanguages.includes(payload)) {
        const languageGroupState = mergeRight(
          languageGroupsInitialState,
          state.groups[payload]
        )

        const mergedState = mergeDeepRight(state, {
          selectedLanguage: payload,
          groups: {
            [payload]: languageGroupState,
          },
        })

        return mergedState
      }

      return { ...state, selectedLanguage: 'en' }
    }

    case t.SET_TABLE_STATE:
      return mergeDeepRight(state, {
        table: payload,
      })

    case t.SET_TAGS_ORDER_TYPES:
      return assocPath(['tags', 'orderTypes'], payload, state)

    default:
      return state
  }
}
