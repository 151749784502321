import { Stats } from '@tellonym/enums'

export const pages = [
  { to: '/stats/all', name: 'All Stats' },
  { to: '/stats/activation', name: 'Activation' },
  { to: '/stats/activity', name: 'Activity' },
  { to: '/stats/likes', name: 'Likes' },
  { to: '/stats/network', name: 'Network' },
  { to: '/stats/notifications', name: 'Notifications' },
  { to: '/stats/posts', name: 'Posts' },
  { to: '/stats/reports', name: 'Reports' },
  { to: '/stats/tells', name: 'Tells' },
  { to: '/stats/userDevices', name: 'User Devices' },
  { to: '/stats/userSettings', name: 'User Settings' },
  { to: '/stats/retention', name: 'Retention' },
  { to: '/stats/tellAmount', name: 'Tell Amount' },
  { to: '/stats/pushNotifications', name: 'Push Notifications' },
  { to: '/stats/artificialTellLists', name: 'Artificial Tell Lists' },
]

export const statisticsRouteMap = {
  activation: {
    title: 'Activation',
    sections: Stats.typesActivation,
  },
  activity: {
    title: 'Activity',
    sections: Stats.typesActivity,
  },
  artificialTellLists: {
    title: 'Artificial Tell Lists',
    sections: Stats.artificialTellLists,
  },
  likes: {
    title: 'Likes',
    sections: Stats.typesLikes,
  },
  network: {
    title: 'Network',
    sections: Stats.typesNetwork,
  },
  notifications: {
    title: 'Notifications',
    sections: Stats.typesNotifications,
  },
  posts: {
    title: 'Posts',
    sections: Stats.typesPosts,
  },
  pushNotifications: {
    title: 'Push Notifications',
    sections: Stats.typesPushNotifications,
  },
  reports: {
    title: 'Reports',
    sections: Stats.typesReports,
  },
  retention: {
    title: 'Retention',
    sections: Stats.typesRetention,
  },
  tellAmount: {
    title: 'Tell Amount',
    sections: Stats.typesTellAmount,
  },
  tells: {
    title: 'Tells',
    sections: Stats.typesTells,
  },
  userDevices: {
    title: 'User Devices',
    sections: Stats.typesUserDevices,
  },
  userSettings: {
    title: 'User Settings',
    sections: Stats.typesUserSettings,
  },
}

export const shortNamesCombinations = [
  {
    name: 'General Overview',
    shortNames: [
      'U30D',
      'Activity-Active',
      'Activity-Tell-Sent-Other',
      'Activity-Tell-Received-Organic',
      'Activity-AmountPerDau-Tell-Sent-Other',
      'Activity-Tell-Answered',
      'Activity-ShareAction',
      'Activity-AmountPerDau-Tell-Answered',
      'Activity-AmountPerDau-Tell-Answered-Bot',
      'Activity-AmountPerDau-Tell-Received-Bot',
      'Activity-Following-New',
      'NU24H',
      'NU24HiOS',
      'NU24HAndroid',
      'PCTDauPNActivatediOS',
      'PCTDauPNActivatedAndroid',
    ],
  },
  {
    name: 'Activation',
    shortNames: [
      'Activity-Active',
      'NU24H',
      'NU24HiOS',
      'NU24HAndroid',
      'Activation-Tell-Sent-Other',
      'Activation-Tell-Sent-Self',
      'Activation-Tell-Received-Organic',
      'Activation-CB-Added',
      'Activation-Tell-Answered',
      'Activation-ShareAction',
      'Activation-ShareAction-AnswerOwn',
      'Activation-ShareAction-ProfileOwn',
      'Activation-Following-Min1',
      'Activation-Follower-Min1',
      'Activation-Avatar-Added',
      'Activation-Gender-Added',
      'Activation-Occupation-Added',
      'Activation-CB-Or-Phone-Added',
      'Activation-Phone-Added',
      'Activation-SocialLink-Added',
      'Activation-Status-Added',
      'Activation-Emoji-Added',
      'Activation-TintColor-Added',
      'Activation-Location-Permission',
      'Activation-Login-AppleOrGoogle',
      'RetentionBW-D01',
      'RetentionBW-D02',
      'RetentionBW-D03',
      'RetentionBW-D04',
      'RetentionBW-D05',
      'RetentionBW-D06',
      'RetentionBW-D07',
    ],
  },
  {
    name: 'Push Notifications Activational',
    shortNames: [
      'PushNType42',
      'PushNOpeningRateActive1DType42',
      'PushNType43',
      'PushNOpeningRateActive1DType43',
      'PushNType41',
      'PushNOpeningRateActive1DType41',
    ],
  },
  {
    name: 'Retention Backwards',
    shortNames: [
      'RetentionBW-D01',
      'RetentionBW-D02',
      'RetentionBW-D03',
      'RetentionBW-D05',
      'RetentionBW-D07',
      'RetentionBW-D14',
      'RetentionBW-D21',
      'RetentionBW-D30',
      'RetentionBW-D60',
      'RetentionBW-D90',
    ],
  },
  {
    name: 'Sent and Received Tells',
    shortNames: [
      'Activity-Active',
      'Activity-Tell-Sent',
      'Activity-Tell-Sent-Other',
      'Activity-Tell-Sent-Self',
      'Activity-AvgAmount-Tell-Sent',
      'Activity-AvgAmount-Tell-Sent-Other',
      'Activity-AvgAmount-Tell-Sent-Self',
      'Activity-Tell-Received',
      'Activity-Tell-Received-Organic',
      'Activity-Tell-Received-Organic-Reg',
      'Activity-Tell-Received-Organic-NotReg',
      'Activity-Tell-Received-Organic-Public',
      'Activity-Tell-Received-Bot',
      'Activity-Tell-Received-OnlyBot',
      'Activity-AvgAmount-Tell-Received',
      'Activity-AvgAmount-Tell-Received-Organic',
      'Activity-AvgAmount-Tell-Received-Organic-Reg',
      'Activity-AvgAmount-Tell-Received-Organic-NotReg',
      'Activity-AvgAmount-Tell-Received-Organic-Public',
      'Activity-AvgAmount-Tell-Received-Bot',
      'Activity-AvgAmount-Tell-Received-OnlyBot',
      'NT24H',
      'NT24HOrganic',
      'NT24HBot',
      'PCTATellsBot',
      'PCTATellsOrganic',
      'PCTATellsNonAnonymous',
      'PCTTellsSelf',
      'PCTTellsNonAnonymous',
      'PCTTellsFromRegistered',
      'PCTTellsFromNonRegistered',
    ],
  },
  {
    name: 'Answered Tell',
    shortNames: [
      'Activity-Active',
      'Activity-Tell-Received-Organic',
      'Activity-Tell-Received-Organic-NotReg',
      'Activity-Tell-Received-Bot',
      'Activity-Tell-Answered',
      'Activity-Tell-Answered-Organic',
      'Activity-Tell-Answered-Bot',
      'Activity-AvgAmount-Tell-Answered',
      'Activity-AvgAmount-Tell-Answered-Organic',
      'Activity-AvgAmount-Tell-Answered-Bot',
      'NP24H',
      'NP24HAnswer',
      'PCTAnswersToTellsWithin5MIN',
      'PCTAnswersToTellsWithin1H',
      'PCTAnswersToTellsWithin1D',
      'NP24HRequestTell',
    ],
  },
  {
    name: 'Sharing',
    shortNames: [
      'Activity-Active',
      'Activity-ShareAction',
      'Activity-ShareAction-Screenshot',
      'Activity-ShareAction-AnswerOwn',
      'Activity-ShareAction-ProfileOwn',
      'Activity-AvgAmount-ShareAction',
      'Activity-AvgAmount-ShareAction-Screenshot',
      'Activity-AvgAmount-ShareAction-AnswerOwn',
      'Activity-AvgAmount-ShareAction-ProfileOwn',
    ],
  },
  {
    name: 'Like and RT Post',
    shortNames: [
      'Activity-Active',
      'Activity-Liked-Content',
      'Activity-Liked-Content-Other',
      'Activity-AvgAmount-Liked-Content',
      'Activity-AvgAmount-Liked-Content-Other',
      'Activity-RTPost-Created',
      'Activity-ShareAction-RTPostOwn',
      'Activity-AvgAmount-RTPost-Created',
      'Activity-AvgAmount-ShareAction-RTPostOwn',
    ],
  },
  {
    name: 'Follow',
    shortNames: [
      'Activity-Active',
      'Activity-Follower-Min1',
      'Activity-Following-Min1',
      'Activity-Following-New',
      'Activity-Following-New-Anon',
      'Activity-Following-UsesNotification',
    ],
  },
  {
    name: 'Attributes',
    shortNames: [
      'Activity-Active',
      'Activity-Avatar-Added',
      'Activity-Gender-Added',
      'Activity-Occupation-Added',
      'Activity-CB-Or-Phone-Added',
      'Activity-Phone-Added',
      'Activity-SocialLink-Added',
      'Activity-Status-Added',
      'Activity-Emoji-Added',
      'Activity-TintColor-Added',
      'Activity-Location-Permission',
      'Activity-Login-Apple',
      'Activity-Login-Google',
      'Activity-Login-AppleOrGoogle',
    ],
  },
  {
    name: 'Push Notifications DAUs',
    shortNames: [
      'PCTDauPNActivatediOS',
      'PCTDauPNActivatedAndroid',
      'PushNType31',
      'PushNOpeningRateActive1DType31',
      'PushNCoverageDAUsType31',
      'PushNType32',
      'PushNOpeningRateActive1DType32',
      'PushNCoverageDAUsType32',
      'PushNType37',
      'PushNOpeningRateActive1DType37',
      'PushNCoverageDAUsType37',
      'PushNType47',
      'PushNOpeningRateActive1DType47',
      'PushNCoverageDAUsType47',
      'PushNType48',
      'PushNOpeningRateActive1DType48',
      'PushNCoverageDAUsType48',
      'PushNType49',
      'PushNOpeningRateActive1DType49',
      'PushNCoverageDAUsType49',
      'PushNType38',
      'PushNOpeningRateActive1DType38',
      'PushNCoverageDAUsType38',
      'PushNType50',
      'PushNOpeningRateActive1DType50',
      'PushNCoverageDAUsType50',
    ],
  },
]
