import { countriesToTrack } from '@tellonym/enums/lib/Stats'
import { Button, Select, Table, Typography, Upload } from 'antd'
import React from 'react'
import * as Redux from 'react-redux'
import { Box, colors, styleSheets, View } from '../../common'
import { convertToOptions } from '../../common/helpers'
import { resetCsvPreview, uploadCsv } from '../actions'
import { getCsvPreview, getIsUploadingCsv } from '../selectors'

const styles = {
  container: { flex: 1, padding: 16, backgroundColor: colors.background },
  bottomMargin: { marginBottom: '2%' },
  download: { alignSelf: 'center', marginTop: '4%' },
  flex1: { flex: 1 },
  metricsContainer: { flex: 3 },
  paramBuilderContainer: {
    flexDirection: 'row',
    paddingHorizontal: '2%',
    paddingVertical: '1%',
  },
  rangePicker: { maxWidth: 300 },
  row: { flexDirection: 'row' },
  section: { marginBottom: 24, borderBottomWidth: 1 },
  select: { minWidth: 130, maxWidth: 250, marginBottom: 8 },
  timeSectionContainer: { marginBottom: 24 },
}

const countryOptions = convertToOptions(countriesToTrack)

const CSV_FILE_SOURCE = {
  APPLE: 'APPLE',
  GOOGLE: 'GOOGLE',
  HUAWEI: 'HUAWEI',
}

const storeOrder = [
  CSV_FILE_SOURCE.APPLE,
  CSV_FILE_SOURCE.GOOGLE,
  CSV_FILE_SOURCE.HUAWEI,
]

const storeOptions = convertToOptions(storeOrder)

const allCountries = 'CHOOSE COUNTRY'

const getTableColumns = (store) => [
  { title: 'Date', dataIndex: 'date', key: 'date' },
  {
    title: 'Downloads',
    dataIndex: 'downloads',
    key: 'downloads',
  },
  ...(store === CSV_FILE_SOURCE.HUAWEI
    ? [
        {
          title: 'Impressions',
          dataIndex: 'impressions',
          key: 'impressions',
        },
        {
          title: 'Uninstalls',
          dataIndex: 'uninstalls',
          key: 'uninstalls',
        },
        {
          title: 'Updates',
          dataIndex: 'updates',
          key: 'updates',
        },
      ]
    : []),
  {
    title: 'Country Code',
    dataIndex: 'countryCode',
    key: 'countryCode',
  },
]

export const PageUpload = () => {
  const dispatch = Redux.useDispatch()

  const [store, setStore] = React.useState(storeOrder[0])
  const [upload, setUpload] = React.useState(undefined)
  const [countryCode, setCountryCode] = React.useState(allCountries)

  const csvPreview = Redux.useSelector(getCsvPreview)
  const isUploadingCsv = Redux.useSelector(getIsUploadingCsv)

  const columns = React.useMemo(() => getTableColumns(store), [store])

  const createSetter = (setter) => (payload) => {
    dispatch(resetCsvPreview())
    setter(payload)
  }

  const onCsvUpload = async ({ file }) => {
    const upload = {
      file,
      store,
      countryCode:
        store !== CSV_FILE_SOURCE.HUAWEI || countryCode === allCountries
          ? undefined
          : countryCode,
    }

    dispatch(uploadCsv({ ...upload, preview: true }))

    setUpload(upload)
  }

  const onCsvSave = () => {
    if (upload) {
      dispatch(uploadCsv(upload))
      setUpload(undefined)
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.section}>
        <View style={{ marginBottom: 24 }}>
          <Typography.Title>CSV Upload</Typography.Title>
          <Typography.Text>
            Choose the data source and the data type, drag the file onto Upload
            CSV, inspect the preview data in the table, and hit Save if
            everything looks alright.
          </Typography.Text>
        </View>
        <View>
          <View style={styleSheets.flex.direction.row}>
            <Box marginRight={12}>
              <Select
                options={storeOptions}
                onChange={createSetter(setStore)}
                value={store}
                style={styles.select}
              />
              <Select
                disabled={store !== CSV_FILE_SOURCE.HUAWEI}
                defaultValue={allCountries}
                options={[{ label: allCountries, value: allCountries }].concat(
                  countryOptions
                )}
                onChange={createSetter(setCountryCode)}
                style={styles.select}
              />
              <Button
                type="primary"
                disabled={isUploadingCsv || !csvPreview}
                loading={isUploadingCsv}
                onClick={onCsvSave}
                style={styles.select}>
                Save
              </Button>
            </Box>
            <Upload
              name="storeDownloads"
              listType="picture-card"
              showUploadList={false}
              customRequest={onCsvUpload}
              disabled={isUploadingCsv}>
              Upload CSV
            </Upload>
          </View>
          <Table dataSource={csvPreview} columns={columns} />
        </View>
      </View>
    </View>
  )
}
