import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import RNFlatList from 'react-native-web/dist/exports/FlatList'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getIsSidemenuShown } from '../../app/selectors'
import { SideMenu } from '../../navigation/components/SideMenu'
import { Container } from './Container'
import { Icon } from './Icon'
import { Spinner } from './Spinner'
import { TouchableOpacity } from './TouchableOpacity'
import { View } from './View'

const Footer = ({ hasMore, horizontal, isFetching, onPressMore }) => (
  <View
    style={[
      {
        justifyContent: 'center',
        alignItems: 'center',
        padding: 8,
      },
      horizontal && {
        flex: 1,
      },
    ]}>
    {hasMore && !isFetching && (
      <TouchableOpacity onPress={onPressMore}>
        {horizontal ? (
          <Icon icon={faAngleRight} size="2x" />
        ) : (
          <Icon icon={faAngleDown} size="2x" />
        )}
      </TouchableOpacity>
    )}
    {isFetching && <Spinner />}
  </View>
)

class _FlatList extends React.Component {
  state = {
    _rerender: {},
    hasMore: this.props.hasMore,
    hasRefreshed: this.props.hasRefreshed,
    hasScrolled: false,
    isFetching: this.props.isFetching,
    viewableItems: {},
    ...this.props.extraData,
  }

  componentDidUpdate(prevProps) {
    const { _rerenderItem, extraData } = this.props

    const hasExtraDataChanged =
      JSON.stringify(extraData) !== JSON.stringify(prevProps.extraData)
    const shouldRerenderItems =
      _rerenderItem &&
      _rerenderItem.ids &&
      _rerenderItem.ids.length &&
      _rerenderItem.count !== prevProps._rerenderItem.count

    const shouldStateUpdate = hasExtraDataChanged || shouldRerenderItems

    if (shouldStateUpdate) {
      this.setState((state) => ({
        ...state,
        ...(hasExtraDataChanged ? extraData : {}),
        ...(shouldRerenderItems
          ? {
              _rerender: _rerenderItem.ids.reduce(
                (acc, id) => ({ ...acc, [id]: !state._rerender[id] }),
                state._rerender
              ),
            }
          : {}),
      }))
    }
  }

  _keyExtractor = (id) => String(id)

  _renderItem = ({ index, item }) => {
    const itemData = this.props.items.data[item]

    const Item = this.props.component
    return (
      <Item
        actions={this.props.actions}
        hasSeparator={index !== this.props.items.ids.length - 1}
        isViewable={!!this.state.viewableItems[item]}
        item={itemData}
        positionInList={index}
        rerender={!!this.state._rerender[item]}
        {...this.props.extraProps}
      />
    )
  }

  _onEndReached = () => {
    const { actions, items, hasMore, isFetching } = this.props
    if (
      actions &&
      actions.fetch &&
      hasMore &&
      !isFetching &&
      this.state.hasScrolled
    ) {
      const oldestId = [...items.ids].reverse().find(Number.isInteger)
      actions.fetch({
        oldestId,
        oldestTime: oldestId ? items.data[oldestId].time : undefined,
        offset: items.ids.filter(Number.isInteger).length,
      })
    }
  }

  _onRefresh = () =>
    this.props.actions &&
    this.props.actions.refresh &&
    this.props.actions.refresh()

  _onScroll = (payload) => {
    if (!this.state.hasScrolled) {
      this.setState({ hasScrolled: true })
    }
    if (typeof this.props.onScroll === 'function') {
      this.props.onScroll(payload)
    }
  }

  _onViewableItemsChanged = (payload) =>
    this.setState((state) => ({
      viewableItems: payload.changed.reduce(
        (acc, item) => ({ ...acc, [item.item]: item.isViewable }),
        state.viewableItems
      ),
    }))

  _renderEmptyComponent = () =>
    this.props.hasRefreshed === false || !this.props.renderEmptyComponent
      ? null
      : this.props.renderEmptyComponent()

  _renderFooter = () =>
    typeof this.props.renderFooter === 'function' ? (
      this.props.renderFooter()
    ) : (
      <Footer
        hasAd={
          this.props.items.ids.length > 0 && this.props.items.ids.length < 3
        }
        hasImprint={this.props.hasImprint}
        hasMore={this.props.hasMore}
        horizontal={this.props.horizontal}
        isFetching={this.props.isFetching}
        isRefreshing={this.props.isRefreshing}
        onPressMore={this._onEndReached}
      />
    )

  _renderHeader = () =>
    this.props.renderHeader
      ? this.props.renderHeader({
          adComponent: this._headerAd,
          ...this.props.extraProps,
        })
      : null

  render() {
    return (
      <Container
        style={{
          height: this.props.style ? this.props.style.height : undefined,
        }}
        isRefreshing={this.props.isRefreshing}>
        <RNFlatList
          CellRendererComponent={this.props.renderCell}
          contentContainerStyle={{
            alignSelf: 'center',
            width: '100%',
            paddingBottom: 50,
            alignItems: 'stretch',
            flex: 1,
            maxWidth:
              window.tnym.getWidth() -
              (this.props.isSidemenuShown ? SideMenu.width : 0),
            ...this.props.contentContainerStyle,
          }}
          data={this.props.items.ids}
          disableVirtualization={false}
          extraData={this.state}
          getItemLayout={this.props.getItemLayout}
          horizontal={this.props.horizontal || false}
          numColumns={this.props.numColumns}
          keyExtractor={this._keyExtractor}
          legacyImplementation={false}
          ListEmptyComponent={this._renderEmptyComponent}
          ListFooterComponent={this._renderFooter}
          ListHeaderComponent={this._renderHeader}
          onEndReached={this._onEndReached}
          onEndReachedThreshold={this.props.onEndReachedThreshold ?? 0.9}
          onRefresh={this._onRefresh}
          onScroll={this._onScroll}
          onViewableItemsChanged={
            this.props.onViewableItemsChanged
              ? this._onViewableItemsChanged
              : undefined
          }
          ref={this.props.forwardRef}
          refreshing={this.props.isRefreshing}
          removeClippedSubviews={false}
          renderItem={this._renderItem}
          style={[
            {
              flex: 1,
              flexBasis:
                window.tnym.whoami.browser === 'safari' ? 'auto' : '0%',
            },
            this.props.style,
          ]}
        />
      </Container>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  isSidemenuShown: getIsSidemenuShown,
})

const withConnect = connect(mapStateToProps)

export const FlatList = withConnect(_FlatList)
