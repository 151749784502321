export const COPY_TO_CLIPBOARD = 'share/COPY_TO_CLIPBOARD'

export const CREATE_STICKER = 'share/CREATE_STICKER'
export const RESET_STICKER = 'share/RESET_STICKER'

export const TOGGLE_MULTI_SHARE_ITEM = 'share/TOGGLE_MULTI_SHARE_ITEM'

export const SET_ACTIVE_MULTI_SHARE_METHOD = 'share/SET_ACTIVE_MULTI_SHARE_METHOD' // prettier-ignore

export const SET_BG_OWN_PIC = 'share/SET_BG_OWN_PIC'
export const SET_BG_TYPE = 'share/SET_BG_TYPE'
export const SET_BG_VARIANT = 'share/SET_BG_VARIANT'

export const SET_PROFILE_BG_TYPE = 'share/SET_PROFILE_BG_TYPE'
export const SET_PROFILE_BG_VARIANT = 'share/SET_PROFILE_BG_VARIANT'

export const SET_SEEN_PROFILE_MESSAGE_INDEX = 'share/SET_SEEN_PROFILE_MESSAGE_INDEX' // prettier-ignore

export const SET_SHARE_STICKER_WITH_BG_SHARE_METHOD = 'share/SET_SHARE_STICKER_WITH_BG_SHARE_METHOD' // prettier-ignore

export const CHOOSE_BACKGROUND_PICTURE = 'share/CHOOSE_BACKGROUND_PICTURE'
export const REMOVE_BACKGROUND_PICTURE = 'share/REMOVE_BACKGROUND_PICTURE'

export const RESET_RANDOM_BACKGROUND_VARIANT = 'share/RESET_RANDOM_BACKGROUND_VARIANT' // prettier-ignore

export const SET_STICKER_CREATION_STATE = 'share/SET_STICKER_CREATION_STATE'

export const SHARE_POST = 'share/SHARE_POST'
export const SHARE_POSTS = 'share/SHARE_POSTS'
export const SHARE_PROFILE = 'share/SHARE_PROFILE'
export const SHARE_STICKER = 'share/SHARE_STICKER'

export const TRIGGER_PUSH_NOTIFICATION_AFTER_SHARING = 'share/TRIGGER_PUSH_NOTIFICATION_AFTER_SHARING' // prettier-ignore
export const TRIGGER_PUSH_NOTIFICATION_AFTER_SHARING_ERROR = 'share/TRIGGER_PUSH_NOTIFICATION_AFTER_SHARING_ERROR' // prettier-ignore
export const TRIGGER_PUSH_NOTIFICATION_AFTER_SHARING_SUCCESS = 'share/TRIGGER_PUSH_NOTIFICATION_AFTER_SHARING_SUCCESS' // prettier-ignore

export const UPLOAD_SHARE_STICKER_BG_PICTURE = 'share/UPLOAD_SHARE_STICKER_BG_PICTURE' // prettier-ignore
export const UPLOAD_SHARE_STICKER_BG_PICTURE_ERROR = 'share/UPLOAD_SHARE_STICKER_BG_PICTURE_ERROR' // prettier-ignore
export const UPLOAD_SHARE_STICKER_BG_PICTURE_SUCCESS = 'share/UPLOAD_SHARE_STICKER_BG_PICTURE_SUCCESS' // prettier-ignore

export const UPLOAD_STICKER = 'share/UPLOAD_STICKER'
export const UPLOAD_STICKER_ERROR = 'share/UPLOAD_STICKER_ERROR'
export const UPLOAD_STICKER_SUCCESS = 'share/UPLOAD_STICKER_SUCCESS'

export const SET_LINK_IN_BIO_ALERT_SHARE_DATA_BUFFER =
  'share/SET_LINK_IN_BIO_ALERT_SHARE_DATA_BUFFER'
