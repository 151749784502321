import * as t from './types'

export const refreshRecentAnswers = (payload) => ({
  type: t.REFRESH_RECENT_ANSWERS,
  payload,
  meta: {
    offline: {
      effect: { path: 'admin/posts/recent' },
      commit: { type: t.REFRESH_RECENT_ANSWERS_SUCCESS },
      rollback: { type: t.REFRESH_RECENT_ANSWERS_ERROR },
    },
  },
})
