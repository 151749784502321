import { Table } from 'antd'
import qs from 'qs'
import React, { useEffect, useMemo, useState } from 'react'
import { useVT } from 'virtualizedtableforantd4'
import { queryElementHeight } from '../helpers'
import { history } from '../history'

export const getNavigationTableState = () => {
  const currentState = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  })

  return currentState
}

export const syncNavigationTableState = (nextState) => {
  const currentState = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  })

  history.replace({
    ...history.location,
    search: qs.stringify({
      ...currentState,
      ...nextState,
    }),
  })
}

/**
 * @param {number} offset set if this Table shouldn't fill the full height
 * @param {Function} onEndReached can be used to fetch additional data
 */
export const TableEndlessScroll = ({
  columns,
  dataSource = [],
  debug = false,
  filters,
  isEndless = true,
  offset = 0,
  onChange,
  onEndReached,
  pagination,
  scroll,
  sorter,
  ...props
}) => {
  const [scrollConfig, setScrollConfig] = useState({
    y: 0,
    x: false,
    ...scroll,
  })

  const headerHeight = queryElementHeight('.ant-table-header')
  const paginationHeight = isEndless ? 0 : 64 // .ant-table-pagination

  useEffect(() => {
    setScrollConfig({
      ...scrollConfig,
      y: scroll?.y
        ? scroll.y
        : window.tnym.getHeight() -
          headerHeight -
          paginationHeight -
          offset -
          10,
    })
  }, [offset, scroll, headerHeight, paginationHeight])

  const [vt] = useVT(
    () => ({
      onScroll:
        typeof onEndReached === 'function'
          ? ({ isEnd }) => {
              if (isEnd) {
                onEndReached()
              }
            }
          : undefined,
      scroll: { y: scrollConfig.y },
      debug,
    }),
    [dataSource, onEndReached, scrollConfig]
  )

  const style = useMemo(
    () => ({
      cursor: 'pointer',
      ...props.style,
    }),
    [props.style]
  )

  const columnsWithState = useMemo(
    () =>
      columns.map((column) => {
        column.filteredValue =
          filters?.[column.key]?.map?.(String) ??
          column.defaultFilteredValue?.map?.(String) ??
          null

        column.sortOrder =
          sorter?.columnKey === column.key
            ? sorter?.order ?? false
            : column.defaultSortOrder ?? false

        return column
      }),
    [columns, filters, pagination, sorter]
  )

  return (
    <Table
      columns={columnsWithState}
      dataSource={dataSource}
      scroll={scrollConfig}
      components={vt}
      onChange={onChange}
      pagination={
        isEndless
          ? false
          : {
              defaultPageSize: '50',
              pageSizeOptions: ['10', '25', '50', '75', '100', '150', '200'],
            }
      }
      style={style}
      {...props}
    />
  )
}
