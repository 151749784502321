import { Radio, Typography } from 'antd'
import React from 'react'
import { Box, colors, View } from '../../common'
import { convertToOptions } from '../../common/helpers'
import * as hooks from '../../common/hooks'
import { TabCyclePlanning } from './TabCyclePlanning'
import { TabJobConversion } from './TabJobConversion'

const styles = {
  tabSelector: { alignSelf: 'center' },
}

const tabs = {
  CYCLE_PLANNING: 'Cycle Planning',
  JOB_CONVERSION: 'Job Conversion',
}

const validTabs = Object.values(tabs)

const tabOptions = convertToOptions(validTabs)

const tabComponents = {
  [tabs.CYCLE_PLANNING]: <TabCyclePlanning />,
  [tabs.JOB_CONVERSION]: <TabJobConversion />,
}

const defaultQueryParams = {
  stage: tabs.CYCLE_PLANNING,
}

export const PageNotionHelpers = () => {
  const containerStyle = hooks.usePageContainerStyle()
  const [queryParams, setQueryParams] = hooks.useQueryParams(defaultQueryParams)

  const onChangeTab = (e) => {
    setQueryParams({ stage: e.target.value })
  }

  React.useEffect(() => {
    if (queryParams.stage && !validTabs.includes(queryParams.stage)) {
      setQueryParams({ defaultQueryParams })
    }
  }, [queryParams])

  return (
    <View style={containerStyle}>
      <Box padding={16} backgroundColor={colors.background}>
        <Typography.Title>Notion Helpers</Typography.Title>
        <Radio.Group
          optionType="button"
          options={tabOptions}
          onChange={onChangeTab}
          value={queryParams.stage}
          size="large"
          style={styles.tabSelector}
        />
        <Box marginTop={24}>{tabComponents[queryParams.stage]}</Box>
      </Box>
    </View>
  )
}
