export const dateTypes = {
  LAST_30_DAYS: 'LAST_30_DAYS',
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  LAST_7_DAYS: 'LAST_7_DAYS',
  LAST_WEEK: 'LAST_WEEK',
  LAST_MONTH: 'LAST_MONTH',
  LAST_90_DAYS: 'LAST_90_DAYS',
  CUSTOM_DATE: 'CUSTOM_DATE',
}

export const sources = {
  ADX: 'ADX',
  AD_SERVER: 'AD_SERVER',
  TOTAL: 'TOTAL',
}

const adxDimensions = {
  AD_EXCHANGE_COUNTRY_NAME: 'AD_EXCHANGE_COUNTRY_NAME',
  AD_EXCHANGE_DFP_AD_UNIT: 'AD_EXCHANGE_DFP_AD_UNIT',
  AD_EXCHANGE_DFP_AD_UNIT_ID: 'AD_EXCHANGE_DFP_AD_UNIT_ID',
  AD_EXCHANGE_DFP_MOBILE_APP_NAME: 'AD_EXCHANGE_DFP_MOBILE_APP_NAME',
  AD_EXCHANGE_PRICING_RULE_NAME: 'AD_EXCHANGE_PRICING_RULE_NAME',
}

const totalDimensions = {
  DATE: 'DATE',
  MOBILE_APP_NAME: 'MOBILE_APP_NAME',
  COUNTRY_NAME: 'COUNTRY_NAME',
  ADVERTISER_NAME: 'ADVERTISER_NAME',
  PLACEMENT_NAME: 'PLACEMENT_NAME',
  AD_UNIT_NAME: 'AD_UNIT_NAME',
  AD_UNIT_ID: 'AD_UNIT_ID',
  CLASSIFIED_ADVERTISER_NAME: 'CLASSIFIED_ADVERTISER_NAME',
  CREATIVE_SIZE_DELIVERED: 'CREATIVE_SIZE_DELIVERED',
  NATIVE_TEMPLATE_NAME: 'NATIVE_TEMPLATE_NAME',
  UNIFIED_PRICING_RULE_NAME: 'UNIFIED_PRICING_RULE_NAME',
  CUSTOM_DIMENSION: 'CUSTOM_DIMENSION',
}

const adxMetrics = {
  AD_EXCHANGE_AD_REQUESTS: 'AD_EXCHANGE_AD_REQUESTS',
  AD_EXCHANGE_RESPONSES_SERVED: 'AD_EXCHANGE_RESPONSES_SERVED',
  AD_EXCHANGE_MATCHED_REQUESTS: 'AD_EXCHANGE_MATCHED_REQUESTS',
  AD_EXCHANGE_COVERAGE: 'AD_EXCHANGE_COVERAGE',
  AD_EXCHANGE_IMPRESSIONS: 'AD_EXCHANGE_IMPRESSIONS',
  AD_EXCHANGE_ACTIVE_VIEW_VIEWABLE: 'AD_EXCHANGE_ACTIVE_VIEW_VIEWABLE',
  AD_EXCHANGE_ESTIMATED_REVENUE: 'AD_EXCHANGE_ESTIMATED_REVENUE',
  AD_EXCHANGE_AD_REQUEST_ECPM: 'AD_EXCHANGE_AD_REQUEST_ECPM',
  AD_EXCHANGE_MATCHED_ECPM: 'AD_EXCHANGE_MATCHED_ECPM',
  AD_EXCHANGE_AD_ECPM: 'AD_EXCHANGE_AD_ECPM',
  AD_EXCHANGE_AD_CTR: 'AD_EXCHANGE_AD_CTR',
}

const adserverMetrics = {
  AD_SERVER_RESPONSES_SERVED: 'AD_SERVER_RESPONSES_SERVED',
  AD_SERVER_IMPRESSIONS: 'AD_SERVER_IMPRESSIONS',
  AD_SERVER_CPM_AND_CPC_REVENUE: 'AD_SERVER_CPM_AND_CPC_REVENUE',
  AD_SERVER_WITHOUT_CPD_AVERAGE_ECPM: 'AD_SERVER_WITHOUT_CPD_AVERAGE_ECPM',
}

const totalMetrics = {
  TOTAL_AD_REQUESTS: 'TOTAL_AD_REQUESTS',
  TOTAL_RESPONSES_SERVED: 'TOTAL_RESPONSES_SERVED',
  TOTAL_CODE_SERVED_COUNT: 'TOTAL_CODE_SERVED_COUNT',
  TOTAL_FILL_RATE: 'TOTAL_FILL_RATE',
  TOTAL_INVENTORY_LEVEL_UNFILLED_IMPRESSIONS:
    'TOTAL_INVENTORY_LEVEL_UNFILLED_IMPRESSIONS',
  TOTAL_LINE_ITEM_LEVEL_IMPRESSIONS: 'TOTAL_LINE_ITEM_LEVEL_IMPRESSIONS',
  TOTAL_ACTIVE_VIEW_VIEWABLE_IMPRESSIONS:
    'TOTAL_ACTIVE_VIEW_VIEWABLE_IMPRESSIONS',
  TOTAL_ACTIVE_VIEW_VIEWABLE_IMPRESSIONS_RATE:
    'TOTAL_ACTIVE_VIEW_VIEWABLE_IMPRESSIONS_RATE',
  TOTAL_LINE_ITEM_LEVEL_ALL_REVENUE: 'TOTAL_LINE_ITEM_LEVEL_ALL_REVENUE',
  TOTAL_LINE_ITEM_LEVEL_CPM_AND_CPC_REVENUE:
    'TOTAL_LINE_ITEM_LEVEL_CPM_AND_CPC_REVENUE',
  TOTAL_LINE_ITEM_LEVEL_WITHOUT_CPD_AVERAGE_ECPM:
    'TOTAL_LINE_ITEM_LEVEL_WITHOUT_CPD_AVERAGE_ECPM',
  TOTAL_LINE_ITEM_LEVEL_CLICKS: 'TOTAL_LINE_ITEM_LEVEL_CLICKS',
  TOTAL_LINE_ITEM_LEVEL_CTR: 'TOTAL_LINE_ITEM_LEVEL_CTR',
}

export const dimensions = {
  [sources.ADX]: adxDimensions,
  [sources.AD_SERVER]: totalDimensions,
  [sources.TOTAL]: totalDimensions,
}

export const metrics = {
  [sources.ADX]: adxMetrics,
  [sources.AD_SERVER]: adserverMetrics,
  [sources.TOTAL]: totalMetrics,
}

export const customDimensionKeyIds = {
  HAS_IDFA: 12603841,
  HB_BIDDER: 11964173,
  OS: 12603712,
}
