import { faThumbsDown } from '@fortawesome/free-solid-svg-icons'
import { pick } from 'ramda'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Alert,
  history,
  Icon,
  moment,
  Text,
  theme,
  TouchableOpacity,
  View,
} from '../../common'
import { declineGroup } from '../actions'
import { ArtificialTellStats } from './ArtificialTellStats'

const styles = {
  groupItem: {
    onHover: {
      boxShadow: theme.styles.shadowInsetVertical,
      cursor: 'pointer',
    },
  },
  groupItemDateContainer: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingLeft: 12,
    paddingRight: 12,
  },
  item: {
    alignItems: 'center',
    backgroundColor: theme.colors.background,
    borderColor: theme.colors.borderLightGrey,
    borderStyle: 'solid',
    borderWidth: 0,
    flexDirection: 'row',
  },
  itemActionsIcon: {
    fontSize: 24,
    color: theme.colors.icon,
  },
  itemActionsIconContainer: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 12,
    paddingBottom: 12,
    onHover: {
      backgroundColor: theme.colors.backgroundLight,
      borderRadius: 12,
      transition: 'background-color 0.5s',
    },
  },
  groupReviewStatusLabelContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    paddingVertical: 4,
  },
  groupReviewStatusLabel: {
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
    marginBottom: 4,
    paddingHorizontal: 6,
    paddingVertical: 4,
  },
}

export const TextLabel = (props) => {
  const color = props.red
    ? theme.colors.appleRed
    : props.green
    ? theme.colors.green
    : '#fac472'

  return (
    <View style={styles.groupReviewStatusLabelContainer}>
      <Text
        color={color}
        type="small"
        style={[
          styles.groupReviewStatusLabel,
          { borderColor: color, borderWidth: 2 },
        ]}>
        {props.children}
      </Text>
    </View>
  )
}

export const GroupReviewStatusLabel = ({ group }) => (
  <View style={styles.groupReviewStatusLabelContainer}>
    {group.isReviewed ? (
      <TextLabel green>Reviewed</TextLabel>
    ) : (
      <TextLabel yellow>Not Reviewed</TextLabel>
    )}
    <View>
      <Text type="note" color={theme.colors.placeholder}>
        {group.lastReviewDate
          ? `${moment(group.lastReviewDate).format('DD.MM HH:mm')} by ${
              group.reviewedBy?.username
            }`
          : ''}
      </Text>
    </View>
  </View>
)

const _GroupItem = React.memo(
  ({ actions, hasSeparator, item: group, language, positionInList }) => {
    const onPress = (e) => {
      const isActionKeyPressed = e.metaKey || e.ctrlKey
      const route = `/artificialtells/${language}/group/${group.id}`

      if (isActionKeyPressed) {
        window.open(route, '_blank')
      } else {
        history.push(route)
      }
    }

    return (
      <TouchableOpacity
        onPress={onPress}
        style={[
          styles.item,
          styles.groupItem,
          { borderBottomWidth: hasSeparator ? 1 : 0 },
        ]}>
        <Text bold center style={{ flex: 1, justifySelf: 'flex-start' }}>
          {positionInList + 1}
        </Text>
        <Text>{group.title ?? group.name}</Text>
        <View style={styles.groupItemDateContainer}>
          {group.notBefore && (
            <Text color={theme.colors.pink} style={{ marginBottom: 6 }}>
              {`Starts ${moment(group.notBefore).format('DD.MM')}`}
            </Text>
          )}
          {group.notAfter && (
            <Text color={theme.colors.pink}>
              {`Ends ${moment(group.notAfter).format('DD.MM')}`}
            </Text>
          )}
        </View>
        <View
          style={{
            flex: 0.5,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <TouchableOpacity
            onPress={async () => {
              const response = await Alert.alert(
                '',
                `Decline ${language.toUpperCase()}?`
              )

              if (response === Alert.CONFIRMED) {
                actions.declineGroup({ id: group.id, language })
              }
            }}
            style={styles.itemActionsIconContainer}>
            <Icon icon={faThumbsDown} style={styles.itemActionsIcon} />
          </TouchableOpacity>
        </View>
        <ArtificialTellStats
          onPress={onPress}
          items={pick(['answerRate', 'amountDeclined', 'amountDrafts', group])}
        />
        <GroupReviewStatusLabel group={group} />
      </TouchableOpacity>
    )
  }
)

const withConnect = connect(null, (dispatch) => ({
  actions: bindActionCreators({ declineGroup }, dispatch),
}))

export const GroupItem = withConnect(_GroupItem)
