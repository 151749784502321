import * as Sentry from '@sentry/browser'
import { configureServer as configureMockServer } from '@tellonym/core/api/mocks/server'
import { configureCore } from '@tellonym/core/config'
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import localData from 'dayjs/plugin/localeData'
import relativeTime from 'dayjs/plugin/relativeTime'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { config } from './config'

dayjs.extend(isoWeek)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(localData)
dayjs.extend(relativeTime)
dayjs.extend(weekday)
dayjs.extend(weekOfYear)

configureCore(config, {
  Sentry,
})

if (__DEV__) {
  console.warn('Mocks are enabled.') // eslint-disable-line no-console
  configureMockServer({ environment: 'development' })
}
