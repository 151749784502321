import { langDetectObjectsByType1 } from '@tellonym/enums/lib/Language'
import { ARTIFICIAL_TELL_VARIANCE_STATUS } from '@tellonym/enums/lib/Tell'
import { config } from '../../config'
import * as t from './types'

const convertLanguageToEnum = (action) => (payload) => {
  const { language: _language } = payload
  const language = langDetectObjectsByType1[_language] || _language

  return action({ ...payload, language })
}

export const add = convertLanguageToEnum(
  ({ groupId, language, ...payload }) => ({
    type: t.ADD,
    payload: { groupId, language, ...payload },
    meta: {
      offline: {
        effect: {
          path: `admin/artificialtells/item/${language}/${groupId}/create`,
          method: 'POST',
        },
        commit: {
          type: t.ADD_SUCCESS,
          meta: { groupId, language },
        },
        rollback: { type: t.ADD_ERROR },
      },
    },
  })
)

export const addGroup = convertLanguageToEnum(
  ({ name, language, title, ...payload }) => ({
    type: t.ADD_GROUP,
    payload: { name, title, ...payload },
    meta: {
      offline: {
        effect: {
          path: 'admin/artificialtells/group/create',
          method: 'POST',
        },
        commit: {
          type: t.ADD_GROUP_SUCCESS,
          meta: { language },
        },
        rollback: { type: t.ADD_GROUP_ERROR },
      },
    },
  })
)

export const changeApproval = convertLanguageToEnum(
  ({ language, groupId, ...payload }) => ({
    type: t.CHANGE_APPROVAL,
    payload: { language, ...payload },
    meta: {
      offline: {
        effect: {
          path: 'admin/artificialtells/item/edit',
          method: 'POST',
        },
        commit: {
          type: t.CHANGE_APPROVAL_SUCCESS,
          meta: { language, groupId },
        },
        rollback: { type: t.CHANGE_APPROVAL_ERROR },
      },
    },
  })
)

export const edit = convertLanguageToEnum(
  ({ groupId, language, ...payload }) => ({
    type: t.EDIT,
    payload: { groupId, language, ...payload },
    meta: {
      offline: {
        effect: { path: 'admin/artificialtells/item/edit', method: 'POST' },
        commit: {
          type: t.EDIT_SUCCESS,
          meta: { groupId, language, ...payload },
        },
        rollback: { type: t.EDIT_ERROR },
      },
    },
  })
)

export const editGroup = convertLanguageToEnum((payload) => ({
  type: t.EDIT_GROUP,
  payload,
  meta: {
    offline: {
      effect: { path: 'admin/artificialtells/group/edit', method: 'POST' },
      commit: {
        type: t.EDIT_GROUP_SUCCESS,
        meta: { payload },
      },
      rollback: { type: t.EDIT_GROUP_ERROR },
    },
  },
}))

export const fetch = convertLanguageToEnum(
  ({ groupId, language, limit, oldestId }) => ({
    type: t.FETCH,
    language,
    meta: {
      groupId,
      language,
      offline: {
        effect: {
          path: `admin/artificialtells/item/${language}/${groupId}`,
          limit,
          olderThanId: oldestId,
        },
        commit: {
          type: t.FETCH_SUCCESS,
          meta: { groupId, language },
        },
        rollback: { type: t.FETCH_ERROR, meta: { groupId, language } },
      },
    },
  })
)

export const fetchAnswers = ({ artificialTellId, ...payload }) => ({
  type: t.FETCH_ANSWERS,
  meta: {
    offline: {
      effect: {
        path: `admin/artificialtells/item/answers/${artificialTellId}`,
        ...payload,
      },
      commit: {
        type: t.FETCH_ANSWERS_SUCCESS,
        meta: { artificialTellId },
      },
      rollback: { type: t.FETCH_ANSWERS_ERROR },
    },
  },
})

export const fetchGroups = convertLanguageToEnum(({ language, oldestId }) => ({
  type: t.FETCH_GROUPS,
  meta: {
    language,
    offline: {
      effect: {
        path: `admin/artificialtells/${language}`,
        limit: 96,
        showAll: true,
        olderThanId: oldestId,
      },
      commit: {
        type: t.FETCH_GROUPS_SUCCESS,
        meta: { language },
      },
      rollback: { type: t.FETCH_GROUPS_ERROR, meta: { language } },
    },
  },
}))

export const fetchGroupSuggestions = convertLanguageToEnum(
  ({ language, limit, oldestId, offset }) => ({
    type: t.FETCH_GROUP_SUGGESTIONS,
    meta: {
      language,
      offline: {
        effect: {
          path: `admin/artificialtells/${language}/suggestions`,
          limit,
          olderThanId: oldestId,
          offset,
        },
        commit: {
          type: t.FETCH_GROUP_SUGGESTIONS_SUCCESS,
          meta: { language },
        },
        rollback: { type: t.FETCH_GROUP_SUGGESTIONS_ERROR, meta: { language } },
      },
    },
  })
)

export const fetchArtTellGroups = ({
  limit = config.api.limit + 1,
  oldestId,
  ...payload
}) => ({
  type: t.FETCH_ART_TELL_GROUPS,
  payload,
  meta: {
    offline: {
      effect: {
        path: `admin/artificialtells/search?limit=${limit}&olderThanId=${oldestId}`,
        method: 'POST',
      },
      commit: { type: t.FETCH_ART_TELL_GROUPS_SUCCESS },
      rollback: { type: t.FETCH_ART_TELL_GROUPS_ERROR },
    },
  },
})

export const refresh = convertLanguageToEnum(
  ({ groupId, language, originLanguage, limit }) => ({
    type: t.REFRESH,
    language,
    meta: {
      groupId,
      language,
      offline: {
        shouldNotEnqueueMultiple: true,
        effect: {
          path: `admin/artificialtells/item/${language}/${groupId}`,
          limit,
          showAll: true,
        },
        commit: {
          type: t.REFRESH_SUCCESS,
          meta: { groupId, language },
        },
        rollback: {
          type: t.REFRESH_ERROR,
          meta: { groupId, language, originLanguage },
        },
      },
    },
  })
)

export const refreshAnswers = ({ artificialTellId }) => ({
  type: t.REFRESH_ANSWERS,
  meta: {
    offline: {
      effect: {
        path: `admin/artificialtells/item/answers/${artificialTellId}`,
      },
      commit: {
        type: t.REFRESH_ANSWERS_SUCCESS,
        meta: { artificialTellId },
      },
      rollback: { type: t.REFRESH_ANSWERS_ERROR },
    },
  },
})

export const refreshGroups = convertLanguageToEnum(({ language }) => ({
  type: t.REFRESH_GROUPS,
  meta: {
    language,
    offline: {
      shouldNotEnqueueMultiple: true,
      effect: {
        path: `admin/artificialtells/${language}`,
        limit: 10000,
        showAll: true,
      },
      commit: {
        type: t.REFRESH_GROUPS_SUCCESS,
        meta: { language },
      },
      rollback: { type: t.REFRESH_GROUPS_ERROR, meta: { language } },
    },
  },
}))

export const refreshGroupSuggestions = convertLanguageToEnum(
  ({ language, limit }) => ({
    type: t.REFRESH_GROUP_SUGGESTIONS,
    meta: {
      language,
      offline: {
        shouldNotEnqueueMultiple: true,
        effect: {
          path: `admin/artificialtells/${language}/suggestions`,
          limit,
        },
        commit: {
          type: t.REFRESH_GROUP_SUGGESTIONS_SUCCESS,
          meta: { language },
        },
        rollback: {
          type: t.REFRESH_GROUP_SUGGESTIONS_ERROR,
          meta: { language },
        },
      },
    },
  })
)

export const refreshArtTellGroups = ({
  limit = config.api.limit + 1,
  ...payload
}) => ({
  type: t.REFRESH_ART_TELL_GROUPS,
  payload,
  meta: {
    offline: {
      effect: {
        path: `admin/artificialtells/search?limit=${limit}`,
        method: 'POST',
      },
      commit: { type: t.REFRESH_ART_TELL_GROUPS_SUCCESS },
      rollback: { type: t.REFRESH_ART_TELL_GROUPS_ERROR },
    },
  },
})

/**
 * Artificial Tells
 */

export const fetchArtificialTells = ({
  limit = config.api.limit + 1,
  oldestId,
  ...payload
}) => ({
  type: t.FETCH_ARTIFICIAL_TELLS,
  payload,
  meta: {
    offline: {
      effect: {
        path: `admin/artificialtells/search/tells?limit=${limit}&olderThanId=${oldestId}`,
        method: 'POST',
      },
      commit: { type: t.FETCH_ARTIFICIAL_TELLS_SUCCESS },
      rollback: { type: t.FETCH_ARTIFICIAL_TELLS_ERROR },
    },
  },
})

export const refreshArtificialTells = ({
  limit = config.api.limit + 1,
  ...payload
}) => ({
  type: t.REFRESH_ARTIFICIAL_TELLS,
  payload,
  meta: {
    offline: {
      effect: {
        path: `admin/artificialtells/search/tells?limit=${limit}`,
        method: 'POST',
      },
      commit: { type: t.REFRESH_ARTIFICIAL_TELLS_SUCCESS },
      rollback: { type: t.REFRESH_ARTIFICIAL_TELLS_ERROR },
    },
  },
})

export const setSearchStringArtTells = (payload) => ({
  type: t.SET_SEARCH_STRING_ART_TELLS,
  payload,
})

export const refreshSuggestions = ({
  groupId,
  language,
  limit,
  showAll = true,
}) => {
  const suggestionsLanguage =
    language === 'en'
      ? langDetectObjectsByType1.de
      : langDetectObjectsByType1.en

  return {
    type: t.REFRESH_SUGGESTIONS,
    meta: {
      groupId,
      language: suggestionsLanguage,
      originLanguage: language,
      offline: {
        effect: {
          path: `admin/artificialtells/item/${suggestionsLanguage}/${groupId}`,
          limit,
          showAll,
        },
        commit: {
          type: t.REFRESH_SUGGESTIONS_SUCCESS,
          meta: {
            groupId,
            language: suggestionsLanguage,
            originLanguage: language,
          },
        },
        rollback: {
          type: t.REFRESH_SUGGESTIONS_ERROR,
          meta: {
            groupId,
            language: suggestionsLanguage,
            originLanguage: language,
          },
        },
      },
    },
  }
}

export const getGroupsForTags = (payload) => ({
  type: t.GET_GROUPS_FOR_TAGS,
  payload: {
    tags: payload,
  },
  meta: {
    offline: payload?.length
      ? {
          effect: {
            path: 'admin/artificialtells/search',
            method: 'POST',
            limit: null,
          },
          commit: { type: t.GET_GROUPS_FOR_TAGS_SUCCESS },
          rollback: { type: t.GET_GROUPS_FOR_TAGS_ERROR },
        }
      : undefined,
  },
})

export const refreshTags = ({ language }) => ({
  type: t.REFRESH_TAGS,
  meta: {
    offline: {
      effect: {
        path: `admin/artificialtells/${language}`,
        limit: null,
        showAll: true,
      },
      commit: { type: t.REFRESH_TAGS_SUCCESS },
      rollback: { type: t.REFRESH_TAGS_ERROR },
    },
  },
})

export const decline = convertLanguageToEnum(
  ({ groupId, language, ...payload }) => ({
    type: t.DECLINE,
    payload: { type: ARTIFICIAL_TELL_VARIANCE_STATUS.DECLINED, ...payload },
    meta: {
      offline: {
        effect: { path: 'admin/artificialtells/item/edit', method: 'POST' },
        commit: {
          type: t.DECLINE_SUCCESS,
          meta: { groupId, language },
        },
        rollback: { type: t.DECLINE_ERROR },
      },
    },
  })
)

export const declineGroup = convertLanguageToEnum((payload) => ({
  type: t.DECLINE_GROUP,
  payload,
  meta: {
    offline: {
      effect: { path: 'admin/artificialtells/group/decline', method: 'POST' },
      commit: { type: t.DECLINE_GROUP_SUCCESS, meta: payload },
      rollback: { type: t.DECLINE_GROUP_ERROR },
    },
  },
}))

export const review = convertLanguageToEnum((payload) => ({
  type: t.REVIEW,
  payload,
  meta: {
    offline: {
      effect: { path: 'admin/artificialtells/group/review', method: 'POST' },
      commit: {
        type: t.REVIEW_SUCCESS,
        meta: {
          language: payload.language,
          groupId: payload.id,
          isReviewed: payload.isReviewed,
        },
      },
      rollback: { type: t.REVIEW_ERROR },
    },
  },
}))

export const addLanguage = (payload) => ({
  type: t.ADD_LANGUAGE,
  payload,
  meta: {
    offline: {
      effect: {
        path: 'admin/artificialtells/group/languages/add',
        method: 'POST',
      },
      commit: { type: t.ADD_LANGUAGE_SUCCESS },
      rollback: { type: t.ADD_LANGUAGE_ERROR },
    },
  },
})

export const removeLanguage = (payload) => ({
  type: t.REMOVE_LANGUAGE,
  payload,
  meta: {
    offline: {
      effect: {
        path: 'admin/artificialtells/group/languages/delete',
        method: 'POST',
      },
      commit: { type: t.REMOVE_LANGUAGE_SUCCESS },
      rollback: { type: t.REMOVE_LANGUAGE_ERROR },
    },
  },
})

export const getTags = () => ({
  type: t.GET_TAGS,
  meta: {
    offline: {
      effect: {
        path: 'admin/artificialtells/groups/tags',
        limit: null,
      },
      commit: { type: t.GET_TAGS_SUCCESS },
      rollback: { type: t.GET_TAGS_ERROR },
    },
  },
})

export const setGroupsOrderTypes = (payload) => ({
  type: t.SET_GROUPS_ORDER_TYPES,
  payload,
})

export const setSearchString = (payload) => ({
  type: t.SET_SEARCH_STRING,
  payload,
})

export const setSearchTags = (payload) => ({
  type: t.SET_SEARCH_TAGS,
  payload,
})

export const setSelectedGroupId = (payload) => ({
  type: t.SET_SELECTED_GROUP_ID,
  payload,
})

export const setSelectedLanguage = (payload) => ({
  type: t.SET_SELECTED_LANGUAGE,
  payload,
})

export const setTableState = (payload) => ({
  type: t.SET_TABLE_STATE,
  payload,
})

export const setTagsOrderTypes = (payload) => ({
  type: t.SET_TAGS_ORDER_TYPES,
  payload,
})
