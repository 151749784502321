import { Collapse, Typography } from 'antd'
import React from 'react'
import { Box, colors, Text, View } from '../../common'
import * as hooks from '../../common/hooks'
import { useAnalyticsEventNamesQuery } from '../queries'

const keys = {
  ALL_EVENTS: 'ALL_EVENTS',
}

export const PageAnalyticsInfo = () => {
  const containerStyle = hooks.usePageContainerStyle()

  const availableEventNames = useAnalyticsEventNamesQuery()

  return (
    <View style={containerStyle}>
      <Box padding={16} backgroundColor={colors.background}>
        <Typography.Title>Analytics Info</Typography.Title>
        <Box marginTop={24}>
          <Collapse ghost>
            <Collapse.Panel
              header={`Available analytics events in Clickhouse${
                availableEventNames?.length >= 0
                  ? `: ${availableEventNames.length}`
                  : ''
              }`}
              key={keys.ALL_EVENTS}>
              {availableEventNames?.map(({ label }) => (
                <Text key={label}>{label}</Text>
              ))}
            </Collapse.Panel>
          </Collapse>
        </Box>
      </Box>
    </View>
  )
}
