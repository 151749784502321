import { Select, Typography } from 'antd'
import React, { useMemo, useState } from 'react'
import { colors, View } from '../../common'
import { GamQueryTool } from './GamQueryTool'
import { RubyQueryTool } from './RubyQueryTool'

const availableQueryTools = [
  { value: 'gam', text: 'Google Ad Manager' },
  { value: 'ruby', text: 'Rubylight' },
]

const styles = {
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: colors.background,
  },
}

const QueryToolSelector = ({ options, onChange }) => {
  return (
    <Select
      defaultValue={options[0].value}
      style={{ width: 180 }}
      onChange={onChange}>
      {options.map(({ value, text }) => (
        <Select.Option key={value} value={value}>
          {text}
        </Select.Option>
      ))}
    </Select>
  )
}

export const PageQueryBuilder = () => {
  const [selectedQueryTool, setSelectedQueryTool] = useState(
    availableQueryTools[0].value
  )

  const QueryComponent = useMemo(() => {
    switch (selectedQueryTool) {
      case 'gam':
        return GamQueryTool

      case 'ruby':
        return RubyQueryTool

      default:
        return () => null
    }
  }, [selectedQueryTool])

  return (
    <View style={styles.container}>
      <Typography.Title>Query Builder</Typography.Title>
      <QueryToolSelector
        options={availableQueryTools}
        onChange={setSelectedQueryTool}
      />
      <QueryComponent />
    </View>
  )
}
