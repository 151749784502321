import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { FlatList, Text, theme, View } from '../../common'
import { fetchArtTellGroups, refreshArtTellGroups } from '../actions'
import {
  getArtTellGroupsData,
  getHasMoreResult,
  getHasSearched,
  getHasSearchedGroups,
  getIsFetchingResult,
  getIsRefreshingResult,
  getSearchString,
  getSearchTags,
  getSelectedLanguage,
} from '../selectors'
import { ArtificialTells } from './ArtificialTells'
import { GroupItem } from './GroupItem'
import { SearchBar } from './SearchBar'

const EmptyComponent = ({ hasSearched, isFetching, isRefreshing }) =>
  hasSearched && !(isRefreshing || isFetching) ? (
    <Text
      center
      type="h3"
      color={theme.colors.placeholder}
      style={{ marginTop: 48 }}>
      Nothing found.
    </Text>
  ) : null

const SearchHeader = ({ hasSeparator, initialValue }) => (
  <View
    style={{
      borderStyle: 'solid',
      borderColor: theme.colors.borderLightGrey,
      borderWidth: 0,
      borderBottomWidth: hasSeparator ? 1 : 0,
      padding: 12,
    }}>
    <SearchBar
      initialValue={initialValue}
      shouldFocusOnMount
      style={{
        maxWidth: 500,
        alignSelf: 'center',
        marginBottom: 48,
      }}
    />
  </View>
)

const _PageArtificialTellsSearch = ({
  hasSearched,
  hasSearchedGroups,
  language,
  searchString,
  searchTags,
  ...props
}) => {
  const hasData = props.data.ids.length > 0

  if (hasSearched && !hasSearchedGroups) {
    return <ArtificialTells />
  }

  return (
    <FlatList
      _rerenderItem={props._rerenderItem}
      actions={{
        fetch: (p) =>
          props.actions.fetch({ searchString, tags: searchTags, ...p }),
        refresh: (p) =>
          props.actions.refresh({ searchString, tags: searchTags, ...p }),
      }}
      component={GroupItem}
      extraData={{
        hasMore: props.hasMore,
        isFetching: props.isFetching,
        isRefreshing: props.isRefreshing,
      }}
      extraProps={{ language, shouldShowGroupName: true }}
      hasMore={props.hasMore}
      isFetching={props.isFetching}
      isRefreshing={props.isRefreshing}
      items={props.data}
      renderEmptyComponent={() => (
        <EmptyComponent
          hasSearched={hasSearched}
          isFetching={props.isFetching}
          isRefreshing={props.isRefreshing}
        />
      )}
      renderHeader={() => <SearchHeader hasSeparator={hasData} />}
    />
  )
}

const mapStateToProps = createStructuredSelector({
  data: getArtTellGroupsData,
  hasMore: getHasMoreResult,
  hasSearched: getHasSearched,
  hasSearchedGroups: getHasSearchedGroups,
  isFetching: getIsFetchingResult,
  isRefreshing: getIsRefreshingResult,
  language: getSelectedLanguage,
  searchString: getSearchString,
  searchTags: getSearchTags,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetch: fetchArtTellGroups,
      refresh: refreshArtTellGroups,
    },
    dispatch
  ),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const PageArtificialTellsSearch = withConnect(_PageArtificialTellsSearch)
