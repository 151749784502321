import { faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import {
  faEnvelope,
  faExternalLinkAlt,
  faPhone,
} from '@fortawesome/free-solid-svg-icons'
import { shortenNumber } from '@tellonym/core/helpers'
import { Button, Modal } from 'antd'
import { assoc } from 'ramda'
import React from 'react'
import { config } from '../../../config'
import {
  Alert,
  Avatar,
  Box,
  Icon,
  Link,
  Text,
  TextTranslatable,
  TouchableOpacity,
  View,
  moment,
  styleSheets,
  theme,
} from '../../common'
import { IconVerified } from '../../common/components/IconVerified'
import {
  useQuickBanSexualMutation,
  useQuickBanSexualPermaMutation,
} from '../queries'
import { ModalFollowers } from './ModalFollowers'
import { ModalFollowings } from './ModalFollowings'
import { UserTypeBadge } from './UserTypeBadge'

const ProfileStatsItem = ({ name, value }) => (
  <View
    style={{
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingVertical: 8,
    }}>
    <Text>{name}</Text>
    <Text bold>{shortenNumber(value)}</Text>
  </View>
)

const ProfileStats = ({ profile }) => (
  <View style={{ width: '100%', marginTop: 24 }}>
    <Link onClick={() => ModalFollowers.show({ userId: profile.id })}>
      <ProfileStatsItem name="Follower" value={profile.follower} />
    </Link>
    <Link onClick={() => ModalFollowings.show({ userId: profile.id })}>
      <ProfileStatsItem name="Following" value={profile.followingCount} />
    </Link>
    <ProfileStatsItem name="Tells" value={profile.tellCount} />
    <ProfileStatsItem name="Answers" value={profile.answerCount} />
    <ProfileStatsItem name="Sent Tells" value={profile.sentTellCount} />
  </View>
)

const ConnectedAccountsItem = ({
  color = undefined,
  icon,
  onPress = undefined,
  to = undefined,
  style = {},
}) => (
  <View
    style={[
      {
        borderRadius: 25,
        marginRight: 8,
        ...style,
      },
      color && { backgroundColor: color },
      style,
    ]}>
    {to ? (
      <Link to={to} style={{ padding: 8 }}>
        <Icon icon={icon} style={{ color: theme.colors.white }} />
      </Link>
    ) : onPress ? (
      <TouchableOpacity onPress={onPress} style={{ padding: 8 }}>
        <Icon icon={icon} style={{ color: theme.colors.white }} />
      </TouchableOpacity>
    ) : (
      <Icon icon={icon} style={{ color: theme.colors.white, padding: 8 }} />
    )}
  </View>
)

const ConnectedAccounts = ({ profile }) => (
  <View style={{ marginTop: 24, flexDirection: 'row', alignSelf: 'center' }}>
    {profile.email && (
      <ConnectedAccountsItem
        icon={faEnvelope}
        color={theme.colors.niceYellow}
        onPress={() => Alert.alert('email', profile.email)}
      />
    )}
    {profile.phone && (
      <ConnectedAccountsItem
        icon={faPhone}
        color={theme.colors.niceGreen}
        onPress={() =>
          Alert.alert('phone', `${profile.phonePrefix} ${profile.phoneNumber}`)
        }
      />
    )}
    {profile.twitterUsername && (
      <ConnectedAccountsItem
        icon={faTwitter}
        color={theme.colors.twitter}
        to={`https://twitter.com/${profile.twitterUsername}`}
      />
    )}
    {profile.instagramUsername && (
      <ConnectedAccountsItem
        icon={faInstagram}
        color={theme.colors.instagramUsername}
        to={`https://instagram.com/${profile.instagramUsername}`}
        style={{
          background:
            'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)',
        }}
      />
    )}
  </View>
)

const modalAvatarCreator =
  ({ avatarFileName }) =>
  () =>
    Modal.info({
      width: '50%',
      content: (
        <Box flex={1} alignItems="center">
          <Avatar
            rectangle
            borderColor={theme.colors.black}
            user={{ avatarFileName }}
            size={380}
          />
        </Box>
      ),
    })

export const GeneralUserInfos = ({
  hasShadow = true,
  isHorizontal = false,
  profile,
}) => {
  const [shouldTranslate, setShouldTranslate] = React.useState(false)

  const toggleShouldTranslate = () => {
    setShouldTranslate(!shouldTranslate)
  }

  const { mutate: triggerQuickBanSexualMutation, isLoading } =
    useQuickBanSexualMutation()

  const quickBanSexual = () => {
    triggerQuickBanSexualMutation({ userId: profile.id })
  }

  const {
    mutate: triggerQuickBanSexualPermaMutation,
    isLoading: isLoadingPerma,
  } = useQuickBanSexualPermaMutation()

  const quickBanSexualPerma = () => {
    triggerQuickBanSexualPermaMutation({ userId: profile.id })
  }

  return (
    <View
      style={{
        padding: 16,
        borderRadius: 25,
        alignSelf: 'flex-start',
        backgroundColor: theme.colors.background,
        marginRight: isHorizontal ? 0 : 24,
        position: 'relative',
        boxShadow: hasShadow ? 'rgba(0, 0, 0, 0.2) 0px 2px 4px 0.1px' : 'none',
      }}>
      <View style={{ flexDirection: isHorizontal ? 'row' : 'column' }}>
        <View
          style={{
            width: isHorizontal ? 360 : 260,
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: isHorizontal ? 32 : 0,
          }}>
          <TouchableOpacity
            onPress={() =>
              window.open(`${config.webUrl}/${profile.username}`, '_blank')
            }
            style={{ position: 'absolute', top: 16, right: 16, zIndex: 99 }}>
            <Icon icon={faExternalLinkAlt} color={theme.colors.primary} />
          </TouchableOpacity>

          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {typeof profile.avatars !== 'undefined' ? (
              [
                profile.avatars.find(({ position }) => position === 1),
                profile.avatars.find(({ position }) => position === 0),
                profile.avatars.find(({ position }) => position === 2),
              ].map((avatar, index) => {
                const isMain = index === 1

                return (
                  <Box
                    key={avatar?.avatarFileName ?? index}
                    onPress={modalAvatarCreator({
                      avatarFileName: avatar?.avatarFileName,
                    })}>
                    <Avatar
                      rectangle
                      borderWidth={isMain ? 1 : 0}
                      borderColor={theme.colors.black}
                      user={assoc(
                        'avatarFileName',
                        avatar?.avatarFileName,
                        profile
                      )}
                      size={isMain ? 90 : 80}
                      style={{
                        marginRight: index === 0 ? -12 : 0,
                        marginLeft: index === 2 ? -12 : 0,
                        zIndex: isMain ? 1 : 0,
                      }}
                    />
                  </Box>
                )
              })
            ) : (
              <Avatar
                rectangle
                user={assoc('avatarFileName', profile?.avatarFileName, profile)}
                size={88}
              />
            )}
          </View>

          <View
            style={{
              justifyContent: 'center',
              marginTop: 12,
              alignItems: 'center',
            }}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text type="h3" center bold style={{ marginBottom: 4 }}>
                {profile.displayName}
              </Text>
              {(profile.verified || profile.isVerified) && (
                <Box marginLeft={4}>
                  <IconVerified size={16} />
                </Box>
              )}
            </View>
            <UserTypeBadge type={profile.type} />
            <Text
              center
              color={theme.colors.placeholder}
              style={{ marginTop: 4 }}>{`@${profile.username}`}</Text>
            <TextTranslatable.Pure
              shouldTranslate={shouldTranslate}
              style={{ marginTop: 8, textAlign: 'center' }}>
              {profile.aboutMe}
            </TextTranslatable.Pure>
            {profile.aboutMe !== '' && (
              <Button
                type="dashed"
                size="small"
                shape="round"
                onClick={toggleShouldTranslate}
                style={styleSheets.margin.top[8]}>
                {shouldTranslate ? 'original' : 'translate'}
              </Button>
            )}
            <Text color={theme.colors.placeholder} style={{ marginTop: 24 }}>
              {`joined ${moment(profile.registered).fromNow()}`}
            </Text>

            <Button
              shape="round"
              loading={isLoading}
              onClick={quickBanSexual}
              style={styleSheets.margin.top[12]}>
              Quick Ban Sexual
            </Button>
            <Button
              shape="round"
              loading={isLoadingPerma}
              onClick={quickBanSexualPerma}
              style={styleSheets.margin.top[12]}>
              Quick Perma Ban Sexual
            </Button>
          </View>
        </View>
        <ProfileStats profile={profile} />
      </View>
      <ConnectedAccounts profile={profile} />
    </View>
  )
}
