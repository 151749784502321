import {
  COHORT_TYPE,
  countriesToTrack,
  INTERVAL_TYPE,
} from '@tellonym/enums/lib/Stats'
import { Button } from 'antd'
import { compose, pick } from 'ramda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Helmet from 'react-helmet'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { getIsSidemenuShown } from '../../app/selectors'
import { moment, styleSheets, Text, theme, View } from '../../common'
import { useQueryParams } from '../../common/hooks'
import { SideMenu } from '../../navigation/components/SideMenu'
import { refreshAvailableShortNames, refreshDiff } from '../actions'
import { dateTypes } from '../constants'
import {
  getAvailableShortNamesData,
  getDiffData,
  getIsRefreshingDiff,
} from '../selectors'
import { dateTypeToTimespan } from '../services'
import { InputQueryParam } from './InputQueryParam'
import { ShortNamesSelector } from './ShortNamesSelector'
import { StatsTable } from './StatsTable'
import { TimeFramePicker } from './TimeFramePicker'

const defaultQueryParams = {
  dateType: dateTypes.LAST_14_DAYS,
  startDate: undefined,
  endDate: undefined,
  countryCode: 'DE',
  experimentId: undefined,
  intervalType: INTERVAL_TYPE.DAILY,
  shortNames: [],
  cohorts: undefined,
}

const styles = {
  buttonCsv: { position: 'absolute', left: 24, bottom: 0 },
  headerContainer: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    marginTop: 24,
    marginBottom: 12,
  },
}

const Header = React.memo(
  ({ hasSetRequiredValues, isLoading, onPressDownloadCsv, onPressRefresh }) => {
    const groups = useSelector(getAvailableShortNamesData)

    return (
      <View style={{ position: 'relative', marginBottom: 24 }}>
        <View style={styles.headerContainer}>
          <View style={styleSheets.flex[1]}>
            <Text bold type="h1" style={styleSheets.margin.bottom[24]}>
              Statistic Changes
            </Text>

            <View style={styleSheets.margin.left[12]}>
              <TimeFramePicker
                defaultQueryParams={defaultQueryParams}
                isLoading={isLoading}
              />

              <InputQueryParam
                defaultQueryParams={defaultQueryParams}
                isOptional
                isDisabled={isLoading}
                queryParamName="experimentId"
                text="Experiment Id"
                validateValue={(value) => Number.isInteger(Number(value))}
              />

              <InputQueryParam
                defaultQueryParams={defaultQueryParams}
                hasMultipleValues
                isOptional
                isDisabled={isLoading}
                queryParamName="cohorts"
                text="Cohorts"
                submitTransformer={(array) => array.join(',')}
                options={Object.values(COHORT_TYPE).map((v) => ({
                  label: String(v),
                  value: String(v),
                }))}
              />

              <InputQueryParam
                defaultQueryParams={defaultQueryParams}
                options={countriesToTrack.map((v) => ({ label: v, value: v }))}
                isDisabled={isLoading}
                queryParamName="countryCode"
                text="Country"
                validateValue={(value) =>
                  countriesToTrack.indexOf(value.toUpperCase()) > -1
                }
              />
            </View>
          </View>

          <View style={[styleSheets.flex[2], styleSheets.margin.top[24]]}>
            <ShortNamesSelector groups={groups} />
          </View>
        </View>
        <Button
          disabled={!hasSetRequiredValues || isLoading}
          type="dashed"
          onClick={onPressDownloadCsv}
          style={styles.buttonCsv}>
          Download .csv
        </Button>

        <View style={styleSheets.alignItems.center}>
          <Button
            type="primary"
            shape="round"
            disabled={!hasSetRequiredValues}
            loading={isLoading}
            onClick={onPressRefresh}>
            Refresh
          </Button>
        </View>
      </View>
    )
  }
)

const getHeaderData = (metrics) =>
  metrics.map(({ date, calendarWeek }) => {
    const data = date
      ? {
          id: date,
          metric: moment(date).format('DD.MM.YY'),
          submetric: moment(date).format('ddd'),
        }
      : {
          id: `${calendarWeek.year}-${calendarWeek.week}`,
          description: `Week started at ${moment()
            .week(calendarWeek.week)
            .day(1)
            .format('DD.MM.YYYY')}`,
          metric: `Week ${calendarWeek.week}`,
          submetric: moment().week(calendarWeek.week).day(1).format('MMM'),
        }

    return data
  })

const _PageDiff = ({ actions, diffData, isRefreshing, isSidemenuShown }) => {
  const [queryParams] = useQueryParams(defaultQueryParams)
  const [containerStyles, setContainerStyles] = useState({
    width: window.tnym.getWidth() - (isSidemenuShown ? SideMenu.width : 0),
    height: window.tnym.getHeight(),
  })

  const hasData = !!diffData.ids[0]
  const metrics = hasData
    ? diffData.data[diffData.ids[0]].data.map(pick(['date', 'calendarWeek']))
    : []

  const hasSetRequiredValues =
    queryParams.countryCode &&
    queryParams.shortNames &&
    queryParams.shortNames.length !== 0

  const onPressRefresh = useCallback(() => {
    if (hasSetRequiredValues) {
      const { endDate, timespan } = dateTypeToTimespan(queryParams)

      const payload = {
        ...queryParams,
        endDate,
        shortNames: Object.values(queryParams.shortNames),
        timespan,
      }

      actions.refresh(payload)
    }
  }, [hasSetRequiredValues, queryParams])

  const headerTitle = useMemo(
    () =>
      [
        'Diff',
        queryParams.countryCode ? queryParams.countryCode.toUpperCase() : '',
        queryParams.cohorts
          ? queryParams.cohorts
              .split(',')
              .map((v) => v.trim())
              .join(', ')
          : '',
        queryParams.experimentId || '',
        '- Tellonym Modcp',
      ].join(' '),
    [queryParams.countryCode, queryParams.cohorts, queryParams.experimentId]
  )

  const onPressDownloadCsv = useCallback(() => {
    if (diffData.ids.length === 0) {
      return
    }

    const arrayData = [['Date']]

    // eslint-disable-next-line no-unused-vars
    for (const name of diffData.ids) {
      const item = diffData.data[name]

      arrayData[0].push(name)

      for (let i = 0; i < item.data.length; i++) {
        const entry = item.data[i]
        const existingArray = arrayData[i + 1]

        if (Array.isArray(existingArray)) {
          existingArray.push(entry.value)
        } else {
          const time =
            entry.date ||
            ` ${entry.calendarWeek.year}-${entry.calendarWeek.week}`
          arrayData[i + 1] = [time, entry.value]
        }
      }
    }

    const data = arrayData.map((e) => e.join(',')).join('\n')

    const csvContent = `data:text/csv;charset=utf-8,${data}`
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', `${new Date().getTime()}.csv`)
    document.body.appendChild(link) // Required for FF
    link.click()
  }, [diffData.ids])

  useEffect(() => {
    actions.refreshAvailableShortNames()
  }, [])

  useEffect(() => {
    setContainerStyles({
      width: window.tnym.getWidth() - (isSidemenuShown ? SideMenu.width : 0),
      height: window.tnym.getHeight(),
    })
  }, [isSidemenuShown])

  useEffect(() => {
    onPressRefresh()
  }, [])

  return (
    <View
      style={[
        containerStyles,
        {
          backgroundColor: theme.colors.background,
        },
      ]}>
      <Helmet title={headerTitle} />
      <Header
        hasSetRequiredValues={hasSetRequiredValues}
        isLoading={isRefreshing}
        onPressRefresh={onPressRefresh}
        onPressDownloadCsv={onPressDownloadCsv}
      />
      <StatsTable
        hasGraph
        items={diffData}
        headerData={getHeaderData(metrics)}
        hasCopyButton
      />
    </View>
  )
}

const mapStateToProps = createStructuredSelector({
  diffData: getDiffData,
  isRefreshing: getIsRefreshingDiff,
  isSidemenuShown: getIsSidemenuShown,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { refresh: refreshDiff, refreshAvailableShortNames },
    dispatch
  ),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const enhancer = compose(withConnect)

export const PageDiff = enhancer(_PageDiff)
