import { events } from '@tellonym/core/events'
import * as actions from '@tellonym/core/store/sagas/actions'
import * as app from '../app/sagas'
import * as artificialTells from '../artificialTells/sagas'
import * as filter from '../filter/sagas'
import * as moderator from '../moderator/sagas'
import * as profile from '../profile/sagas'
import * as share from '../share/sagas'
import * as statistics from '../statistics/sagas'
import * as tools from '../tools/sagas'
import * as user from '../user/sagas'
import { eventFactory } from './eventFactories'
import { rootEventHandler } from './eventHandlers'

const modules = [
  app,
  artificialTells,
  filter,
  moderator,
  profile,
  share,
  statistics,
  tools,
  user,
]

export const rootSaga = function* rootSaga() {
  yield events.subscribe(modules, eventFactory, rootEventHandler)
  yield actions.subscribe(modules)
}
