import { splitEvery } from 'ramda'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { getIsSidemenuShown } from '../../app/selectors'
import { AnswerItem, LoadingIndicator, View } from '../../common'
import { Header } from '../../navigation/components/Header'
import { SideMenu } from '../../navigation/components/SideMenu'
import { refreshRecentAnswers } from '../actions'
import {
  getHasMore,
  getIsFetching,
  getIsRefreshing,
  getRecentAnswersData,
  get_rerenderItem,
} from '../selectors'

const _PageRecentAnswers = ({
  actions,
  data,
  isRefreshing,
  isSidemenuShown,
}) => {
  const paddingHorizontal = 16
  const availableWidth =
    window.tnym.getWidth() -
    ((isSidemenuShown ? SideMenu.width : 0) + paddingHorizontal)
  const width = 450

  const [columnGroups, setColumnGroups] = useState([])

  useEffect(() => {
    actions.refresh()

    const interval = setInterval(() => {
      actions.refresh()
    }, 60000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (data.ids.length !== 0) {
      const columns = Math.floor(availableWidth / width)
      const idChunkSize = data.ids.length / columns
      const _columnGroups = splitEvery(idChunkSize, data.ids)

      setColumnGroups(_columnGroups)
    }
  }, [isSidemenuShown, data.ids])

  return (
    <View
      style={{
        flex: 1,
        position: 'relative',
        alignItems: 'center',
        marginTop: Header.height + 12,
      }}>
      <LoadingIndicator isLoading={isRefreshing} />
      <View style={{ flexDirection: 'row' }}>
        {columnGroups.map((idChunk) => (
          <View
            key={idChunk[0]}
            style={{
              flex: 1,
              paddingHorizontal,
            }}>
            {idChunk.map((id) => (
              <View key={id} style={{ width }}>
                <AnswerItem
                  hasSeparator
                  isCurrentUser={false}
                  item={data.data[id]}
                />
              </View>
            ))}
          </View>
        ))}
      </View>
    </View>
  )
}

const mapStateToProps = createStructuredSelector({
  _rerenderItem: get_rerenderItem,
  isFetching: getIsFetching,
  isRefreshing: getIsRefreshing,
  isSidemenuShown: getIsSidemenuShown,
  hasMore: getHasMore,
  data: getRecentAnswersData,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ refresh: refreshRecentAnswers }, dispatch),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const PageRecentAnswers = withConnect(_PageRecentAnswers)

export { PageRecentAnswers }
