import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons'
import useChange from '@react-hook/change'
import { langDetectObjectsByType1 } from '@tellonym/enums/lib/Language'
import {
  ARTIFICIAL_TELL_GROUP_LANG_STATUS,
  ARTIFICIAL_TELL_VARIANCE_STATUS,
} from '@tellonym/enums/lib/Tell'
import {
  Breadcrumb,
  Button,
  DatePicker,
  InputNumber,
  PageHeader,
  Popconfirm,
  Radio,
  Select,
  Switch,
  Tag,
} from 'antd'
import { pick } from 'ramda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { getPermissions } from '../../app/selectors'
import {
  Alert,
  FlatList,
  helpers,
  history,
  hooks,
  Icon,
  Input as CustomInput,
  moment,
  styleSheets,
  Text,
  theme,
  TouchableOpacity,
  View,
} from '../../common'
import { languageEnumAsString } from '../../common/helpers'
import {
  add,
  addLanguage,
  changeApproval,
  declineGroup,
  editGroup,
  fetch,
  getTags,
  refresh,
  refreshSuggestions,
  removeLanguage,
  review,
  setSelectedGroupId,
  setSelectedLanguage,
} from '../actions'
import { supportedLanguages } from '../constants'
import {
  getError,
  getGroupDetailsData,
  getGroupDetailsInfo,
  getGroupDetailsSuggestions,
  getHasMoreGroupDetails,
  getIsFetchingGroupDetails,
  getIsRefreshingGroupDetails,
  getIsSaving,
  getSelectedGroupId,
  getSelectedLanguage,
  getTagNames,
} from '../selectors'
import { ArtificialTellItem } from './ArtificialTellItem'
import { ArtificialTellStats } from './ArtificialTellStats'
import { ButtonBack } from './ButtonBack'
import { NewEntry } from './NewEntry'
import { SelectorsLanguage } from './SelectorsLanguage'
import { TierRectangle } from './TierRectangle'

const styles = {
  datePicker: { width: 140, marginRight: 4 },
  checkbox: {
    marginBottom: 4,
  },
  groupHeader: {
    backgroundColor: theme.colors.antdBackgroundElevated,
    borderBottomWidth: 0.5,
    borderBottomColor: theme.colors.antdBackgroundElevatedBorder,
    borderBottomStyle: 'solid',
    marginBottom: 8,
    paddingBottom: 0,
  },
  groupHeaderContainer: {
    flexDirection: 'row',
  },
  groupHeaderLoadingPlaceholder: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  groupHeaderInput: {
    backgroundColor: theme.colors.antdBackgroundElevated,
    marginBottom: 0,
  },
  groupHeaderInputInvalid: {
    borderBottomColor: theme.colors.pink,
    borderBottomStyle: 'solid',
  },
  groupHeaderInputInvalidHint: {
    color: theme.colors.pink,
    fontSize: 12,
  },
  suggestionItem: {
    backgroundColor: theme.colors.background,
    flexDirection: 'row',
    borderWidth: 0,
    borderColor: theme.colors.borderLightGrey,
    borderStyle: 'solid',
    paddingVertical: 12,
    paddingHorizontal: 48,
    alignItems: 'center',
    onHover: {
      boxShadow: theme.styles.shadowInsetVertical,
      cursor: 'pointer',
    },
  },
  tagSectionContainer: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '80%',
  },
  dateSectionContainer: {
    marginTop: 4,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '80%',
  },
  title: {
    backgroundColor: theme.colors.borderLightGrey,
    flexDirection: 'row',
    borderWidth: 0,
    borderBottomWidth: 1,
    borderColor: theme.colors.borderLightGrey,
    borderStyle: 'solid',
    paddingVertical: 6,
    paddingHorizontal: 24,
    alignItems: 'center',
  },
  translateContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
  },
  groupReviewStatusLabelContainer: {
    justifyContent: 'center',
    width: '100%',
    marginBottom: 8,
  },
  groupReviewStatusLabel: {
    borderRadius: 6,
    borderStyle: 'solid',
    borderWidth: 1,
    marginBottom: 4,
    paddingHorizontal: 12,
    paddingVertical: 6,
  },
}

const GroupReviewStatusLabel = ({
  actions,
  group,
  groupId,
  isDisabled,
  isLoading,
  language,
}) => {
  const isGroupDeclined =
    group.status === ARTIFICIAL_TELL_GROUP_LANG_STATUS.DECLINED

  const isInactive = isDisabled || isLoading

  const onChange = (e) => {
    const { value: isReviewed } = e.target

    if (typeof isReviewed === 'boolean' && isReviewed !== group.isReviewed) {
      actions.review({
        id: groupId,
        isReviewed,
        language,
      })
    }
  }
  return (
    <View style={styles.groupReviewStatusLabelContainer}>
      <Radio.Group
        disabled={isInactive}
        value={group.isReviewed}
        onChange={onChange}
        optionType="button"
        buttonStyle="solid"
        size="large"
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          width: '100%',
        }}>
        <Radio.Button
          className={isGroupDeclined || isLoading ? 'inactive' : 'success'}
          value
          color
          style={{ textAlign: 'center' }}>
          Reviewed
        </Radio.Button>
        <Radio.Button
          className={isGroupDeclined || isLoading ? 'inactive' : 'warning'}
          value={false}
          style={{ textAlign: 'center' }}>
          Not reviewed
        </Radio.Button>
      </Radio.Group>
    </View>
  )
}

const DateSection = ({ group, isDisabled }) => {
  const [notAfter, setNotAfter] = useState(group.notAfter)
  const [notBefore, setNotBefore] = useState(group.notBefore)

  const dispatch = useDispatch()

  const onChangeStartDate = (date, dateString) => {
    if (dateString !== moment(notBefore).format('YYYY-MM-DD')) {
      setNotBefore(date)
      dispatch(editGroup({ ...group, notBefore: dateString ?? null }))
    }
  }

  const onChangeEndDate = (date, dateString) => {
    if (dateString !== moment(notAfter).format('YYYY-MM-DD')) {
      setNotAfter(date)
      dispatch(editGroup({ ...group, notAfter: dateString ?? null }))
    }
  }

  const onChangeReaskInDays = (reaskInDays) => {
    dispatch(editGroup({ ...group, reaskInDays }))
  }

  const onChangeIsRepeatedYearly = (isRepeatedYearly) => {
    dispatch(
      editGroup({
        ...group,
        isRepeatedYearly,
      })
    )
  }

  const onPressTagCheckbox = (tag, isActive) => {
    const newTags = isActive
      ? [...group.tags, tag]
      : group.tags.filter((curr) => curr !== tag)

    dispatch(
      editGroup({
        ...group,
        tags: newTags,
      })
    )
  }

  useEffect(() => {
    setNotAfter(group.notAfter)
  }, [group.notAfter])

  useEffect(() => {
    setNotBefore(group.notBefore)
  }, [group.notBefore])

  return (
    <>
      <View style={styles.dateSectionContainer}>
        <DatePicker
          value={notBefore ? moment(notBefore) : undefined}
          disabled={isDisabled}
          onChange={onChangeStartDate}
          placeholder="Start date"
          style={styles.datePicker}
        />
        <DatePicker
          value={notAfter ? moment(notAfter) : undefined}
          disabled={isDisabled}
          onChange={onChangeEndDate}
          placeholder="End date"
          style={styles.datePicker}
        />
        <InputNumber
          min={0}
          max={365}
          value={group.reaskInDays ?? 0}
          onChange={onChangeReaskInDays}
          addonBefore="Every"
          addonAfter="days"
          style={{ maxWidth: 180 }}
        />
      </View>

      <View
        style={{
          flexDirection: 'row',
          alignItems: 'flex-start',
          marginTop: 8,
        }}>
        <Switch
          checked={group.tags.includes('activational')}
          disabled={isDisabled}
          onChange={(isActivational) =>
            onPressTagCheckbox('activational', isActivational)
          }
          checkedChildren="Activational"
          unCheckedChildren="Activational"
          style={{ marginRight: 6 }}
        />
        <Switch
          checked={group.isRepeatedYearly}
          disabled={isDisabled}
          onChange={onChangeIsRepeatedYearly}
          checkedChildren="Repeated yearly"
          unCheckedChildren="Repeated yearly"
        />
      </View>
    </>
  )
}

const validateTag = (text) => {
  const hasWhiteSpace = /\s/g.test(text)

  if (hasWhiteSpace) {
    Alert.error('Spaces are not allowed in tags.')

    return false
  }

  return true
}

const TagsSection = ({
  isDisabled: isDisabledGroup,
  isLoading: isLoadingGroup,
  group,
}) => {
  const dispatch = useDispatch()

  const language = useSelector(getSelectedLanguage)
  const tagNames = useSelector(getTagNames)

  const [isLoading, setIsLoading] = useState(isLoadingGroup)

  const tagSuggestions = useMemo(
    () =>
      tagNames.map((name) => ({
        label: name,
        value: name,
      })),
    [tagNames]
  )

  const languages =
    group.languages?.reduce((acc, { language }) => {
      if (languageEnumAsString[language]) {
        acc.push(languageEnumAsString[language])
      }

      return acc
    }, []) ?? []

  const languageSuggestions = useMemo(
    () =>
      supportedLanguages.reduce((acc, supportedLang) => {
        const isNew = group.languages?.every?.(
          ({ language }) => language !== langDetectObjectsByType1[supportedLang]
        )

        if (isNew) {
          acc.push({
            label: supportedLang,
            value: supportedLang,
          })
        }

        return acc
      }, []),
    [group.languages]
  )

  const isDisabled = isLoadingGroup || isDisabledGroup

  const handleLanguageChange = (values) => {
    const langaugesToAdd = values.reduce((acc, value) => {
      const isValid = supportedLanguages.includes(value)

      if (isValid) {
        const languageAsEnum = langDetectObjectsByType1[value]

        const isNew = group.languages?.every?.(
          ({ language }) => language !== languageAsEnum
        )

        if (isNew) {
          acc.push({
            type: ARTIFICIAL_TELL_GROUP_LANG_STATUS.DRAFT,
            language: languageAsEnum,
          })
        }
      }

      return acc
    }, [])

    const languagesToRemove = group.languages?.filter?.(({ language }) =>
      values.every((value) => language !== langDetectObjectsByType1[value])
    )

    if (langaugesToAdd.length) {
      dispatch(
        addLanguage({
          groupId: group.id,
          groupLanguage: language,
          languages: langaugesToAdd,
        })
      )
    }

    if (languagesToRemove?.length) {
      dispatch(
        removeLanguage({
          groupId: group.id,
          groupLanguage: language,
          languages: languagesToRemove,
        })
      )
    }
  }

  const handleTagChange = (values) => {
    const isValid = values.every(validateTag)

    if (isValid) {
      dispatch(
        editGroup({
          ...group,
          tags: values,
        })
      )
    }
  }

  useEffect(() => {
    if (tagNames.length === 0) {
      dispatch(getTags())
    }
  }, [])

  useChange(isLoadingGroup, (nextIsLoadingGroup, prevIsLoadingGroup) => {
    if (prevIsLoadingGroup && nextIsLoadingGroup === false) {
      setIsLoading(isLoadingGroup)
    }
  })

  return (
    <React.Fragment>
      <View style={styles.tagSectionContainer}>
        <Select
          loading={isLoading}
          disabled={isDisabled}
          mode="tags"
          placeholder="Languages"
          onChange={handleLanguageChange}
          options={languageSuggestions}
          value={languages}
          style={{ width: '70%' }}
          tagRender={({ label, value, closable, onClose }) => {
            const status = group.languages.reduce((acc, lang) => {
              if (
                typeof acc === 'undefined' &&
                lang.language === langDetectObjectsByType1[value]
              ) {
                return lang.status
              }

              return acc
            }, undefined)

            const color =
              status === ARTIFICIAL_TELL_GROUP_LANG_STATUS.ACTIVE
                ? 'green'
                : status === ARTIFICIAL_TELL_GROUP_LANG_STATUS.DRAFT
                ? 'yellow'
                : status === ARTIFICIAL_TELL_GROUP_LANG_STATUS.DECLINED
                ? 'red'
                : 'grey'

            return (
              <Tag
                key={`${value}${status}`}
                color={color}
                onMouseDown={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
                onClick={() => {
                  dispatch(setSelectedLanguage(value))
                }}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3, cursor: 'pointer' }}>
                {label}
              </Tag>
            )
          }}
        />
        <Select
          loading={isLoading}
          disabled={isDisabled}
          mode="tags"
          placeholder="Select tags"
          onChange={handleTagChange}
          options={tagSuggestions}
          value={group.tags}
          style={{ width: '70%', marginTop: 4 }}
        />
      </View>
    </React.Fragment>
  )
}

const SuggestionItem = ({ item }) => {
  const { id, hasSeparator } = item

  return (
    <TouchableOpacity
      onPress={() => {
        history.push(`/artificialtells/${id}/answers`)
      }}
      style={[
        styles.suggestionItem,
        { borderBottomWidth: hasSeparator ? 1 : 0 },
      ]}>
      <Text style={{ flex: 7 }}>{item.content}</Text>
    </TouchableOpacity>
  )
}

const Suggestions = ({ suggestions }) => {
  return (
    <View style={{ position: 'relative' }}>
      <View style={{ justifyContent: 'center' }}>
        <Text bold style={{ marginLeft: 48 }}>
          Suggestions
        </Text>
      </View>
      <View style={{ position: 'absolute', top: 65 }}>
        {suggestions.ids.map((id) => {
          const suggestionItem = suggestions.data[id]

          return <SuggestionItem key={id} item={suggestionItem} />
        })}
      </View>
    </View>
  )
}

const _GroupHeader = ({
  actions,
  artificialTellIds,
  groupId,
  group,
  isGroupNotFound,
  isSaving,
  language,
  onChangeShouldTranslate,
  permissions,
  shouldTranslate,
  suggestions,
}) => {
  // const [input, setInput] = useState('')

  const statsItems = useMemo(
    () =>
      pick(
        [
          'status',
          'answerRate',
          'answerPctLong',
          'answerPctShort',
          'amountSent',
          'amountTells',
          'amountDeclined',
          'amountDrafts',
        ],
        group
      ),
    [group]
  )
  const isLoaded = !!group.name

  const isGroupDeclined =
    group.status === ARTIFICIAL_TELL_GROUP_LANG_STATUS.DECLINED

  const hasEditGroupPermissions = helpers.checkPermission(
    'artificialtells.group.edit',
    permissions
  )
  const [inputDescriptionValue, setInputDescriptionValue] = useState(group.description || '') // prettier-ignore
  const [inputNameValue, setInputNameValue] = useState(group.name || '')
  const [inputTitleValue, setInputTitleValue] = useState(group.title || '')
  const [isGroupNameValid, setIsGroupNameValid] = useState(true)

  const inputRef = useRef(null)

  const getIsAlphanumeric = (str) => {
    const regex = /^[a-zA-Z0-9]+$/gm
    return regex.test(str)
  }

  const getIsDirty = () =>
    group.description !== inputDescriptionValue ||
    group.name !== inputNameValue ||
    group.title !== inputTitleValue

  const onChangeName = (value) => {
    setInputNameValue(value)
    setIsGroupNameValid(getIsAlphanumeric(value))
  }

  const onPressSave = () =>
    typeof group?.id !== 'undefined' &&
    actions.editGroup({
      ...group,
      description: inputDescriptionValue,
      name: inputNameValue,
      title: inputTitleValue,
      language,
    })

  const onSubmitNewEntry = ({ inputValue }) => {
    if (inputValue !== '') {
      actions.add({
        groupId,
        language,
        tellContent: inputValue,
      })
    }
  }

  hooks.useKeyboardShortcutToSubmit({ inputRef, onSubmit: onPressSave })
  hooks.useEscapeKey({ inputRef, onPress: () => inputRef.current.blur() })

  useEffect(() => {
    if (group.description) {
      setInputDescriptionValue(group.description)
    }

    if (group.name) {
      setInputNameValue(group.name)
    }

    if (group.title) {
      setInputTitleValue(group.title)
    }
  }, [group.description, group.name, group.title])

  if (isGroupNotFound && !group.name) {
    return null
  }

  return (
    <PageHeader
      ghost={false}
      onBack={() => {}}
      backIcon={
        <ButtonBack
          onPress={() => history.goBack()}
          style={{ margin: 6, width: 30, height: 30 }}
        />
      }
      title=" "
      breadcrumb={
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Breadcrumb>
            <Breadcrumb.Item key="Overview">
              <a
                href="javascript:void(0);"
                onClick={(e) => {
                  e.preventDefault()
                  history.push({
                    pathname: `/artificialtells/${language}/list`,
                    search: history.search,
                  })
                }}>
                Overview
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item
              key="Overview"
              className={`${group.name}_${language}`}>{`${
              group.name
            }_${language}_${
              {
                [ARTIFICIAL_TELL_GROUP_LANG_STATUS.ACTIVE]: 'active',
                [ARTIFICIAL_TELL_GROUP_LANG_STATUS.DRAFT]: 'draft',
                [ARTIFICIAL_TELL_GROUP_LANG_STATUS.DECLINED]: 'declined',
              }[group.status]
            }`}</Breadcrumb.Item>
          </Breadcrumb>
          {group.editedBy?.lastEditedTime ? (
            <Text
              type="note"
              color={
                theme.colors.antdBreadcrumbInactive
              }>{`last edited ${moment(group.editedBy.lastEditedTime).format(
              'DD.MM.HH:mm[h]'
            )} by ${group.editedBy.username ?? 'unknown'}`}</Text>
          ) : null}
        </View>
      }
      style={styles.groupHeader}
      extra={[
        <Button
          key="Accept All"
          size="large"
          onClick={() => {
            actions.changeApproval({
              artificialTellIds,
              groupId,
              language,
              type: ARTIFICIAL_TELL_VARIANCE_STATUS.ACTIVE,
            })
          }}>
          Accept All
        </Button>,
        <Popconfirm
          key="Popconfirm"
          placement="bottomLeft"
          title={`Do you really want to decline ${language.toUpperCase()}?`}
          onConfirm={() => {
            actions.declineGroup({ id: groupId, language })
          }}
          okText="Yes">
          <Button size="large">
            {isGroupDeclined
              ? `${language.toUpperCase()} declined`
              : `Decline ${language.toUpperCase()}`}
          </Button>
        </Popconfirm>,
        <SelectorsLanguage key="SelectorsLanguage" />,
      ]}>
      <View style={styles.groupHeaderContainer}>
        <View style={styleSheets.flex[1]}>
          {isLoaded ? (
            <View style={styleSheets.flex.direction.row}>
              <View style={styleSheets.margin.right[8]}>
                <TierRectangle
                  isEditable
                  group={group}
                  size={100}
                  style={styleSheets.margin.top[8]}
                />
              </View>
              <View style={[styleSheets.flex[1], styleSheets.margin.right[12]]}>
                <CustomInput
                  multiline
                  maxRows={3}
                  forwardRef={inputRef}
                  onChangeText={(value) => setInputTitleValue(value)}
                  placeholder="Title"
                  value={inputTitleValue}
                  style={styles.groupHeaderInput}
                />
                <CustomInput
                  multiline
                  maxRows={3}
                  disabled={!group.name}
                  forwardRef={inputRef}
                  onChangeText={onChangeName}
                  placeholder="Name"
                  value={inputNameValue}
                  style={{
                    ...styles.groupHeaderInput,
                    ...(!isGroupNameValid && styles.groupHeaderInputInvalid),
                  }}
                />
                <CustomInput
                  multiline
                  maxRows={3}
                  forwardRef={inputRef}
                  onChangeText={(value) => setInputDescriptionValue(value)}
                  placeholder="Description"
                  value={inputDescriptionValue}
                  style={styles.groupHeaderInput}
                />
                <View style={{ height: 20, marginBottom: 16 }}>
                  {getIsDirty() &&
                    isGroupNameValid &&
                    group.name &&
                    inputNameValue !== '' && (
                      <Text
                        onPress={onPressSave}
                        color={theme.colors.primary}
                        style={{
                          alignSelf: 'flex-start',
                        }}>
                        Save
                      </Text>
                    )}
                </View>
              </View>
            </View>
          ) : (
            <View style={{ height: 143 }}>
              <Text style={styles.groupHeaderLoadingPlaceholder}>
                Loading...
              </Text>
            </View>
          )}
          <TagsSection
            isDisabled={!hasEditGroupPermissions}
            isLoading={!isLoaded}
            group={group}
          />
          <DateSection
            group={group}
            isDisabled={!isLoaded || !hasEditGroupPermissions}
            language={language}
          />
        </View>
        <View style={styleSheets.alignItems.end}>
          <GroupReviewStatusLabel
            actions={actions}
            group={group}
            groupId={groupId}
            isDisabled={!hasEditGroupPermissions}
            isLoading={!isLoaded || isSaving}
            language={language}
          />
          <ArtificialTellStats
            items={statsItems}
            style={{
              backgroundColor: theme.colors.antdBackgroundElevated,
              marginTop: 8,
            }}
          />
        </View>
      </View>
      <View style={[styleSheets.flex[1], styleSheets.flex.direction.row]}>
        <View style={[styleSheets.flex[3], styleSheets.flex.direction.row]}>
          <View style={[styleSheets.flex[1], { marginTop: 8 }]}>
            <NewEntry
              isDisabled={isGroupDeclined}
              onSubmit={onSubmitNewEntry}
            />
          </View>
          <View style={styles.translateContainer}>
            <Text>Translate</Text>
            <Switch
              checked={shouldTranslate}
              checkedChildren={<Icon icon={faGlobeEurope} />}
              unCheckedChildren={<Icon icon={faGlobeEurope} />}
              onChange={onChangeShouldTranslate}
              style={styleSheets.margin.left[12]}
            />
          </View>
        </View>
        <View style={{ flex: 2, justifyContent: 'center' }}>
          <Suggestions suggestions={suggestions} />
        </View>
      </View>
    </PageHeader>
  )
}

const GroupHeader = connect(
  (state) => ({
    permissions: getPermissions(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators(
      { add, changeApproval, declineGroup, review, editGroup },
      dispatch
    ),
  })
)(_GroupHeader)

const Item = (props) => (
  <View
    style={{
      flexDirection: 'row',
      paddingTop: 8,
      paddingBottom: 8,
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: 0,
      borderBottomWidth: 0.5,
      borderColor: props.hasSeparator
        ? theme.colors.borderLightGrey
        : theme.colors.background,
      borderStyle: 'solid',
    }}>
    <View style={{ flex: 3, justifyContent: 'center' }}>
      <ArtificialTellItem {...props} />
    </View>
    <View style={{ flex: 2 }} />
  </View>
)

const _PageArtificialTellsGroupDetails = ({
  actions,
  data,
  error,
  group = {},
  groupId,
  hasMore,
  isSaving,
  language,
  isFetching,
  isRefreshing,
  match,
  suggestions,
}) => {
  const [shouldTranslate, setShouldTranslate] = useState(false)
  const { groupId: urlGroupId } = match?.params ?? {}

  const groupStats = pick(
    ['answerPctLong', 'answerPctShort', 'answerRate'],
    group
  )

  const onChangeShouldTranslate = (isChecked) => {
    setShouldTranslate(isChecked)
  }

  const renderHeader = useCallback(
    (props) => (
      <GroupHeader
        artificialTellIds={data.ids}
        group={group}
        isGroupNotFound={
          error && (error.status === 404 || error.status === 400)
        }
        isSaving={isSaving}
        onChangeShouldTranslate={onChangeShouldTranslate}
        shouldTranslate={shouldTranslate}
        suggestions={suggestions}
        {...props}
      />
    ),
    [error, group, data.ids, isSaving]
  )

  useEffect(() => {
    const _groupId = parseInt(urlGroupId, 10)

    if (_groupId) {
      actions.setSelectedGroupId(_groupId)
    }
  }, [urlGroupId])

  useEffect(() => {
    if (groupId && language) {
      actions.refreshSuggestions({ groupId, language })
    }
  }, [groupId])

  useEffect(() => {
    if (groupId && language) {
      actions.refresh({ groupId, language })
    }
  }, [groupId, language])

  return (
    <FlatList
      actions={{
        fetch: (p) => actions.fetch({ language, groupId, ...p }),
        refresh: (payload) =>
          actions.refresh({
            language,
            groupId,
            ...payload,
          }),
      }}
      component={Item}
      extraData={{
        hasMore,
        isFetching,
        isRefreshing,
        group,
      }}
      extraProps={{ groupId, groupStats, language, shouldTranslate }}
      hasMore={hasMore}
      items={data}
      isFetching={isFetching}
      isRefreshing={isRefreshing || isSaving}
      renderHeader={renderHeader}
    />
  )
}

const mapStateToProps = createStructuredSelector({
  data: getGroupDetailsData,
  error: getError,
  group: getGroupDetailsInfo,
  groupId: getSelectedGroupId,
  language: getSelectedLanguage,
  hasMore: getHasMoreGroupDetails,
  isSaving: getIsSaving,
  isFetching: getIsFetchingGroupDetails,
  isRefreshing: getIsRefreshingGroupDetails,
  suggestions: getGroupDetailsSuggestions,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetch,
      refresh,
      refreshSuggestions,
      setSelectedGroupId,
    },
    dispatch
  ),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const PageArtificialTellsGroupDetails = withConnect(
  _PageArtificialTellsGroupDetails
)
