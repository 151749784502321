import { DETECTABLE_LANGUAGE } from '@tellonym/enums/lib/Language'
import { languageEnumAsString } from '../common/helpers'

export const name = 'artificialTells'

const availableLanguages = [
  DETECTABLE_LANGUAGE.ENGLISH,
  DETECTABLE_LANGUAGE.GERMAN,
  DETECTABLE_LANGUAGE.ARABIC,
  DETECTABLE_LANGUAGE.ITALIAN,
  DETECTABLE_LANGUAGE.PORTUGUESE,
  DETECTABLE_LANGUAGE.ROMANIAN,
  DETECTABLE_LANGUAGE.SWEDISH,
]

export const supportedLanguages = availableLanguages.map(
  (i) => languageEnumAsString[i]
)

/**
 * If you want to add a new order type you just have to add it in
 * - orderTypes constant
 * - groupOrderTypesList (this is the order of orderTypes)
 * - getSortedItemsForType in GroupFilter.js (your sorting function)
 */
export const orderTypes = {
  ANSWER_RATE: 'Order By Answer Rate',
  LONG_ANSWERS: 'Order By Long Answers',
  SHORT_ANSWERS: 'Order By Short Answers',
  DATE: 'Date Restricted',
  DESC: 'Reverse Order',
  DRAFTS: 'Has Open Drafts',
  ORDER_BY_REVIEW_STATUS: 'Order By Review Status',
  ONLY_NOT_REVIEWED: 'Only Not Reviewed',
}

export const groupOrderTypesList = [
  orderTypes.ANSWER_RATE,
  orderTypes.DESC,
  orderTypes.DATE,
  orderTypes.DRAFTS,
  orderTypes.ONLY_NOT_REVIEWED,
  orderTypes.ORDER_BY_REVIEW_STATUS,
  orderTypes.LONG_ANSWERS,
  orderTypes.SHORT_ANSWERS,
]
