import { name } from './constants'

export const ADD = `${name}/ADD`
export const ADD_ERROR = `${name}/ADD_ERROR`
export const ADD_SUCCESS = `${name}/ADD_SUCCESS`

export const ADD_GROUP = `${name}/ADD_GROUP`
export const ADD_GROUP_ERROR = `${name}/ADD_GROUP_ERROR`
export const ADD_GROUP_SUCCESS = `${name}/ADD_GROUP_SUCCESS`

export const ADD_LANGUAGE = `${name}/ADD_LANGUAGE`
export const ADD_LANGUAGE_ERROR = `${name}/ADD_LANGUAGE_ERROR`
export const ADD_LANGUAGE_SUCCESS = `${name}/ADD_LANGUAGE_SUCCESS`

export const REMOVE_LANGUAGE = `${name}/REMOVE_LANGUAGE`
export const REMOVE_LANGUAGE_ERROR = `${name}/REMOVE_LANGUAGE_ERROR`
export const REMOVE_LANGUAGE_SUCCESS = `${name}/REMOVE_LANGUAGE_SUCCESS`

export const CHANGE_APPROVAL = `${name}/CHANGE_APPROVAL`
export const CHANGE_APPROVAL_ERROR = `${name}/CHANGE_APPROVAL_ERROR`
export const CHANGE_APPROVAL_SUCCESS = `${name}/CHANGE_APPROVAL_SUCCESS`

export const EDIT = `${name}/EDIT`
export const EDIT_ERROR = `${name}/EDIT_ERROR`
export const EDIT_SUCCESS = `${name}/EDIT_SUCCESS`

export const EDIT_GROUP = `${name}/EDIT_GROUP`
export const EDIT_GROUP_ERROR = `${name}/EDIT_GROUP_ERROR`
export const EDIT_GROUP_SUCCESS = `${name}/EDIT_GROUP_SUCCESS`

export const GET_GROUPS_FOR_TAGS = `${name}/GET_GROUPS_FOR_TAGS`
export const GET_GROUPS_FOR_TAGS_ERROR = `${name}/GET_GROUPS_FOR_TAGS_ERROR`
export const GET_GROUPS_FOR_TAGS_SUCCESS = `${name}/GET_GROUPS_FOR_TAGS_SUCCESS`

export const GET_TAGS = `${name}/GET_TAGS`
export const GET_TAGS_ERROR = `${name}/GET_TAGS_ERROR`
export const GET_TAGS_SUCCESS = `${name}/GET_TAGS_SUCCESS`

export const FETCH = `${name}/FETCH`
export const FETCH_ERROR = `${name}/FETCH_ERROR`
export const FETCH_SUCCESS = `${name}/FETCH_SUCCESS`

export const FETCH_ANSWERS = `${name}/FETCH_ANSWERS`
export const FETCH_ANSWERS_ERROR = `${name}/FETCH_ANSWERS_ERROR`
export const FETCH_ANSWERS_SUCCESS = `${name}/FETCH_ANSWERS_SUCCESS`

export const FETCH_GROUPS = `${name}/FETCH_GROUPS`
export const FETCH_GROUPS_ERROR = `${name}/FETCH_GROUPS_ERROR`
export const FETCH_GROUPS_SUCCESS = `${name}/FETCH_GROUPS_SUCCESS`

export const FETCH_GROUP_SUGGESTIONS = `${name}/FETCH_GROUP_SUGGESTIONS`
export const FETCH_GROUP_SUGGESTIONS_ERROR = `${name}/FETCH_GROUP_SUGGESTIONS_ERROR`
export const FETCH_GROUP_SUGGESTIONS_SUCCESS = `${name}/FETCH_GROUP_SUGGESTIONS_SUCCESS`

export const FETCH_ART_TELL_GROUPS = `${name}/FETCH_ART_TELL_GROUPS`
export const FETCH_ART_TELL_GROUPS_ERROR = `${name}/FETCH_ART_TELL_GROUPS_ERROR`
export const FETCH_ART_TELL_GROUPS_SUCCESS = `${name}/FETCH_ART_TELL_GROUPS_SUCCESS`

export const REFRESH_ANSWERS = `${name}/REFRESH_ANSWERS`
export const REFRESH_ANSWERS_ERROR = `${name}/REFRESH_ANSWERS_ERROR`
export const REFRESH_ANSWERS_SUCCESS = `${name}/REFRESH_ANSWERS_SUCCESS`

export const REFRESH = `${name}/REFRESH`
export const REFRESH_ERROR = `${name}/REFRESH_ERROR`
export const REFRESH_SUCCESS = `${name}/REFRESH_SUCCESS`

export const REFRESH_GROUPS = `${name}/REFRESH_GROUPS`
export const REFRESH_GROUPS_ERROR = `${name}/REFRESH_GROUPS_ERROR`
export const REFRESH_GROUPS_SUCCESS = `${name}/REFRESH_GROUPS_SUCCESS`

export const REFRESH_GROUP_SUGGESTIONS = `${name}/REFRESH_GROUP_SUGGESTIONS`
export const REFRESH_GROUP_SUGGESTIONS_ERROR = `${name}/REFRESH_GROUP_SUGGESTIONS_ERROR`
export const REFRESH_GROUP_SUGGESTIONS_SUCCESS = `${name}/REFRESH_GROUP_SUGGESTIONS_SUCCESS`

export const REFRESH_ART_TELL_GROUPS = `${name}/REFRESH_ART_TELL_GROUPS`
export const REFRESH_ART_TELL_GROUPS_ERROR = `${name}/REFRESH_ART_TELL_GROUPS_ERROR`
export const REFRESH_ART_TELL_GROUPS_SUCCESS = `${name}/REFRESH_ART_TELL_GROUPS_SUCCESS`

/**
 * Artificial Tells
 */
export const REFRESH_ARTIFICIAL_TELLS = `${name}/REFRESH_ARTIFICIAL_TELLS`
export const REFRESH_ARTIFICIAL_TELLS_ERROR = `${name}/REFRESH_ARTIFICIAL_TELLS_ERROR`
export const REFRESH_ARTIFICIAL_TELLS_SUCCESS = `${name}/REFRESH_ARTIFICIAL_TELLS_SUCCESS`

export const FETCH_ARTIFICIAL_TELLS = `${name}/FETCH_ARTIFICIAL_TELLS`
export const FETCH_ARTIFICIAL_TELLS_ERROR = `${name}/FETCH_ARTIFICIAL_TELLS_ERROR`
export const FETCH_ARTIFICIAL_TELLS_SUCCESS = `${name}/FETCH_ARTIFICIAL_TELLS_SUCCESS`

export const SET_SEARCH_STRING_ART_TELLS = `${name}/SET_SEARCH_STRING_ART_TELLS`

export const REFRESH_SUGGESTIONS = `${name}/REFRESH_SUGGESTIONS`
export const REFRESH_SUGGESTIONS_ERROR = `${name}/REFRESH_SUGGESTIONS_ERROR`
export const REFRESH_SUGGESTIONS_SUCCESS = `${name}/REFRESH_SUGGESTIONS_SUCCESS`

export const REFRESH_TAGS = `${name}/REFRESH_TAGS`
export const REFRESH_TAGS_SUCCESS = `${name}/REFRESH_TAGS_SUCCESS`
export const REFRESH_TAGS_ERROR = `${name}/REFRESH_TAGS_ERROR`

export const DECLINE = `${name}/DECLINE`
export const DECLINE_ERROR = `${name}/DECLINE_ERROR`
export const DECLINE_SUCCESS = `${name}/DECLINE_SUCCESS`

export const DECLINE_GROUP = `${name}/DECLINE_GROUP`
export const DECLINE_GROUP_ERROR = `${name}/DECLINE_GROUP_ERROR`
export const DECLINE_GROUP_SUCCESS = `${name}/DECLINE_GROUP_SUCCESS`

export const REVIEW = `${name}/REVIEW`
export const REVIEW_SUCCESS = `${name}/REVIEW_SUCCESS`
export const REVIEW_ERROR = `${name}/REVIEW_ERROR`

export const SET_GROUPS_ORDER_TYPES = `${name}/SET_GROUPS_ORDER_TYPES`

export const SET_SEARCH_STRING = `${name}/SET_SEARCH_STRING`

export const SET_SEARCH_TAGS = `${name}/SET_SEARCH_TAGS`

export const SET_SELECTED_GROUP_ID = `${name}/SET_SELECTED_GROUP_ID`

export const SET_SELECTED_LANGUAGE = `${name}/SET_SELECTED_LANGUAGE`

export const SET_TABLE_STATE = `${name}/SET_TABLE_STATE`

export const SET_TAGS_ORDER_TYPES = `${name}/SET_TAGS_ORDER_TYPES`
