import { CheckCircleOutlined, CopyOutlined } from '@ant-design/icons'
import { copyToClipboard } from '@tellonym/core/share/actions'
import { Button, DatePicker, Input, Select, Typography } from 'antd'
import moment from 'moment'
import qs from 'qs'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { colors, View } from '../../common'
import { snakeToCamelCase } from '../../statistics/services'
import { dateTypes } from '../constants'

const { Title, Text } = Typography

const styles = {
  bottomMargin: { marginBottom: '2%' },
  buttonTest: { marginTop: '4%' },
  buttonTestSuccess: { color: colors.green, borderColor: colors.green },
  container: { padding: '2%' },
  datePicker: { marginLeft: '1%' },
  errorText: { flex: 1, display: 'flex', wordBreak: 'break-word' },
  halfpage: { width: '48%' },
  reportRowItem: { flex: 1, paddingRight: '2%' },
  row: { flexDirection: 'row' },
  textareaButtonContainer: { marginLeft: '1%', paddingRight: '2%' },
}

const Selector = ({ options, onChange, ...props }) => {
  if (options && options.length === 0) {
    return null
  }

  return (
    <Select
      defaultValue={options[0].value}
      onChange={(v) => onChange(v)}
      style={{ width: 240 }}
      options={options}
      {...props}
    />
  )
}

const dateTypeOptions = [
  dateTypes.LAST_30_DAYS,
  dateTypes.LAST_7_DAYS,
  dateTypes.LAST_90_DAYS,
  dateTypes.CUSTOM_DATE,
].map((dateType) => ({
  value: dateType,
  label: snakeToCamelCase(dateType),
}))

const MetaSection = ({ changeQuery }) => {
  const [selectedDateType, setSelectedDateType] = useState(
    dateTypeOptions[0].value
  )

  const getDisabledDate = (current) =>
    current && current > moment().endOf('day')

  const onChangeDates = (values) => {
    if (values && values.length > 1) {
      const [startDate, endDate] = values
      if (selectedDateType !== 'CUSTOM_DATE') {
        setSelectedDateType('CUSTOM_DATE')
      }
      changeQuery({
        dateFrom: startDate.format('DD.MM.YYYY'),
        dateTo: endDate.format('DD.MM.YYYY'),
      })
    }
  }

  useEffect(() => {
    if (selectedDateType !== 'CUSTOM_DATE') {
      const days = Number(selectedDateType.match(/_(\d*)/)[1])
      const from = moment().subtract(days, 'days')
      const to = moment().subtract(1, 'day')

      changeQuery({
        dateFrom: from.format('DD.MM.YYYY'),
        dateTo: to.format('DD.MM.YYYY'),
      })
    }
  }, [selectedDateType])

  return (
    <View style={styles.bottomMargin}>
      <Title level={4}>Date range</Title>
      <View style={styles.row}>
        <Selector
          options={dateTypeOptions}
          onChange={setSelectedDateType}
          value={selectedDateType}
        />
        <DatePicker.RangePicker
          defaultPickerValue={[
            moment().subtract(31, 'days'),
            moment().subtract(1, 'day'),
          ]}
          disabledDate={getDisabledDate}
          onChange={onChangeDates}
          style={styles.datePicker}
        />
      </View>
    </View>
  )
}

const fetchRubyEventNames = async () => {
  try {
    const response = await fetch('https://gam-api.tnym.de/ruby/eventNames')

    if (!response.ok) {
      return []
    }

    return response.json()
  } catch (e) {
    return []
  }
}

const ReportSection = ({ changeQuery }) => {
  const [eventName, setEventName] = useState('')
  const [eventNameOptions, setEventNameOptions] = useState([])
  const [isLoadingEventNames, setIsLoadingEventNames] = useState(false)

  useEffect(() => {
    setIsLoadingEventNames(true)
    fetchRubyEventNames().then((data) => {
      const options = data.map((eventName) => ({
        label: eventName,
        value: eventName,
      }))
      setEventNameOptions(options)
      setIsLoadingEventNames(false)
    })

    return () => {}
  }, [])

  const onChangeEventName = (value) => {
    setEventName(value)
    changeQuery({ eventName: value })
  }

  return (
    <View style={styles.bottomMargin}>
      <Title level={4}>Report</Title>

      <View style={[styles.reportRowItem, styles.bottomMargin]}>
        <Title level={5}>Event Name</Title>
        <Selector
          allowClear
          autoClearSearchValue
          showSearch
          placeholder="Event Name"
          loading={isLoadingEventNames}
          options={eventNameOptions}
          onChange={onChangeEventName}
          value={eventName}
          style={{ width: '100%' }}
        />
      </View>
    </View>
  )
}

const defaultStatement = {
  top: '',
  shift: '',
  days: '30',
  date: '',
  dateFrom: '',
  dateTo: '',
  dateSince: '',
  dimension: '',
  eventName: '',
  returnEventName: '',
  view: 'daily',
  aggregation: '',
  accum: '',
  accumTime: '',
  gmt: '',
  query: '',
}

const defaultQuery = {
  statement: qs.stringify(defaultStatement),
  type: 'RUBY',
}

const validateQuery = async (queryString) => {
  try {
    const query = JSON.parse(queryString)

    try {
      const url = 'https://gam-api.tnym.de/ruby/validate'
      const response = await fetch(`${url}?${qs.stringify({ query })}`)

      if (!response.ok) {
        throw new Error(
          `Request Failed: ${response.statusText}\n${await response.text()}`
        )
      }

      return undefined
    } catch (e) {
      return e.message
    }
  } catch (e) {
    return 'No valid JSON'
  }
}

export const RubyQueryTool = () => {
  const dispatch = useDispatch()
  const [query, setQuery] = useState(defaultQuery)
  const [queryString, setQueryString] = useState(JSON.stringify(defaultQuery))
  const [isRunningTest, setIsRunningTest] = useState(false)
  const [errorString, setErrorString] = useState('')
  const [hasValidationSucceeded, setHasValidationSucceeded] = useState(false)

  const onChangeQuery = useCallback(({ target: { value } }) => {
    setQueryString(value)
  }, [])

  const copyQuery = useCallback(() => {
    dispatch(copyToClipboard(queryString))
  }, [dispatch, queryString])

  const changeQuery = useCallback(
    (object) => {
      const parsedStatement = qs.parse(query.statement)
      const statement = { ...parsedStatement, ...object }
      const stringifiedStatement = qs.stringify(statement)

      const updatedQuery = { ...defaultQuery, statement: stringifiedStatement }

      setQuery(updatedQuery)
      setQueryString(JSON.stringify(updatedQuery))
    },
    [query]
  )

  const onPressTest = async () => {
    setIsRunningTest(true)
    setHasValidationSucceeded(false)
    setErrorString('')

    const error = await validateQuery(queryString)

    setIsRunningTest(false)

    if (error) {
      setErrorString(error)
    } else {
      setHasValidationSucceeded(true)
    }
  }

  return (
    <View style={styles.container}>
      <MetaSection changeQuery={changeQuery} />
      <ReportSection changeQuery={changeQuery} />

      <Title level={4}>Query</Title>
      <View style={styles.row}>
        <Input.TextArea
          value={queryString}
          onChange={onChangeQuery}
          autoSize={{ minRows: 3 }}
          style={styles.halfpage}
        />
        <View style={styles.textareaButtonContainer}>
          <Button type="primary" onClick={copyQuery} icon={<CopyOutlined />}>
            Copy
          </Button>
          <Button
            disabled={isRunningTest}
            icon={hasValidationSucceeded ? <CheckCircleOutlined /> : undefined}
            loading={isRunningTest}
            onClick={onPressTest}
            style={{
              ...styles.buttonTest,
              ...(hasValidationSucceeded ? styles.buttonTestSuccess : {}),
            }}>
            Test
          </Button>
        </View>
        <Text type="danger" style={styles.errorText}>
          {errorString}
        </Text>
      </View>
    </View>
  )
}
