import { ARTIFICIAL_TELL_GROUP_LANG_STATUS } from '@tellonym/enums/lib/Tell'
import React, { useCallback, useMemo } from 'react'
import {
  Avatar,
  TableEndlessScroll,
  Text,
  View,
  history,
  moment,
  styleSheets,
} from '../../common'
import { Username } from '../../common/components/Username'
import { valueToPercentage } from '../../statistics/services'
import { TextLabel } from './GroupItem'
import { TierRectangle } from './TierRectangle'

const RESTRICTION_TYPE = {
  NONE: 0,
  DURATION: 1,
  START: 2,
  END: 3,
}

const getRestrictionType = (group) => {
  const hasStart = Boolean(group.notBefore)
  const hasEnd = Boolean(group.notAfter)
  const hasBoth = hasStart && hasEnd

  if (hasBoth) {
    return RESTRICTION_TYPE.DURATION
  }

  if (hasStart) {
    return RESTRICTION_TYPE.START
  }

  if (hasEnd) {
    return RESTRICTION_TYPE.END
  }

  return RESTRICTION_TYPE.NONE
}

const getUser = (group) =>
  (group.editedBy?.username ? group.editedBy : group.reviewedBy) ?? {}

const getLastEditedAt = (group) => {
  const user = getUser(group)
  return user.lastEditedTime ?? user.lastReviewedTime ?? group.lastReviewDate
}

export const ArtificalTellTable = (props) => {
  const columns = useMemo(
    () =>
      props.columns ?? [
        {
          title: '',
          dataIndex: 'id',
          key: 'id',
          width: '6%',
          render: (id, group, index) => (
            <View style={styleSheets.center}>{index + 1}</View>
          ),
          filterIcon: () => null,
          filterDropdown: () => null,
          onFilter: (value, group) => {
            if (value === 'none') {
              return group.tags.length === 0
            }

            return group.tags.includes(value)
          },
        },
        {
          title: 'Tier',
          dataIndex: 'tier',
          key: 'tier',
          width: '8%',
          render: (tier, group) => (
            <View style={styleSheets.center}>
              <TierRectangle size={20} group={group} />
            </View>
          ),
          filters: [
            {
              text: '1',
              value: 1,
            },
            {
              text: '2',
              value: 2,
            },
            {
              text: '3',
              value: 3,
            },
            {
              text: '4',
              value: 4,
            },
          ],
          onFilter: (value, group) => {
            return group.tier === value
          },
          sorter: (a, b) => a.tier - b.tier,
        },
        {
          title: 'Name',
          dataIndex: 'id',
          key: 'name',
          render: (id, group) => group.title ?? group.name,
          filterIcon: () => null,
          filterDropdown: () => null,
          onFilter: (value, group) => {
            if (!value) {
              return true
            }

            return new RegExp(value, 'i').test(group.title ?? group.name)
          },
          sorter: (a, b) => {
            const textA = a.title ?? a.name
            const textB = b.title ?? b.name

            if (textA < textB) {
              return -1
            }

            if (textA > textB) {
              return 1
            }

            return 0
          },
        },
        {
          title: 'AR',
          dataIndex: 'answerRate',
          key: 'answerRate',
          width: '5%',
          sorter: (a, b) => a.answerRate - b.answerRate,
          render: (answerRate) => (
            <View style={styleSheets.center}>
              {valueToPercentage(answerRate, 1)}
            </View>
          ),
        },
        {
          title: 'L',
          dataIndex: 'answerPctLong',
          key: 'answerPctLong',
          width: '5%',
          sorter: (a, b) => a.answerPctLong - b.answerPctLong,
          render: (answerRate) => (
            <View style={styleSheets.center}>
              {valueToPercentage(answerRate, 1)}
            </View>
          ),
        },
        {
          title: 'Last Edited',
          dataIndex: 'id',
          key: 'editedBy',
          width: '10%',
          render: (id, group) => {
            const user = getUser(group)
            const lastEditedAt = getLastEditedAt(group)
            const date = lastEditedAt
              ? moment(lastEditedAt).format('DD.MM. HH:mm')
              : ''

            return (
              <View>
                <View style={styleSheets.flex.direction.row}>
                  <Avatar
                    size={20}
                    user={user}
                    style={styleSheets.margin.right[4]}
                  />
                  <Username
                    bold={false}
                    hasVerifiedIcon={false}
                    user={user}
                    onPress={null}
                    ellipsisWidth
                    style={{ textOverflow: 'clip', cursor: undefined }}
                  />
                </View>
                <View style={{ paddingLeft: 10 }}>
                  <Text type="micro">{date}</Text>
                </View>
              </View>
            )
          },
          sorter: (a, b) => {
            const timeA = getLastEditedAt(a)
            const timeB = getLastEditedAt(b)

            if (timeA && timeB) {
              return new Date(timeA).getTime() - new Date(timeB).getTime()
            }

            if (!timeA && !timeB) {
              return 0
            }

            if (timeA) {
              return 1
            }

            return -1
          },
          defaultSortOrder: 'descend',
        },
        {
          title: 'Time Restrictions',
          dataIndex: 'id',
          key: 'timeRestrictions',
          width: '12%',
          filterMultiple: false,
          filters: [
            {
              text: 'Only Restricted',
              value: 1,
            },
            {
              text: 'Only Start Dates',
              value: 2,
            },
            {
              text: 'Only End Dates',
              value: 3,
            },
            {
              text: 'No Restrictions',
              value: 4,
            },
          ],
          onFilter: (value, group) => {
            switch (value) {
              case 1:
                return group.notBefore || group.notAfter
              case 2:
                return group.notBefore && !group.notAfter
              case 3:
                return !group.notBefore && group.notAfter
              case 4:
                return !group.notBefore && !group.notAfter
              default:
                return true
            }
          },
          sorter: (a, b) => getRestrictionType(a) - getRestrictionType(b),
          render: (id, group) => {
            const type = getRestrictionType(group)

            const dateStart = group.notBefore
              ? moment(group.notBefore).format('DD.MM.')
              : ''
            const dateEnd = group.notAfter
              ? moment(group.notAfter).format('DD.MM.')
              : ''

            switch (type) {
              case RESTRICTION_TYPE.DURATION:
                return `${dateStart} - ${dateEnd}`
              case RESTRICTION_TYPE.START:
                return `from ${dateStart}`
              case RESTRICTION_TYPE.END:
                return `till ${dateEnd}`
              case RESTRICTION_TYPE.NONE:
              default:
                return ''
            }
          },
        },
        {
          title: 'Drafts',
          dataIndex: 'amountDrafts',
          key: 'amountDrafts',
          width: '7%',
          filters: [
            {
              text: 'Only Drafts',
              value: 1,
            },
            {
              text: 'Only No Drafts',
              value: 0,
            },
          ],
          filterMultiple: false,
          defaultFilteredValue: undefined,
          onFilter: (value, group) => {
            switch (value) {
              case 0:
                return group.amountDrafts === 0
              case 1:
                return group.amountDrafts > 0
              default:
                return true
            }
          },
          sorter: (a, b) =>
            parseInt(a.amountDrafts, 10) - parseInt(b.amountDrafts, 10),
          render: (amountDrafts) => (
            <View style={styleSheets.center}>{amountDrafts}</View>
          ),
        },
        {
          title: 'Status',
          dataIndex: 'id',
          key: 'status',
          width: '12%',
          render: (id, group) => (
            <TextLabel
              green={group.status === ARTIFICIAL_TELL_GROUP_LANG_STATUS.ACTIVE}
              yellow={group.status === ARTIFICIAL_TELL_GROUP_LANG_STATUS.DRAFT}
              red={group.status === ARTIFICIAL_TELL_GROUP_LANG_STATUS.DECLINED}>
              {
                {
                  [ARTIFICIAL_TELL_GROUP_LANG_STATUS.ACTIVE]: 'ACTIVE',
                  [ARTIFICIAL_TELL_GROUP_LANG_STATUS.DRAFT]: 'DRAFT',
                  [ARTIFICIAL_TELL_GROUP_LANG_STATUS.DECLINED]: 'DECLINED',
                }[group.status]
              }
            </TextLabel>
          ),
          filters: [
            {
              text: 'Active & Draft',
              value: 0,
            },
            {
              text: 'Active',
              value: 1,
            },
            {
              text: 'Draft',
              value: 2,
            },
            {
              text: 'Declined',
              value: 3,
            },
            {
              text: 'All',
              value: -1,
            },
          ],
          filterMultiple: false,
          defaultFilteredValue: ['0'],
          onFilter: (value, group) => {
            switch (value) {
              case 0:
                return (
                  group.status !== ARTIFICIAL_TELL_GROUP_LANG_STATUS.DECLINED
                )
              case 1:
                return group.status === ARTIFICIAL_TELL_GROUP_LANG_STATUS.ACTIVE
              case 2:
                return group.status === ARTIFICIAL_TELL_GROUP_LANG_STATUS.DRAFT
              case 3:
                return (
                  group.status === ARTIFICIAL_TELL_GROUP_LANG_STATUS.DECLINED
                )
              default:
                return true
            }
          },
        },
        {
          title: 'Reviewed',
          dataIndex: 'id',
          key: 'reviewStatus',
          width: '12%',
          render: (id, group) =>
            group.isReviewed ? (
              <TextLabel green>Reviewed</TextLabel>
            ) : (
              <TextLabel yellow>Not Reviewed</TextLabel>
            ),
          filters: [
            {
              text: 'Reviewed',
              value: 1,
            },
            {
              text: 'Not Reviewed',
              value: 0,
            },
            {
              text: 'All',
              value: -1,
            },
          ],
          filterMultiple: false,
          defaultFilteredValue: ['-1'],
          onFilter: (value, group) => {
            switch (value) {
              case 0:
                return !group.isReviewed
              case 1:
                return group.isReviewed
              default:
                return true
            }
          },
        },
      ],
    [props.columns]
  )

  const onRow = useCallback(
    (group) => ({
      onClick: (e) => {
        const isActionKeyPressed = e.metaKey || e.ctrlKey
        const route = `/artificialtells/${props.language ?? 'en'}/group/${
          group.id
        }`

        if (isActionKeyPressed) {
          window.open(route, '_blank')
        } else {
          history.push({
            pathname: route,
            search: history.location.search,
          })
        }
      },
    }),
    [props.language, history.location.search]
  )

  return <TableEndlessScroll {...props} columns={columns} onRow={onRow} />
}
