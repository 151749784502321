import { langDetectObjectsByType1 } from '@tellonym/enums/lib/Language'
import { Radio } from 'antd'
import { equals } from 'ramda'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { getPermissions } from '../../app/selectors'
import { helpers } from '../../common'
import { setSelectedLanguage } from '../actions'
import { supportedLanguages } from '../constants'
import { getSelectedLanguage } from '../selectors'

const _SelectorsLanguage = ({
  actions,
  language,
  languages = supportedLanguages,
  permissions,
  ...props
}) => {
  const currentIndex = languages.findIndex(equals(language))
  const supportedLanguagesWithPermission = languages.filter(
    (lang) =>
      lang === 'en' ||
      helpers.checkPermission(
        `artificialtells.edit.lang.${langDetectObjectsByType1[lang]}`,
        permissions
      )
  )

  return (
    <Radio.Group
      onChange={(e) => {
        actions.setSelectedLanguage(e.target.value)
      }}
      defaultValue={supportedLanguages[currentIndex]}
      buttonStyle="solid"
      size="large"
      {...props}>
      {supportedLanguagesWithPermission.map((lang) => (
        <Radio.Button key={lang} value={lang}>
          {lang}
        </Radio.Button>
      ))}
    </Radio.Group>
  )
}

const mapStateToProps = createStructuredSelector({
  language: getSelectedLanguage,
  permissions: getPermissions,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setSelectedLanguage }, dispatch),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const SelectorsLanguage = withConnect(_SelectorsLanguage)
