import { langDetectObjectsByType1 } from '@tellonym/enums/lib/Language'
import { history } from '../common/history'
import { shortNamesCombinations } from '../statistics/statisticsRouteMap'

export const sidemenu = {
  ids: [
    'index',
    'dashboards',
    'moderator',
    'management',
    'filter',
    'stats',
    'tools',
  ],
  data: {
    index: {
      routes: ['/'],
    },
    dashboards: {
      name: 'Dashboards',
      routes: ['/dashboards/recentanswers'],
    },
    management: {
      name: 'management',
      routes: ['/artificialtells', '/artificialtells/search'],
      permissions: 'artificialtells',
    },
    moderator: {
      name: 'Moderator',
      routes: [
        '/search',
        '/moderation',
        '/accountverification',
        '/nsfwpictures',
        '/reports',
        '/ban/candidates',
        '/ban/devices',
        '/blacklist',
      ],
      permissions: 'moderation',
    },
    filter: {
      name: 'filter',
      routes: ['/lastblocked', '/spam'],
      permissions: 'blockings',
    },
    stats: {
      name: 'stats',
      routes: [
        '/stats/ch/diff',
        '/stats/ch/compare',
        '/stats/diff',
        '/stats/experiment',
        '/stats/chevents',
        '/stats/csv',
        '/stats/upload',
      ],
      permissions: 'stats',
    },
    tools: {
      name: 'tools',
      routes: [
        '/tools/analyticse2e',
        '/tools/analyticsinfo',
        '/tools/badwordscore',
        '/tools/experiments',
        '/tools/notionhelpers',
        '/tools/profilevisualiser',
        '/tools/querybuilder',
        '/tools/generatetiktokvideo',
      ],
      permissions: 'menu.tools',
    },
  },
}

export const routes = {
  ids: ['/'],

  data: {
    '/': {
      name: 'Tellonym Modcp',
    },
    '/dashboards/recentanswers': {
      name: 'Recent Answers',
    },
    '/artificialtells': {
      name: 'Art. Tells Overview',
      getPath: () => {
        const { pathname } = window.location
        if (pathname.indexOf('/artificialtells') >= 0) {
          const [, , _language] = history.location.pathname.split('/')
          const langEnum = langDetectObjectsByType1[_language]
          const language = langEnum > 0 ? _language : 'en'

          return `/artificialtells/${language}/list${history.location.search}`
        }

        return '/artificialtells'
      },
    },
    '/artificialtells/search': {
      name: 'Art. Search',
    },
    '/artificialtells/tags': {
      name: 'Art. Tags',
    },
    '/search': {
      name: 'Search',
    },
    '/reports': {
      name: 'Reports',
    },
    '/ban/candidates': {
      name: 'Ban',
    },
    '/ban/devices': {
      name: 'Banned Devices',
    },
    '/blacklist': {
      name: 'Blacklist',
    },
    '/lastblocked': {
      name: 'Last Blocked',
    },
    '/spam': {
      name: 'Spam Detection',
    },
    '/spam/:spamItemId': {
      name: 'Spam Detection Item',
    },
    '/stats/ch/diff': {
      name: 'CH Changes',
    },
    '/stats/ch/compare': {
      name: 'CH Compare',
    },
    '/stats/diff': {
      name: 'Changes',
      options: shortNamesCombinations,
    },
    '/stats/experiment': {
      name: 'Experiment',
    },
    '/stats/chevents': {
      name: 'CH Events',
    },
    '/stats/csv': {
      name: 'CSV Download',
    },
    '/stats/upload': {
      name: 'Upload',
    },
    '/moderation': {
      name: 'Moderation',
    },
    '/accountverification': {
      name: 'Account Verification',
    },
    '/nsfwpictures': {
      name: 'NSFW Pictures',
    },
    '/tools/analyticse2e': {
      name: 'Analytics E2E',
    },
    '/tools/analyticsinfo': {
      name: 'Analytics Info',
    },
    '/tools/badwordscore': {
      name: 'Badwordscore',
    },
    '/tools/experiments': {
      name: 'Experiments',
    },
    '/tools/notionhelpers': {
      name: 'Notion Helpers',
    },
    '/tools/profilevisualiser': {
      name: 'Profile Visualiser',
    },
    '/tools/querybuilder': {
      name: 'Query Builder',
    },
    '/tools/generatetiktokvideo': {
      name: 'TikTok Video Generator',
    },
  },
}
