import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ContentContainer, Text, theme, View } from '../../common'
import { PageDashboard as selector } from '../selectors'

class IndexComponent extends React.Component {
  render() {
    return (
      <ContentContainer>
        <Text type="h1" center style={{ marginTop: 24, marginBottom: 24 }}>
          Dashboard
        </Text>

        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
          }}>
          <View
            style={{
              backgroundColor: theme.colors.white,
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              padding: 24,
              marginBottom: 24,
            }}>
            <Text
              type="h4"
              color={theme.colors.placeholder}
              style={{ marginBottom: 24 }}>
              Hope you have a nice day!
            </Text>
          </View>
        </View>
      </ContentContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  ...selector(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
})

export const Index = connect(
  mapStateToProps,
  mapDispatchToProps
)(IndexComponent)
