import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useRef, useState } from 'react'
import {
  hooks,
  Icon,
  Input,
  Text,
  theme,
  TouchableOpacity,
  View,
} from '../../common'
import { camelizeString } from '../helpers'

export const NewEntry = ({ isDisabled, isEditingGroup, onSubmit, style }) => {
  const [inputValue, setInputValue] = useState('')
  const [isEditing, setIsEditing] = useState(false)
  const inputRef = useRef(null)

  const onPressSave = () => {
    if (typeof onSubmit === 'function') {
      onSubmit({ inputValue })
    }
    setInputValue('')
  }

  const onBlur = () => {
    setIsEditing(false)
  }

  hooks.useKeyboardShortcutToSubmit({
    inputRef,
    onSubmit: onPressSave,
  })

  hooks.useEscapeKey({
    inputRef,
    onPress: () => {
      setIsEditing(false)
      setInputValue('')
    },
  })

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus()
    }
  }, [isEditing])

  return (
    <View
      style={{
        backgroundColor: theme.colors.antdBackgroundElevated,
        flexDirection: 'row',
        borderWidth: 0,
        borderBottomWidth: 1,
        borderColor: theme.colors.antdBackgroundElevated,
        borderStyle: 'solid',
        paddingVertical: isEditing ? 12 : 0,
        alignItems: 'center',
        onHover: {
          cursor: 'pointer',
        },
        ...style,
      }}>
      {isEditing ? (
        <React.Fragment>
          <View style={{ flex: 4 }}>
            <Input
              forwardRef={inputRef}
              onBlur={onBlur}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="New Entry..."
              value={inputValue}
              style={{
                marginBottom: 0,
                paddingLeft: 48,
                backgroundColor: theme.colors.antdBackgroundElevated,
              }}
            />
          </View>
          {isEditingGroup && (
            <Text bold ellipsisWidth={360}>
              {camelizeString(inputValue)}
            </Text>
          )}
          <View
            style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
          />
          <View style={{ flex: 2 }}>
            <Text onMouseDown={onPressSave} center color={theme.colors.primary}>
              Save
            </Text>
          </View>
        </React.Fragment>
      ) : (
        <TouchableOpacity
          onPress={() => !isDisabled && setIsEditing(true)}
          style={{
            flex: 1,
            flexDirection: 'row',
            paddingHorizontal: 24,
            paddingVertical: 12,
          }}>
          <Icon
            icon={faPlusCircle}
            color={isDisabled ? theme.colors.grey : theme.colors.primary}
            size="lg"
          />
          <Text style={{ marginLeft: 12, marginTop: 3 }}>Add Entry</Text>
        </TouchableOpacity>
      )}
    </View>
  )
}
