import { Client } from '@notionhq/client'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { HOST_TYPE, buildFetch, resolveOrReject } from '@tellonym/core/api'
import { getAccessToken } from '@tellonym/core/app/selectors'
import React from 'react'
import * as hooks from '../common/hooks'
import { store } from '../common/store'

const notionClient = new Client({
  baseUrl: 'https://notion-api.callosum.workers.dev',
})

const analyticsEvents = {
  all: ['analyticsEvents'],
  list: (filters) => ['list', filters],
}

const analyticsEventNames = {
  all: ['analyticsEventNames'],
  props: (tableName) => ['analyticsEventNames', 'props', tableName],
}

const tiktokVideoBackgrounds = {
  all: ['tiktokVideoBackgrounds'],
}

const notion = {
  all: ['notion'],
  filters: (filters) => [notion.all, filters],
}

const fetchAnalyticsEvents = async ({ queryKey }) => {
  const { userId, time, env, events } = queryKey[1]

  const accessToken = getAccessToken(store.getState())

  const [url, fetchConfig] = buildFetch(
    {
      path: 'admin/stats/clickhouse/analyticsevents',
      method: 'POST',
      accessToken,
      payload: { userId, time, env, events },
    },
    { accessToken }
  )

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useAnalyticsEventsQuery = ({ env, userId, time, events }) => {
  const userIdDebounced = hooks.useDebounceValue(userId, 500)

  const result = useQuery(
    analyticsEvents.list({
      env,
      userId: userIdDebounced === '' ? undefined : Number(userIdDebounced),
      time,
      events,
    }),
    fetchAnalyticsEvents,
    {
      enabled: time?.length > 0 && userIdDebounced?.length > 0,
      initialData: { ids: [], data: {} },
    }
  )

  return result
}

const fetchAnalyticsEventNames = async () => {
  const accessToken = getAccessToken(store.getState())

  const [url, fetchConfig] = buildFetch(
    { path: 'admin/stats/clickhouse/analyticseventnames' },
    { accessToken }
  )

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useAnalyticsEventNamesQuery = () => {
  const result = useQuery(analyticsEventNames.all, fetchAnalyticsEventNames, {
    refetchOnWindowFocus: false,
  })

  const data =
    result?.data?.eventNames?.map?.((v) => ({ label: v, value: v })) ?? []

  return { ...result, data }
}

const fetchAnalyticsEventProps = async ({ queryKey }) => {
  const tableName = queryKey[2]

  const accessToken = getAccessToken(store.getState())

  const [url, fetchConfig] = buildFetch(
    {
      method: 'POST',
      path: 'admin/stats/clickhouse/analyticseventprops',
      payload: { tableName },
    },
    { accessToken }
  )

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useAnalyticsEventPropsQuery = ({ tableName, onSuccess }) => {
  const result = useQuery({
    queryKey: analyticsEventNames.props(tableName),
    queryFn: fetchAnalyticsEventProps,
    onSuccess,
    enabled: typeof tableName !== 'undefined',
    refetchOnWindowFocus: false,
  })

  return result
}

const fetchTikTokVideoBackgrounds = async () => {
  const [url, fetchConfig] = buildFetch(
    {
      path: 'backgrounds',
    },
    {
      hostType: HOST_TYPE.VIDEOGEN,
    }
  )

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useTiktokVideoBackgroundQuery = () => {
  const result = useQuery(
    tiktokVideoBackgrounds.all,
    fetchTikTokVideoBackgrounds,
    { refetchOnWindowFocus: false, cacheTime: 10 * 60 * 1000 }
  )

  return result
}

const getNotionDatabaseData = async ({ queryKey, pageParam }) => {
  try {
    const response = await notionClient.databases.query({
      ...queryKey[1],
      start_cursor: pageParam,
    })

    return response
  } catch (e) {
    console.log(e)
    return Promise.reject(e)
  }
}

/**
 * Infinite query returns pages in chunks, this function merges them into one array
 */
const mergePages = (data) => {
  if (!data?.pages) {
    return []
  }

  return data.pages.reduce((acc, curr) => [...acc, ...curr.results], [])
}

/**
 * Notion database query hook, can only fetch db data that has the tellonym-helper as a connection. (Add via notion UI)
 * Might do multiple fetches due to notion pagination but merges all pages into one array automatically.
 * @param {{database_id: string}} queryConfig - requires database_id, other params optional like filter and sorts
 */
export const useNotionDbQuery = (queryConfig) => {
  const result = useInfiniteQuery(
    notion.filters(queryConfig),
    getNotionDatabaseData,
    {
      getNextPageParam: (lastPage) =>
        lastPage.next_cursor === null ? undefined : lastPage.next_cursor,
      refetchOnWindowFocus: false,
      cacheTime: 10 * 60 * 1000,
      enabled:
        typeof queryConfig?.database_id === 'string' &&
        queryConfig?.database_id !== '',
    }
  )

  React.useEffect(() => {
    if (result.hasNextPage && !result.isFetchingNextPage) {
      result.fetchNextPage()
    }
  }, [result])

  const data = mergePages(result.data)

  return { ...result, data }
}

const getNotionPageData = async ({ queryKey }) => {
  try {
    if (Array.isArray(queryKey[1])) {
      const requests = queryKey[1].map((query) =>
        notionClient.pages.retrieve(query)
      )

      return Promise.all(requests)
    }

    const response = await notionClient.pages.retrieve(queryKey[1])

    return response
  } catch (e) {
    console.log(e)
    return Promise.reject(e)
  }
}

const hasPageConfig = (queryConfig) => {
  if (Array.isArray(queryConfig)) {
    return (
      queryConfig.length > 0 &&
      queryConfig.every(
        (query) => typeof query?.page_id === 'string' && query?.page_id !== ''
      )
    )
  }

  return typeof queryConfig?.page_id === 'string' && queryConfig?.page_id !== ''
}

/**
 * Used to get information for a specific page. Use an array of queryConfigs to get multiple pages at once.
 *
 * @param {*} queryConfig - page_id required, can be an array of queryConfigs
 */
export const useNotionPageQuery = (queryConfig) => {
  const result = useQuery(notion.filters(queryConfig), getNotionPageData, {
    refetchOnWindowFocus: false,
    cacheTime: 10 * 60 * 1000,
    enabled: hasPageConfig(queryConfig),
  })

  return result
}
