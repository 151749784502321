import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { getIsLoggedIn } from '@tellonym/core/app/selectors'
import { logout } from '@tellonym/core/profile/actions'
import qs from 'qs'
import React, { useState } from 'react'
import ScrollView from 'react-native-web/dist/exports/ScrollView'
import { connect, useSelector } from 'react-redux'
import { useLocation, withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { getIsSidemenuShown, getPermissions } from '../../app/selectors'
import { Icon } from '../../common/components/Icon'
import { Link } from '../../common/components/Link'
import { Text } from '../../common/components/Text'
import { TouchableOpacity } from '../../common/components/TouchableOpacity'
import { View } from '../../common/components/View'
import * as helpers from '../../common/helpers'
import { theme } from '../../common/styles/theme'
import { routes, sidemenu } from '../routes'
import * as services from '../services'
import { Header } from './Header'

const getParamsString = (object, location) => {
  const params = qs.parse(location.search ? location.search.split('?')[1] : {})

  const mergedParams = {
    ...params,
    ...object,
  }

  const newParams = Object.keys(mergedParams).reduce(
    (acc, curr) =>
      mergedParams[curr] === '' ? acc : { ...acc, [curr]: mergedParams[curr] },
    {}
  )

  return qs.stringify(newParams)
}

const Options = ({ options }) => {
  const location = useLocation()

  return (
    <View>
      {options.map((option) => {
        const newParamsString = getParamsString(
          { shortNames: option.shortNames },
          location
        )
        const to = `${location.pathname}?${newParamsString}`

        return (
          <View
            style={{
              paddingVertical: 8,
              paddingLeft: 16,
              cursor: 'pointer',
              ':hover': {
                backgroundColor: theme.colors.darkerBackground,
                boxShadow: theme.styles.shadowInset,
              },
            }}>
            <Link key={option.name} to={to}>
              <Text>{option.name}</Text>
            </Link>
          </View>
        )
      })}
    </View>
  )
}

export const NavItem = ({
  children = undefined,
  getPath,
  isActive = false,
  options = [],
  text = '',
  to = '/',
  style = {},
  onPress,
}) => {
  const location = useLocation()
  const queryParams = location.search.split('?')[1]
  const hasOptions = options.length > 0

  const [isOptionsVisible, setIsOptionsVisible] = useState(false)

  return (
    <View>
      <View
        style={{
          paddingVertical: 12,
          paddingHorizontal: 24,
          backgroundColor: isActive ? theme.colors.darkerBackground : undefined,
          boxShadow: isActive ? theme.styles.shadowInset : undefined,
          flexDirection: hasOptions ? 'row' : 'column',
          justifyContent: 'space-between',
          alignItems: hasOptions ? 'center' : 'flex-start',
          ...style,
        }}>
        {children || (
          <Link
            to={
              getPath
                ? getPath()
                : `${to}${queryParams ? `?${queryParams}` : ''}`
            }
            onClick={onPress}
            style={{ marginLeft: 12 }}>
            <Text>{text}</Text>
          </Link>
        )}
        {hasOptions && (
          <View
            onPress={() => setIsOptionsVisible(!isOptionsVisible)}
            style={{
              cursor: 'pointer',
              paddingHorizontal: 12,
              paddingVertical: 4,
            }}>
            <Icon
              icon={isOptionsVisible ? faChevronUp : faChevronDown}
              color={theme.colors.placeholder}
            />
          </View>
        )}
      </View>
      {isOptionsVisible && <Options options={options} />}
    </View>
  )
}

const Section = ({ children, name }) => (
  <View style={{ marginBottom: 24 }}>
    {name && (
      <Text
        type="note"
        color={theme.colors.placeholder}
        style={{ marginLeft: 12, marginBottom: 8 }}>
        {name.toUpperCase()}
      </Text>
    )}
    {children}
  </View>
)

const Routes = ({ location }) => {
  const permissions = useSelector(getPermissions)

  return (
    <View>
      {sidemenu.ids.map((sectionId) => {
        const {
          permissions: requiredPermission,
          name,
          routes: sideMenuRoutes,
        } = sidemenu.data[sectionId]
        const hasPermission = requiredPermission
          ? helpers.checkPermission(requiredPermission, permissions)
          : true

        if (!hasPermission) {
          return null
        }

        return (
          <Section key={sectionId} name={name}>
            {sideMenuRoutes.map((id) => (
              <NavItem
                key={routes.data[id].name}
                isActive={services.isActive(location.pathname, id)}
                getPath={routes.data[id].getPath}
                options={routes.data[id].options}
                to={id}
                text={routes.data[id].name}
              />
            ))}
          </Section>
        )
      })}
    </View>
  )
}

export const Logout = ({ onPress, style }) => (
  <TouchableOpacity onPress={onPress}>
    <NavItem style={{ backgroundColor: theme.colors.niceRed, ...style }}>
      <Text center color={theme.colors.white}>
        Logout
      </Text>
    </NavItem>
  </TouchableOpacity>
)

class SideMenuComponent extends React.Component {
  static width = 200

  render() {
    const { actions, children, isLoggedIn, isSidemenuShown, location } =
      this.props

    return isLoggedIn ? (
      <View
        style={{
          flexDirection: 'row',
          flex: 1,
          height: '100%',
          paddingTop: Header.height,
        }}>
        {isSidemenuShown && (
          <View
            style={{
              position: 'fixed',
              boxShadow: theme.styles.shadow,
              top: Header.height,
              left: 0,
              bottom: 0,
              backgroundColor: theme.colors.background,
              zIndex: 98,
              width: SideMenuComponent.width,
              justifyContent: 'space-between',
            }}>
            <ScrollView
              contentContainerStyle={{ flexDirection: 'column', flexGrow: 1 }}
              style={{ flex: 1 }}>
              <Routes location={location} />
              <Logout onPress={actions.logout} />
            </ScrollView>
          </View>
        )}
        {isSidemenuShown && (
          <View style={{ minWidth: SideMenuComponent.width }} />
        )}
        {children}
      </View>
    ) : (
      children
    )
  }
}

const mapStateToProps = createStructuredSelector({
  isLoggedIn: getIsLoggedIn,
  isSidemenuShown: getIsSidemenuShown,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ logout }, dispatch),
})

export const SideMenu = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SideMenuComponent)
)
