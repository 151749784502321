import { ARTIFICIAL_TELL_GROUP_LANG_STATUS } from '@tellonym/enums/lib/Tell'
import { Dropdown, Menu, Typography } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import { colors, View } from '../../common'
import { editGroup } from '../actions'

const TIER = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
}

const COLOR = {
  [TIER[1]]: '#2D9CDB',
  [TIER[2]]: '#56CCF2',
  [TIER[3]]: '#F2C94C',
  [TIER[4]]: '#F2994A',
}

const Rectangle = ({ size, group }) => {
  const isGroupDeclined =
    group.status === ARTIFICIAL_TELL_GROUP_LANG_STATUS.DECLINED

  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        width: size,
        height: size,
        backgroundColor: isGroupDeclined
          ? colors.inactiveGrey
          : COLOR[group.tier] ?? 'red',
      }}>
      <Typography.Text
        style={{
          color: 'white',
          fontSize: size / 1.8,
          fontWeight: 'bold',
        }}>
        {group.tier ?? 0}
      </Typography.Text>
    </View>
  )
}

export const TierRectangle = ({ isEditable, group, size = 100, style }) => {
  const dispatch = useDispatch()

  if (isEditable) {
    return (
      <View style={style}>
        <Dropdown
          overlay={
            <Menu>
              {Object.values(TIER)
                .sort()
                .map((tier) => (
                  <Menu.Item key={tier}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => {
                        e.preventDefault()
                        dispatch(editGroup({ ...group, tier }))
                      }}>
                      {tier}
                    </a>
                  </Menu.Item>
                ))}
            </Menu>
          }>
          <a
            target="_blank"
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}>
            <Rectangle size={size} group={group} />
          </a>
        </Dropdown>
      </View>
    )
  }

  return (
    <View style={style}>
      <Rectangle size={size} group={group} />
    </View>
  )
}
