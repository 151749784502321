import * as events from '@tellonym/core/events/reducer'
import * as profile from '@tellonym/core/profile/reducer'
import { configureRootReducer } from '@tellonym/core/store/configureRootReducer'
import { getPersistOptions } from '@tellonym/core/store/persistence'
import * as user from '@tellonym/core/user/reducer'
import { config } from '../../config'
import * as app from '../app/reducer'
import * as artificialTells from '../artificialTells/reducer'
import * as dashboards from '../dashboards/reducer'
import * as filter from '../filter/reducer'
import * as index from '../index/reducer'
import * as moderator from '../moderator/reducer'
import * as statistics from '../statistics/reducer'
import * as tools from '../tools/reducer'
import { migrations } from './migrations'

const { version } = config.store

const modules = [
  app,
  artificialTells,
  dashboards,
  events,
  filter,
  index,
  moderator,
  profile,
  statistics,
  tools,
  user,
]

export const persistOptions = getPersistOptions(modules)

export const rootReducer = configureRootReducer({
  modules,
  migrations,
  version,
})
