import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons'
import { langDetectObjectsByType1 } from '@tellonym/enums/lib/Language'
import { ARTIFICIAL_TELL_VARIANCE_STATUS } from '@tellonym/enums/lib/Tell'
import { Button, Input, Popconfirm, Switch, Tag, Tooltip } from 'antd'
import moment from 'moment'
import { compose, pick } from 'ramda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { getPermissions } from '../../app/selectors'
import {
  helpers,
  history,
  Icon,
  styleSheets,
  Text,
  theme,
  View,
} from '../../common'
import { useTranslation } from '../../common/components/TextTranslatable'
import { changeApproval, decline, edit } from '../actions'
import { ArtificialTellStats } from './ArtificialTellStats'

const styles = {
  container: {
    overflow: 'hidden',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  artificialTellText: {
    cursor: 'text',
    flex: 1,
    marginBottom: 0,
    marginVertical: 6,
    paddingVertical: 4,
  },
  groupNameText: {
    fontStyle: 'italic',
    onHover: { color: theme.colors.primary },
    alignSelf: 'flex-start',
  },
  lineNumberText: { width: 72 },
  innerContainer: {
    flex: 4,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 24,
  },
  itemActionsContainer: {
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    paddingHorizontal: 24,
  },
  itemActions: { marginBottom: 4, width: 120 },
  itemActionsLast: { marginBottom: 4, width: 120 },
  waitingActionsIcon: { fontSize: 24, padding: 4 },
}

const timestampToElapsedTime = (date) => {
  if (typeof date !== 'object') {
    date = new Date(date)
  }

  const seconds = Math.floor((new Date() - date) / 1000)
  let intervalType = 's'
  let interval = Math.floor(seconds / 31536000)

  if (interval >= 1) {
    intervalType = 'y'
  } else {
    interval = Math.floor(seconds / 2592000)
    if (interval >= 1) {
      intervalType = 'm'
    } else {
      interval = Math.floor(seconds / 86400)
      if (interval >= 1) {
        intervalType = 'd'
      } else {
        interval = Math.floor(seconds / 3600)
        if (interval >= 1) {
          intervalType = 'h'
        } else {
          interval = Math.floor(seconds / 60)
          if (interval >= 1) {
            intervalType = 'min'
          } else {
            interval = seconds
            intervalType = 'sec'
          }
        }
      }
    }
  }

  return `${interval}${intervalType}`
}

const ArtificialTellItemActions = ({
  groupId,
  isEditing,
  setIsEditing,
  item,
  language,
  onPressSave,
  onPressItem,
  resetInputValue,
}) => {
  const isDeclined = item.type === ARTIFICIAL_TELL_VARIANCE_STATUS.DECLINED
  const isWaiting = item.type === ARTIFICIAL_TELL_VARIANCE_STATUS.DRAFT

  const dispatch = useDispatch()

  const onPressApproval = (type) => () => {
    dispatch(
      changeApproval({
        artificialTellIds: [item.id],
        type,
        groupId,
        language,
      })
    )
  }

  const onPressApprovalApprove = onPressApproval(
    ARTIFICIAL_TELL_VARIANCE_STATUS.ACTIVE
  )

  const onPressApprovalDecline = onPressApproval(
    ARTIFICIAL_TELL_VARIANCE_STATUS.DECLINED
  )

  const onPressDecline = () => {
    dispatch(
      decline({
        artificialTellIds: [item.id],
        groupId,
        language,
      })
    )
  }

  const EditButtons = () =>
    isEditing === false ? (
      <Button
        size="small"
        onClick={() => setIsEditing(true)}
        style={styles.itemActions}>
        Edit
      </Button>
    ) : (
      <View style={styleSheets.flex.direction.row}>
        <Button
          size="small"
          className="success"
          icon={<CheckOutlined />}
          onClick={() => {
            setIsEditing(false)
            onPressSave()
          }}
          style={{
            ...styles.itemActions,
            width: styles.itemActions.width / 2,
          }}
        />
        <Button
          size="small"
          danger
          icon={<CloseOutlined />}
          onClick={() => {
            setIsEditing(false)
            resetInputValue()
          }}
          style={{
            ...styles.itemActions,
            width: styles.itemActions.width / 2,
          }}
        />
      </View>
    )

  if (isWaiting) {
    return (
      <View style={styles.itemActionsContainer}>
        <EditButtons />
        <Button
          disabled={isEditing}
          className={isEditing ? 'success disabled' : 'success'}
          size="small"
          onClick={onPressApprovalApprove}
          style={styles.itemActions}>
          Approve
        </Button>
        <Button
          disabled={isEditing}
          danger
          size="small"
          onClick={onPressApprovalDecline}
          style={styles.itemActions}>
          Decline
        </Button>
      </View>
    )
  }

  return (
    <View style={styles.itemActionsContainer}>
      <EditButtons />
      <Button size="small" onClick={onPressItem} style={styles.itemActions}>
        Show answers
      </Button>
      {!isDeclined && (
        <Popconfirm
          placement="bottomLeft"
          title="Do you really want to decline this group?"
          onConfirm={onPressDecline}
          okText="Yes">
          <Button danger size="small" style={styles.itemActionsLast}>
            Decline
          </Button>
        </Popconfirm>
      )}
    </View>
  )
}

const ArtificialTellText = ({
  inputValue,
  setInputValue,
  isEditing,
  item,
  onPressSave,
  shouldTranslate,
}) => {
  const input = useTranslation(inputValue, !isEditing && shouldTranslate)
  const isDeclined = item.type === ARTIFICIAL_TELL_VARIANCE_STATUS.DECLINED

  return (
    <Input.TextArea
      disabled={isDeclined}
      autoSize
      bordered={false}
      showCount={false}
      onChange={(e) => isEditing && setInputValue(e.target.value)}
      onPressEnter={onPressSave}
      value={input}
    />
  )
}

const Badges = ({ item }) => {
  const isDeclined = item.type === ARTIFICIAL_TELL_VARIANCE_STATUS.DECLINED

  if (!isDeclined) {
    return null
  }

  return (
    <View style={[styleSheets.margin.left[12], styleSheets.alignItems.end]}>
      <Tag color="red">Declined</Tag>
    </View>
  )
}

const LastUpdated = ({ time }) => {
  if (!time) {
    return null
  }

  return (
    <View style={{ alignItems: 'flex-start' }}>
      <Tooltip
        title={moment(time).format('YYYY-MM-DD HH:mm')}
        placement="bottomLeft">
        <Text type="note" center color={theme.colors.placeholder}>
          {`updated\n${timestampToElapsedTime(time)}`}
        </Text>
      </Tooltip>
    </View>
  )
}

const _ArtificialTellItem = ({
  actions,
  groupId,
  groupStats,
  item,
  language,
  match,
  permissions,
  shouldShowGroupName,
  shouldTranslate: shouldTranslateAll,
}) => {
  const [shouldTranslate, setShouldTranslate] = useState(shouldTranslateAll)
  const [isLoadingTranslation, setIsLoadingTranslation] = useState(false)
  const tellContent = item.content || item.tellContent
  const { term } = match.params || {}
  const [inputValue, setInputValue] = useState(tellContent || '')
  const [isEditing, setIsEditing] = useState(false)

  const resetInputValue = useCallback(() => {
    setInputValue(tellContent)
  }, [])

  useEffect(() => {
    setShouldTranslate(shouldTranslateAll)
  }, [shouldTranslateAll])

  const statsItems = useMemo(
    () =>
      pick(
        ['answerRate', 'answerPctLong', 'answerPctShort', 'amountSent'],
        item
      ),
    [item]
  )

  const onChange = (isChecked) => {
    setShouldTranslate(isChecked)
  }

  const onLoadTranslation = (isLoading) => {
    setIsLoadingTranslation(isLoading)
  }

  const onPressGroup = (e) => {
    e.stopPropagation()
    history.push(`/artificialtells/${item.language}/group/${item.groupId}`)
  }

  const onPressItem = (e) => {
    const hasEditPermissions = helpers.checkPermission(
      `artificialtells.edit.lang.${langDetectObjectsByType1[language]}`,
      permissions
    )

    const isActionKeyPressed = e.metaKey || e.ctrlKey
    const route = `/artificialtells/${item.id}/answers`

    if (hasEditPermissions) {
      if (isActionKeyPressed) {
        window.open(route, '_blank')
      } else {
        history.push(route)
      }
    }
  }

  const onPressSave = (e) => {
    e?.stopPropagation?.()

    const isValueChanged = tellContent !== inputValue

    if (isValueChanged && inputValue !== '') {
      const payload = {
        artificialTellIds: [item.id],
        tellContent: inputValue,
        groupId,
        language,
        searchString: term,
        ...(item.type === ARTIFICIAL_TELL_VARIANCE_STATUS.DECLINED
          ? { type: ARTIFICIAL_TELL_VARIANCE_STATUS.DRAFT }
          : {}),
      }

      actions.edit(payload)
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <View style={{ flex: 1 }}>
          {shouldShowGroupName && (
            <Text
              type="small"
              color={theme.colors.placeholder}
              onPress={onPressGroup}
              style={styles.groupNameText}>
              {item.groupName}
            </Text>
          )}
          <ArtificialTellText
            inputValue={inputValue}
            setInputValue={setInputValue}
            isEditing={isEditing}
            item={item}
            onLoadChange={onLoadTranslation}
            onPressSave={onPressSave}
            shouldTranslate={shouldTranslate}
          />
        </View>
      </View>

      <Badges item={item} />

      <ArtificialTellItemActions
        onPressSave={onPressSave}
        onPressItem={onPressItem}
        groupId={groupId}
        language={language}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        item={item}
        resetInputValue={resetInputValue}
      />

      <ArtificialTellStats
        onPress={onPressItem}
        groupStats={groupStats}
        items={statsItems}
        type="micro"
      />

      <View style={{ alignItems: 'center', paddingHorizontal: 12 }}>
        <Switch
          checkedChildren={<Icon icon={faGlobeEurope} />}
          unCheckedChildren={<Icon icon={faGlobeEurope} />}
          checked={shouldTranslate}
          loading={isLoadingTranslation}
          onClick={onChange}
          style={styleSheets.margin.bottom[8]}
        />
        <LastUpdated time={item.lastUpdated} />
      </View>
    </View>
  )
}

const mapStateToProps = (state) => ({
  permissions: getPermissions(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ edit }, dispatch),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const enhancer = compose(withRouter, withConnect)

export const ArtificialTellItem = enhancer(_ArtificialTellItem)
