import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { FlatList, Text, theme, View } from '../../common'
import { fetchArtificialTells, refreshArtificialTells } from '../actions'
import {
  getDataArtTells,
  getHasMoreArtTells,
  getHasSearched,
  getIsFetchingArtTells,
  getIsRefreshingArtTells,
  getSearchStringArtTells,
} from '../selectors'
import { ArtificialTellItem } from './ArtificialTellItem'
import { SearchBar } from './SearchBar'

const EmptyComponent = ({ hasSearched, isFetching, isRefreshing }) =>
  hasSearched && !(isRefreshing || isFetching) ? (
    <Text
      center
      type="h3"
      color={theme.colors.placeholder}
      style={{ marginTop: 48 }}>
      Nothing found.
    </Text>
  ) : null

const SearchHeader = ({ hasSeparator, initialValue }) => (
  <View
    style={{
      borderStyle: 'solid',
      borderColor: theme.colors.borderLightGrey,
      borderWidth: 0,
      borderBottomWidth: hasSeparator ? 1 : 0,
    }}>
    <SearchBar
      initialValue={initialValue}
      shouldFocusOnMount
      _searchMethod="content"
      style={{
        maxWidth: 500,
        alignSelf: 'center',
        marginBottom: 48,
      }}
    />
  </View>
)

const _ArtificialTells = ({
  actions,
  hasMore,
  hasSearched,
  isRefreshing,
  isFetching,
  data,
  searchString,
}) => {
  const hasData = data.ids.length > 0

  return (
    <FlatList
      actions={{
        fetch: (p) => actions.fetch({ searchString, ...p }),
        refresh: (p) => actions.refresh({ searchString, ...p }),
      }}
      component={ArtificialTellItem}
      extraData={{ hasMore, isFetching, isRefreshing }}
      extraProps={{ shouldShowGroupName: true }}
      hasMore={hasMore}
      isFetching={isFetching}
      isRefreshing={isRefreshing}
      items={data}
      renderHeader={() => <SearchHeader hasSeparator={hasData} />}
      renderEmptyComponent={() => (
        <EmptyComponent
          hasSearched={hasSearched}
          isFetching={isFetching}
          isRefreshing={isRefreshing}
        />
      )}
    />
  )
}

const mapStateToProps = createStructuredSelector({
  hasMore: getHasMoreArtTells,
  hasSearched: getHasSearched,
  isRefreshing: getIsRefreshingArtTells,
  isFetching: getIsFetchingArtTells,
  data: getDataArtTells,
  searchString: getSearchStringArtTells,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { fetch: fetchArtificialTells, refresh: refreshArtificialTells },
    dispatch
  ),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const ArtificialTells = withConnect(_ArtificialTells)

export { ArtificialTells }
