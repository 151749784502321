import { blue } from '@ant-design/colors'
import { SelectOutlined } from '@ant-design/icons'
import { Line, TinyArea } from '@ant-design/plots'
import useChange from '@react-hook/change'
import { Modal, Radio, Table, Tag, Tooltip } from 'antd'
import { pick } from 'ramda'
import React, { useMemo, useRef, useState } from 'react'
import { Text, View, styleSheets, theme } from '../../common'
import { camelToSentenceCase } from '../services'
import { StatsItem } from './StatsItem'

const FIRST_COLUMN_WIDTH = 290
const COLUMN_WIDTH = 160

const mainValueKeys = ['percentageOfUsers', 'amountPerUnique', 'amountUniques']
const additionalValueKeys = ['amountActions', 'amountPerUser', 'amountUsers']

const rowValueKeys = mainValueKeys.concat(additionalValueKeys)

const styles = {
  iconOutlined: { paddingRight: 8 },
  rowKeyDescriptionContainer: { height: 22 },
  rowKeyDescription: { textAlign: 'right' },
  valueExplainer: { marginBottom: 12 },
}

const HeaderCell = ({ metric, submetric }) => (
  <View>
    <span style={styleSheets.fontSize[12]}>{submetric}</span>
    <span style={styleSheets.fontSize[14]}>{metric}</span>
  </View>
)

const RowKeyDescription = ({ children }) => (
  <View style={styles.rowKeyDescriptionContainer}>
    <Text
      type="note"
      color={theme.colors.placeholder}
      style={styles.rowKeyDescription}>
      {children}
    </Text>
  </View>
)

const buildChartData = (graphMetric, record) => {
  const metric = record.canBeExpanded ? graphMetric : 'value'

  return Object.keys(record).reduce(
    (acc, key) => {
      /**
       * We collect here values for legacy reasons. Some shortnames do not support amountPerUnique
       * yet. If this is the case we use the legacy prop 'value'.
       * We iterate over all values of an object so we make sure that they are part of the dataset.
       */
      if (
        typeof record[key]?.amountPerUnique === 'undefined' &&
        typeof record[key]?.value === 'undefined'
      ) {
        return acc
      }

      acc.dataset.push({ date: key, value: record[key][metric] })
      acc.onlyValues.push(record[key][metric])

      return acc
    },
    { onlyValues: [], dataset: [] }
  )
}

const buildChartDataExperiment = (graphMetric, record) => {
  const experimentIds = Object.keys(record).filter((key) =>
    Number.isInteger(Number(key))
  )

  const dataPerExperiment = experimentIds.map(
    (expId) =>
      record[expId].graphData.reduce(
        (acc, curr, index) => {
          /**
           * We collect here values for legacy reasons. Some shortnames do not support amountPerUnique
           * yet. If this is the case we use the legacy prop 'value'.
           */
          if (
            typeof curr?.amountPerUnique === 'undefined' &&
            typeof curr?.value === 'undefined'
          ) {
            return acc
          }

          acc.dataset.push({ date: index, value: curr[graphMetric], expId })
          acc.onlyValues.push(curr[graphMetric])

          return acc
        },
        { onlyValues: [], dataset: [] }
      ),
    []
  )

  return dataPerExperiment
}

const lineConfig = {
  padding: 'auto',
  xField: 'date',
  yField: 'value',
}

const ModalGraphContent = ({ record }) => {
  const [graphMetric, setGraphMetric] = useState(rowValueKeys[0])

  const dataset = useMemo(() => {
    if (record.isCompareExperiments) {
      const dataPerExperiment = buildChartDataExperiment(graphMetric, record)

      const dataset = dataPerExperiment.reduce((acc, { dataset }) => {
        acc = acc.concat(dataset)

        return acc
      }, [])

      return dataset
    }

    return buildChartData(graphMetric, record).dataset
  }, [record, graphMetric])

  const handleSizeChange = (e) => {
    setGraphMetric(e.target.value)
  }

  return (
    <>
      {record.canBeExpanded && (
        <Radio.Group
          onChange={handleSizeChange}
          size="medium"
          value={graphMetric}
          style={styleSheets.margin.bottom[24]}>
          {rowValueKeys.map((key) => (
            <Radio.Button key={key} value={key}>
              {camelToSentenceCase(key)}
            </Radio.Button>
          ))}
        </Radio.Group>
      )}
      <Line
        data={dataset}
        seriesField={record.isCompareExperiments ? 'expId' : undefined}
        {...lineConfig}
      />
    </>
  )
}

const chartConfig = {
  autoFit: false,
  smooth: true,
  height: 45,
  width: 240,
}

const arePropsEqualInlineChart = (prev, next) =>
  prev.record?.isExpanded === next.record?.isExpanded

const InlineChart = React.memo(({ record }) => {
  const ref = useRef(null)
  const [graphMetric, setGraphMetric] = useState(rowValueKeys[0])
  const [width, setWidth] = useState(chartConfig.width)

  const { onlyValues } = useMemo(() => {
    if (record.isCompareExperiments) {
      return buildChartDataExperiment(graphMetric, record)[1] ?? {}
    }

    return buildChartData(graphMetric, record)
  }, [record, graphMetric])

  const onChangeMetric = (value) => (e) => {
    e.stopPropagation()

    setGraphMetric(value)
  }

  useChange(record.isExpanded, (current) => {
    const { width, height } = current
      ? { width: 165, height: 70 }
      : pick(['width', 'height'], chartConfig)

    ref?.current?.getChart().changeSize(width, height)
    setWidth(width)
  })

  if (onlyValues === 0) {
    return (
      <Tag color="red" style={styleSheets.alignSelf.center}>
        No data available
      </Tag>
    )
  }

  return (
    <>
      <TinyArea ref={ref} data={onlyValues} {...chartConfig} />
      <View
        style={[
          styleSheets.flex.direction.row,
          { maxWidth: width, justifyContent: 'space-around' },
        ]}>
        {rowValueKeys.map((value, index) => (
          <Tooltip
            key={value}
            title={camelToSentenceCase(value)}
            placement="bottom">
            <Text
              type="note"
              disabled={graphMetric === value}
              onClick={onChangeMetric(value)}
              style={{
                color:
                  graphMetric === value
                    ? theme.colors.placeholder
                    : blue.primary,
                paddingLeft: 8,
                paddingRight: 8,
                onHover: { cursor: 'pointer' },
              }}>
              {index + 1}
            </Text>
          </Tooltip>
        ))}
      </View>
    </>
  )
}, arePropsEqualInlineChart)

const arePropsEqualFirstItem = (prev, next) => {
  const isTextEqual = prev.text === next.text
  const isExpandEqual = prev.record.isExpanded === next.record.isExpanded

  return isTextEqual && isExpandEqual
}

const FirstItem = React.memo(({ text, record }) => {
  const onClickOpenGraph = (e) => {
    e.stopPropagation()

    Modal.info({
      title: text,
      content: <ModalGraphContent record={record} />,
      width: 1000,
    })
  }

  return (
    <View style={styleSheets.flex.direction.row}>
      <View style={styleSheets.flex[1]}>
        <View
          style={[
            styleSheets.flex.direction.row,
            styleSheets.flex[1],
            styleSheets.justifyContent.spaceBetween,
          ]}>
          <Text type="note">{text}</Text>
          <SelectOutlined
            onClick={onClickOpenGraph}
            style={styles.iconOutlined}
          />
        </View>

        <View
          style={[
            styleSheets.flex[1],
            styleSheets.justifyContent.center,
            styleSheets.margin.top[8],
          ]}>
          <InlineChart record={record} />
        </View>
      </View>
      <View style={[styleSheets.justifyContent.center, styles.valueExplainer]}>
        {record.isExpanded
          ? rowValueKeys.map((key) => (
              <RowKeyDescription key={key}>{key}</RowKeyDescription>
            ))
          : record.canBeExpanded && (
              <>
                <RowKeyDescription>%Usr</RowKeyDescription>
                <RowKeyDescription>a pUq</RowKeyDescription>
                <RowKeyDescription>a Uq</RowKeyDescription>
              </>
            )}
      </View>
    </View>
  )
}, arePropsEqualFirstItem)

const renderFirstItem = (text, record) => (
  <FirstItem text={text} record={record} />
)

const arePropsEqualItem = (prev, next) =>
  prev.item?.value === next.item?.value &&
  prev.item?.amountUniques === next.item?.amountUniques &&
  prev.record.isExpanded === next.record.isExpanded

const Item = React.memo(({ item, record }) => {
  if (!item) {
    return null
  }

  if (mainValueKeys.every((key) => item[key] === 0)) {
    // This is for legacy support
    return (
      <StatsItem
        value={item.value}
        warning={item.warning}
        compareValue={item.compareValues?.value}
        isHigherBetter={record.isHigherBetter}
        isPercentage={record.isPercentage}
      />
    )
  }

  return (
    <View>
      <StatsItem
        isPercentage
        value={item.percentageOfUsers}
        compareValue={item.compareValues?.percentageOfUsers}
        isHigherBetter={record.isHigherBetter}
      />
      <StatsItem
        value={item.amountPerUnique}
        warning={item.warning}
        compareValue={item.compareValues?.amountPerUnique}
        isHigherBetter={record.isHigherBetter}
      />
      <StatsItem
        value={item.amountUniques}
        compareValue={item.compareValues?.amountUniques}
        isHigherBetter={record.isHigherBetter}
      />
      {record.isExpanded &&
        additionalValueKeys.map((key) => (
          <StatsItem
            key={key}
            value={item[key]}
            compareValue={item.compareValues?.[key]}
            isHigherBetter={record.isHigherBetter}
          />
        ))}
    </View>
  )
}, arePropsEqualItem)

const renderItem = (item, record) => <Item item={item} record={record} />

const defaultRowDisplayName = ({ name }) => name

export const StatsTable = ({
  getRowDisplayName = defaultRowDisplayName,
  headerData,
  items,
  isCompareExperiments,
  isLoading,
  yAxisDescription,
}) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([])

  const columns = useMemo(() => {
    const columns = [
      {
        title: <HeaderCell metric={yAxisDescription || 'short names'} />,
        dataIndex: 'rowName',
        key: 'rowName',
        fixed: 'left',
        render: renderFirstItem,
        onCell: () => ({
          style: {
            paddingLeft: 0,
            paddingRight: 8,
            paddingBottom: 4,
          },
        }),
        width: FIRST_COLUMN_WIDTH,
      },
    ]

    headerData.forEach((entry) => {
      columns.push({
        title: <HeaderCell metric={entry.metric} submetric={entry.submetric} />,
        subtitle: entry.submetric,
        dataIndex: entry.id,
        key: entry.id,
        render: renderItem,
        width: COLUMN_WIDTH,
        align: 'center',
      })
    })

    return columns
  }, [headerData])

  const data = useMemo(() => {
    const data = items.ids.map((rowId) => {
      const row = items.data[rowId]
      const isExpanded = expandedRowKeys.includes(rowId)

      const extraProps = pick(['isPercentage', 'isHigherBetter'], row)
      extraProps.key = rowId
      extraProps.isExpanded = isExpanded
      extraProps.rowName = getRowDisplayName(row)
      extraProps.canBeExpanded = false
      extraProps.isCompareExperiments = isCompareExperiments

      const entry = row.data.reduce((acc, item) => {
        acc[item.id] = pick([...rowValueKeys, 'compareValues', 'value'], item)

        acc[item.id].graphData = item.data

        if (
          !acc.canBeExpanded &&
          mainValueKeys.some((key) => acc[item.id][key] > 0)
        ) {
          acc.canBeExpanded = true
        }

        return acc
      }, extraProps)

      return entry
    })

    return data
  }, [expandedRowKeys.length, items.ids])

  const onExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, record.key])
    } else {
      const filteredKeys = expandedRowKeys.filter(
        (entry) => entry !== record.key
      )
      setExpandedRowKeys(filteredKeys)
    }
  }

  return (
    <Table
      columns={columns}
      dataSource={data}
      expandable={{
        expandedRowClassName: () => 'tnym-hidden',
        expandedRowRender: () => {},
        expandRowByClick: true,
        expandedRowKeys,
        onExpand,
        rowExpandable: (record) => record.canBeExpanded,
      }}
      loading={isLoading}
      scroll={{ x: 1 }}
      sticky={{ offsetHeader: 50 }}
      pagination={false}
    />
  )
}
