import { normalize } from '@tellonym/core/helpers'
import * as t from './types'

export const { name } = t

export const initialState = {
  recentAnswers: {
    _rerenderItem: { ids: [], count: 0 },
    hasMore: false,
    isFetching: false,
    isRefreshing: false,
    data: { ids: [], data: {} },
  },
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case t.REFRESH_RECENT_ANSWERS:
      return {
        ...state,
        recentAnswers: { ...state.recentAnswers, isRefreshing: true },
      }

    case t.REFRESH_RECENT_ANSWERS_SUCCESS: {
      const { data, hasMore } = payload.feed
      const normalized = normalize(data)

      return {
        ...state,
        recentAnswers: {
          ...state.recentAnswers,
          data: normalized,
          hasMore,
          isRefreshing: false,
        },
      }
    }

    case t.REFRESH_RECENT_ANSWERS_ERROR:
      return {
        ...state,
        recentAnswers: { ...state.recentAnswers, isRefreshing: false },
      }

    default:
      return state
  }
}
