import { debounce } from '@tellonym/core/helpers'
import equals from 'fast-deep-equal'
import { compose } from 'ramda'
import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import {
  Input as CustomInput,
  Text,
  theme,
  TouchableOpacity,
  View,
} from '../../common'
import { useEscapeKey } from '../../common/hooks'
import { getTags } from '../actions'
import { getSelectedLanguage, getTagArray } from '../selectors'

const styles = {
  container: { alignSelf: 'center', width: '100%' },
  tagBadgeContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: theme.colors.backgroundLight,
    borderRadius: 25,
    marginTop: 4,
    marginRight: 3,
    marginBottom: 4,
    marginLeft: 3,
    paddingBottom: 6,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 6,
    alignItems: 'center',
  },
  tagBadgeContainerSelected: {
    backgroundColor: theme.colors.background,
    boxShadow: theme.styles.shadow,
  },
  tagCloudContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
}

const TagBadge = ({ key, onPressTag, selectedTagIds, tag }) => {
  const isSelected = selectedTagIds.includes(tag.tag)

  return (
    <TouchableOpacity
      key={key}
      onPress={() => onPressTag([tag.tag])}
      style={[
        styles.tagBadgeContainer,
        isSelected && styles.tagBadgeContainerSelected,
      ]}>
      <Text bold={isSelected} type="small" style={{ userSelect: 'none' }}>
        {`${tag.tag} (${tag.count})`}
      </Text>
    </TouchableOpacity>
  )
}

const TagSwitch = ({ key, onPressTag, prefixId, selectedTagIds, tags }) => {
  const ids = tags.reduce(
    (acc, tag) => {
      acc.all.push(tag.tag)

      acc[tag.tag] = tag

      if (selectedTagIds.includes(tag.tag)) {
        acc.selected.push(tag.tag)
        acc[tag.tag].isSelected = true
      } else {
        acc.unselected.push(tag.tag)
        acc[tag.tag].isSelected = false

        acc.areAllSelected = false
      }

      return acc
    },
    {
      all: [],
      selected: [],
      unselected: [],
      areAllSelected: true,
      isCoreSelected: true,
    }
  )

  const onPress = (tagId) => {
    if (ids[tagId].isSelected) {
      if (ids.areAllSelected) {
        onPressTag(
          ids.selected.filter((id) => id !== tagId),
          tags
        )
      } else {
        onPressTag([tagId], tags)
      }
    } else {
      onPressTag(ids.selected.concat(tagId), tags)
    }
  }

  return (
    <View
      key={key}
      style={[
        styles.tagBadgeContainer,
        ids.selected.length > 0 && styles.tagBadgeContainerSelected,
      ]}>
      <Text
        type="small"
        onPress={() => {
          if (ids.areAllSelected) {
            onPressTag(ids.all, tags)
          } else {
            onPressTag(ids.unselected, tags)
          }
        }}
        style={{
          userSelect: 'none',
          textDecorationLine: ids.areAllSelected ? 'underline' : undefined,
        }}>
        {prefixId}
      </Text>
      <Text bold style={{ color: 'lightGrey' }}>
        {' | '}
      </Text>
      <Text
        bold={!ids.areAllSelected && ids[prefixId].isSelected}
        type="small"
        onPress={() => onPress(prefixId)}
        style={{
          userSelect: 'none',
          textDecorationLine:
            !ids.areAllSelected && ids[prefixId].isSelected
              ? 'underline'
              : undefined,
        }}>
        {`core (${ids[prefixId]?.count})`}
      </Text>
      <Text bold style={{ color: 'lightGrey' }}>
        {' | '}
      </Text>
      {tags.map((tag, idx, arr) => {
        if (tag.tag === prefixId) {
          return null
        }

        const isLastItem = idx === arr.length - 1

        return (
          <>
            <Text
              type="small"
              onPress={() => onPress(tag.tag)}
              style={{
                userSelect: 'none',
                textDecorationLine:
                  !ids.areAllSelected && ids[tag.tag].isSelected
                    ? 'underline'
                    : undefined,
              }}>
              {`${tag.tag.replace(`${prefixId}_`, '')} (${tag.count})`}
            </Text>
            {isLastItem === false && (
              <Text bold style={{ color: 'lightGrey' }}>
                {' | '}
              </Text>
            )}
          </>
        )
      })}
    </View>
  )
}

const TagSearchInput = ({ onChangeText, value }) => {
  const ref = useRef(null)

  const clearInput = () => {
    onChangeText('')
    ref.current.blur()
  }

  useEscapeKey({
    inputRef: ref,
    onPress: clearInput,
  })

  return (
    <CustomInput
      forwardRef={ref}
      onChangeText={onChangeText}
      placeholder="Search Tags..."
      value={value}
      style={{
        alignSelf: 'center',
        borderRadius: 25,
        paddingHorizontal: 24,
        paddingVertical: 4,
        marginBottom: undefined,
        marginRight: 6,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    />
  )
}

class _TagCloud extends React.Component {
  static getDerivedStateFromProps({ tags }, state) {
    if (equals(tags, state.tags)) {
      return null
    }

    return {
      ...state,
      tags,
      filteredTags:
        state.searchTerm !== ''
          ? tags.filter(({ tag }) => tag.includes(state.searchTerm))
          : tags,
    }
  }

  state = {
    filteredTags: this.props.tags,
    searchTerm: '',
  }

  componentDidMount() {
    this.props.actions.getTags()
  }

  _filterTagIdsForSearchTerm = debounce((searchTerm) => {
    this.setState({
      filteredTags:
        searchTerm === ''
          ? this.props.tags
          : this.props.tags.filter(({ tag }) => tag.includes(searchTerm)),
    })
  }, 600)

  _onChangeText = (searchTerm) => {
    this.setState({ searchTerm })
    this._filterTagIdsForSearchTerm(searchTerm)
  }

  render() {
    const { onPressTag, selectedTagIds } = this.props
    const { filteredTags, searchTerm } = this.state

    const hasSearch = this.props.tags.length > 0

    const prefixes = Array.from(
      filteredTags.reduce((acc, tag) => {
        if (/^\w+_[\w_]+$/.test(tag.tag)) {
          const prefixId = tag.tag.replace(/^(.*?)_.+$/, '$1')

          if (filteredTags.some((tag) => tag.tag === prefixId)) {
            acc.add(prefixId)
          }
        }
        return acc
      }, new Set())
    )

    const [switches, tags] = filteredTags.reduce(
      (acc, tag) => {
        const prefixIndex = prefixes.findIndex((prefix) =>
          new RegExp(`^(${prefix}|${prefix}_\\w+?)$`).test(tag.tag)
        )

        if (prefixIndex > -1) {
          acc[0][prefixIndex].push(tag)
        } else {
          acc[1].push(tag)
        }
        return acc
      },
      [prefixes.map(() => []), []]
    )

    return (
      <View style={styles.container}>
        <View style={styles.tagCloudContainer}>
          {hasSearch && (
            <TagSearchInput
              onChangeText={this._onChangeText}
              value={searchTerm}
            />
          )}

          {switches.map((tags, index) => (
            <TagSwitch
              key={prefixes[index]}
              prefixId={prefixes[index]}
              onPressTag={onPressTag}
              selectedTagIds={selectedTagIds}
              tags={tags}
            />
          ))}
          {tags.map((tag) => (
            <TagBadge
              key={tag.tag}
              onPressTag={onPressTag}
              selectedTagIds={selectedTagIds}
              tag={tag}
            />
          ))}
        </View>
      </View>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  language: getSelectedLanguage,
  tags: getTagArray,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getTags }, dispatch),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const enhancer = compose(withConnect)

export const TagCloud = enhancer(_TagCloud)
