import { events } from '@tellonym/core/events'
import { safeEffects } from '@tellonym/core/sagas'
import { eventChannel } from 'redux-saga'
import { put, select, spawn, take } from 'redux-saga/effects'
import { Alert, history } from '../common'
import { syncNavigationTableState } from '../common/components/TableEndlessScroll'
import { languageEnumAsString } from '../common/helpers'
import {
  refresh,
  refreshArtTellGroups,
  refreshGroups,
  setSelectedGroupId,
} from './actions'
import { getSelectedLanguage } from './selectors'
import * as t from './types'

const declineGroupSuccess = function* () {
  yield Alert.success('Language Declined')
}

const syncUrlLanguageWithState = function* () {
  const hasLanguageParam = /^\/artificialtells\/\w{2}[?/]/.test(
    history.location.pathname
  )

  if (hasLanguageParam) {
    const language = yield select(getSelectedLanguage)
    const [, routeString, lang, ...rest] = history.location.pathname.split('/')

    if (lang !== language) {
      const updatedRoute = [routeString, language, ...rest].join('/')

      history.replace({
        pathname: `/${updatedRoute}`,
        search: history.location.search,
      })
    }
  }
}

const syncUrlGroupIdWithState = function* (action) {
  const [, routeString, lang, groupString, , ...rest] =
    window.location.pathname.split('/')

  const updatedRoute = [
    routeString,
    lang,
    groupString,
    action.payload,
    ...rest,
  ].join('/')

  history.replace({
    pathname: `/${updatedRoute}`,
    search: history.location.search,
  })
}

const refreshArtificialData = function* ({ meta }) {
  const groupId = meta.id || meta.groupId

  if (window.location.pathname.startsWith('/artificialtells/search')) {
    yield put(refreshArtTellGroups({ searchString: meta.searchString }))
  } else if (groupId && meta.language) {
    const originLanguage = yield select(getSelectedLanguage)
    yield put(refresh({ groupId, language: meta.language, originLanguage }))
  }
}

const refreshArtificialGroupsAndRedirect = function* ({ meta, payload }) {
  const language = languageEnumAsString[meta.language]

  yield put(refreshGroups({ language }))
  yield history.push(`/artificialtells/${language}/group/${payload.id}`)
  yield put(setSelectedGroupId(payload.id))
}

export const handleHistoryChange = function* syncUrl() {
  const channel = eventChannel((emitter) => {
    const unlisten = history.listen(emitter)
    return () => {
      unlisten()
    }
  })

  while (true) {
    yield take(channel)
    yield spawn(syncUrlLanguageWithState)
  }
}

const syncUrlWithTable = function* syncUrlWithTable(action) {
  syncNavigationTableState(action.payload)
}

export const eventHandler = {
  [events.LOAD]: [handleHistoryChange],
}

export const actionHandler = {
  [t.ADD_SUCCESS]: [safeEffects.takeLatest, refreshArtificialData],
  [t.ADD_GROUP_SUCCESS]: [safeEffects.takeLatest, refreshArtificialGroupsAndRedirect], // prettier-ignore
  [t.CHANGE_APPROVAL_SUCCESS]: [safeEffects.takeLatest, refreshArtificialData],
  [t.DECLINE_GROUP_SUCCESS]: [safeEffects.takeLatest, declineGroupSuccess, refreshArtificialData], // prettier-ignore
  [t.EDIT_SUCCESS]: [safeEffects.takeLatest, refreshArtificialData],
  [t.DECLINE_SUCCESS]: [safeEffects.takeLatest, refreshArtificialData],
  [t.SET_SELECTED_LANGUAGE]: [safeEffects.takeLatest, syncUrlLanguageWithState],
  [t.SET_SELECTED_GROUP_ID]: [safeEffects.takeLatest, syncUrlGroupIdWithState],
  [t.SET_TABLE_STATE]: [safeEffects.takeLatest, syncUrlWithTable],
}
