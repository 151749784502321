import { countriesToTrack, INTERVAL_TYPE } from '@tellonym/enums/lib/Stats'
import { Button } from 'antd'
import { compose, prop } from 'ramda'
import React, { useCallback, useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { getIsSidemenuShown } from '../../app/selectors'
import { LoadingIndicator, styleSheets, Text, theme, View } from '../../common'
import { useQueryParams } from '../../common/hooks'
import { SideMenu } from '../../navigation/components/SideMenu'
import { refreshAvailableShortNames, refreshExperiments } from '../actions'
import { dateTypes } from '../constants'
import {
  getAvailableShortNamesData,
  getExperimentsData,
  getIsRefreshingExperiments,
} from '../selectors'
import { dateTypeToTimespan } from '../services'
import { InputQueryParam } from './InputQueryParam'
import { ShortNamesSelector } from './ShortNamesSelector'
import { StatsTable } from './StatsTable'
import { TimeFramePicker } from './TimeFramePicker'

const defaultQueryParams = {
  countryCode: 'DE',
  controlGroupId: undefined,
  dateType: dateTypes.LAST_14_DAYS,
  endDate: undefined,
  experimentIds: undefined,
  timespan: 14,
  intervalType: INTERVAL_TYPE.DAILY,
  shortNames: [],
  startDate: undefined,
}

const styles = {
  headerContainer: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    marginTop: 24,
  },
}

const Header = ({ hasSetRequiredValues, onPressRefresh, isLoading }) => {
  const groups = useSelector(getAvailableShortNamesData)

  return (
    <View style={{ position: 'relative' }}>
      <View style={styles.headerContainer}>
        <View style={styleSheets.flex[1]}>
          <Text bold type="h1" style={styleSheets.margin.bottom[24]}>
            Statistic Changes: Experiments
          </Text>

          <View style={styleSheets.margin.left[12]}>
            <TimeFramePicker
              defaultQueryParams={defaultQueryParams}
              isLoading={isLoading}
            />

            <InputQueryParam
              defaultQueryParams={defaultQueryParams}
              hasMultipleValues
              isDisabled={isLoading}
              queryParamName="experimentIds"
              text="Experiment Ids"
              submitTransformer={(array) => array.join(',')}
              validateValue={(values) =>
                values.every((v) => Number.isInteger(Number(v)))
              }
              options={[]}
            />

            <InputQueryParam
              defaultQueryParams={defaultQueryParams}
              isDisabled={isLoading}
              queryParamName="controlGroupId"
              text="Control Group Id"
              validateValue={(value) => Number.isInteger(Number(value))}
            />

            <InputQueryParam
              defaultQueryParams={defaultQueryParams}
              options={countriesToTrack.map((v) => ({ label: v, value: v }))}
              isDisabled={isLoading}
              queryParamName="countryCode"
              text="Country"
              validateValue={(value) =>
                countriesToTrack.indexOf(value.toUpperCase()) > -1
              }
            />
          </View>
        </View>

        <View style={[styleSheets.flex[2], styleSheets.margin.top[24]]}>
          <ShortNamesSelector groups={groups} />
        </View>
      </View>
      <Button
        type="primary"
        shape="round"
        disabled={!hasSetRequiredValues}
        loading={isLoading}
        onClick={onPressRefresh}
        style={styleSheets.alignSelf.center}>
        Refresh
      </Button>
    </View>
  )
}

const getHeaderData = (metrics) =>
  metrics.map((metric) => ({
    id: metric,
    submetric: null,
    metric,
  }))

const _PageExperiment = ({
  actions,
  experimentData,
  isRefreshing,
  isSidemenuShown,
}) => {
  const [queryParams] = useQueryParams(defaultQueryParams)
  const [containerStyles, setContainerStyles] = useState({
    width: window.tnym.getWidth() - (isSidemenuShown ? SideMenu.width : 0),
    height: window.tnym.getHeight(),
  })

  const hasData = !!experimentData.ids[0]
  const metrics = hasData
    ? experimentData.data[experimentData.ids[0]].data.map(prop('experimentId'))
    : []

  const hasSetRequiredValues =
    queryParams.controlGroupId &&
    queryParams.experimentIds &&
    queryParams.shortNames &&
    queryParams.shortNames.length !== 0

  const onPressRefresh = useCallback(() => {
    if (hasSetRequiredValues) {
      const { endDate, timespan } = dateTypeToTimespan(queryParams)

      const payload = {
        ...queryParams,
        endDate,
        shortNames: Object.values(queryParams.shortNames),
        timespan,
      }

      actions.refresh(payload)
    }
  }, [hasSetRequiredValues, queryParams])

  const getHeaderTitle = () =>
    [
      'Exp',
      queryParams.countryCode?.toUpperCase?.() ?? '',
      queryParams.experimentIds ?? '',
      '- Tellonym Modcp',
    ].join(' ')

  useEffect(() => {
    actions.refreshAvailableShortNames()
  }, [])

  useEffect(() => {
    setContainerStyles({
      width: window.tnym.getWidth() - (isSidemenuShown ? SideMenu.width : 0),
      height: window.tnym.getHeight(),
    })
  }, [isSidemenuShown])

  useEffect(() => {
    onPressRefresh()
  }, [])

  return (
    <View
      style={[
        containerStyles,
        {
          backgroundColor: theme.colors.background,
        },
      ]}>
      <Helmet title={getHeaderTitle()} />
      <LoadingIndicator isLoading={isRefreshing} />
      <Header
        hasSetRequiredValues={hasSetRequiredValues}
        onPressRefresh={onPressRefresh}
        isLoading={isRefreshing}
      />
      <StatsTable
        hasGraph
        isCompareExperiments
        items={experimentData}
        headerData={getHeaderData(metrics)}
      />
    </View>
  )
}

const mapStateToProps = createStructuredSelector({
  experimentData: getExperimentsData,
  isRefreshing: getIsRefreshingExperiments,
  isSidemenuShown: getIsSidemenuShown,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { refresh: refreshExperiments, refreshAvailableShortNames },
    dispatch
  ),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const enhancer = compose(withConnect)

export const PageExperiment = enhancer(_PageExperiment)
