import { colors } from '@tellonym/core/common/colorSystem'
import { SPAM_DETECTION_TYPE } from '@tellonym/enums/lib/SpamDetecton'
import { Button, Modal } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import { Avatar, Box, Text, history } from '../../common'
import { ReadMore } from '../../common/components/ReadMore'

const LabelValue = ({ children, label, value }) => (
  <Text color={colors.grey[7]} style={{ marginRight: 12, fontStyle: 'italic' }}>
    {`${label}: `}
    {typeof children !== 'undefined' ? (
      children
    ) : (
      <Text color={colors.black[1]} style={{ fontStyle: 'normal' }}>
        {value}
      </Text>
    )}
  </Text>
)

const UserItem = ({ profile }) => {
  return (
    <Box
      flexDirection="row"
      onPress={() => history.push(`/user/${profile.id}`)}
      marginBottom={12}>
      <Avatar user={profile} />
      <Box marginLeft={12}>
        <Text bold>{profile.displayName}</Text>
        <Text>{`@${profile.username}`}</Text>
      </Box>
      <Box marginLeft="auto">
        <Text type="small">last active:</Text>
        <Text type="small" color={colors.grey[7]}>
          {dayjs(profile.lastActiveAllAt).fromNow()}
        </Text>
      </Box>
    </Box>
  )
}

export const SpamItem = ({ item }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false)

  const type =
    SPAM_DETECTION_TYPE[item.type].replace(/_/g, ' ').toLowerCase() || 'Unknown'

  const onPressShowUsers = () => {
    setIsModalVisible(true)
  }

  const onClose = () => {
    setIsModalVisible(false)
  }

  return (
    <Box
      backgroundColor={colors.white[1]}
      borderWidth={1}
      borderColor="#F0F0F3"
      borderRadius={20}
      borderStyle="solid"
      padding={20}
      marginBottom={12}>
      <Box flexDirection="row" justifyContent="space-between">
        <Text bold style={{ marginBottom: 12 }}>
          {type}
        </Text>
        <Text>{dayjs(item.time).format('DD.MM.YY HH:mm')}</Text>
      </Box>
      <LabelValue label="content">
        <ReadMore maxLength={300}>{item.content}</ReadMore>
      </LabelValue>
      <Box flexDirection="row" marginTop={12} alignItems="center">
        {item.amountUsers && (
          <LabelValue label="Amount users" value={item.amountUsers} />
        )}
        {item.amountBlocked &&
          Object.keys(item.amountBlocked).map((key) => (
            <LabelValue key={key} label={key} value={item.amountBlocked[key]} />
          ))}
        {item.ip && <LabelValue label="IP" value={item.ip} />}
        {item.socialLink && (
          <LabelValue label="Social Link" value={item.socialLink} />
        )}
        <Button
          shape="round"
          onClick={onPressShowUsers}
          style={{ marginLeft: 'auto' }}>
          Show users
        </Button>
        <Modal
          visible={isModalVisible}
          onOk={onClose}
          onCancel={onClose}
          width="75%">
          <Text type="h2" bold style={{ marginBottom: 12 }}>
            Users
          </Text>
          {item.users &&
            item.users.map((user) => <UserItem key={user.id} profile={user} />)}
        </Modal>
      </Box>
    </Box>
  )
}
