import { CheckCircleOutlined, CopyOutlined } from '@ant-design/icons'
import { copyToClipboard } from '@tellonym/core/share/actions'
import { Button, DatePicker, Input, Radio, Select, Typography } from 'antd'
import moment from 'moment'
import qs from 'qs'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { colors, View } from '../../common'
import { snakeToCamelCase } from '../../statistics/services'
import {
  customDimensionKeyIds,
  dateTypes,
  dimensions,
  metrics,
  sources,
} from '../constants'

const { Title, Text } = Typography

const styles = {
  bottomMargin: { marginBottom: '2%' },
  buttonTest: { marginTop: '4%' },
  buttonTestSuccess: { color: colors.green, borderColor: colors.green },
  container: { padding: '2%' },
  datePicker: { marginLeft: '1%' },
  errorText: { flex: 1, display: 'flex', wordBreak: 'break-word' },
  halfpage: { width: '48%' },
  reportRowItem: { flex: 1, paddingRight: '2%' },
  row: { flexDirection: 'row' },
  textareaButtonContainer: { marginLeft: '1%', paddingRight: '2%' },
}

const Selector = ({ options, onChange, ...props }) => {
  if (options && options.length === 0) {
    return null
  }

  return (
    <Select
      defaultValue={options[0].value}
      onChange={(v) => onChange(v)}
      style={{ width: 240 }}
      options={options}
      {...props}
    />
  )
}

const SearchSelector = (props) => (
  <Selector
    allowClear
    autoClearSearchValue
    maxTagCount={5}
    maxTagTextLength={16}
    mode="multiple"
    showSearch
    style={{ width: '100%' }}
    {...props}
  />
)

const dateTypeOptions = Object.keys(dateTypes).map((dateType) => ({
  value: dateType,
  label: snakeToCamelCase(dateType),
}))

const MetaSection = ({ changeQuery }) => {
  const [selectedDateType, setSelectedDateType] = useState(
    dateTypeOptions[0].value
  )

  const getDisabledDate = (current) =>
    current && current > moment().endOf('day')

  const onChangeDates = (values) => {
    if (values && values.length > 1) {
      const [startDate, endDate] = values
      if (selectedDateType !== 'CUSTOM_DATE') {
        setSelectedDateType('CUSTOM_DATE')
      }
      changeQuery({
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      })
    }
  }

  useEffect(() => {
    changeQuery({ dateRangeType: selectedDateType })
  }, [selectedDateType])

  return (
    <View style={styles.bottomMargin}>
      <Title level={4}>Date range</Title>
      <View style={styles.row}>
        <Selector
          options={dateTypeOptions}
          onChange={setSelectedDateType}
          value={selectedDateType}
        />
        <DatePicker.RangePicker
          defaultPickerValue={[
            moment().subtract(31, 'days'),
            moment().subtract(1, 'day'),
          ]}
          disabledDate={getDisabledDate}
          onChange={onChangeDates}
          style={styles.datePicker}
        />
      </View>
    </View>
  )
}

const sourcesOptions = [
  { value: sources.TOTAL, label: 'Total' },
  { value: sources.AD_SERVER, label: 'Ad Server' },
  { value: sources.ADX, label: 'AdExchange' },
]

const transforToOptions = (object) =>
  Object.keys(object).map((option) => ({
    value: option,
    label: snakeToCamelCase(option).replace('Line Item Level ', ''),
  }))

const transformWithSources = (object) =>
  Object.keys(sources).reduce((acc, source) => {
    acc[source] = transforToOptions(object[source])

    return acc
  }, {})

const dimensionOptions = transformWithSources(dimensions)

const metricsOptions = transformWithSources(metrics)

const customDimensionKeyIdsOptions = transforToOptions(customDimensionKeyIds)

const ReportSection = ({ changeQuery }) => {
  const [selectedDimensions, setSelectedDimensions] = useState([])
  const [selectedMetrics, setSelectedMetrics] = useState([])
  const [selectedSource, setSelectedSource] = useState(sourcesOptions[0].value)
  const [statement, setStatement] = useState('')
  const [selectedCustomDimensionKeyIds, setSelectedCustomDimensionKeyIds] =
    useState([])

  const hasSelectedCustomDimension = useMemo(
    () =>
      selectedDimensions.includes(dimensions[sources.TOTAL].CUSTOM_DIMENSION),
    [selectedDimensions]
  )

  const onChangeCustomDimensionKeyIds = (value) => {
    setSelectedCustomDimensionKeyIds(value)

    if (value.length === 0) {
      changeQuery({ customDimensionKeyIds: undefined })
    } else {
      changeQuery({
        customDimensionKeyIds: value.map((v) => customDimensionKeyIds[v]),
      })
    }
  }

  const onChangeDimensions = (value) => {
    setSelectedDimensions(value)
    if (
      selectedCustomDimensionKeyIds.length > 0 &&
      !value.includes(dimensions[sources.TOTAL].CUSTOM_DIMENSION)
    ) {
      onChangeCustomDimensionKeyIds([])
      // needs to be done explicitly otherwise it gets overwritten
      changeQuery({ dimensions: value, customDimensionKeyIds: undefined })
    } else {
      changeQuery({ dimensions: value })
    }
  }

  const onChangeMetrics = (value) => {
    setSelectedMetrics(value)
    changeQuery({ columns: value })
  }

  const onChangeSource = ({ target: { value } }) => {
    if (selectedSource !== value) {
      onChangeMetrics([])
      onChangeDimensions([])
    }
    setSelectedSource(value)
  }

  const onChangeStatement = ({ target: { value } }) => {
    setStatement(value)

    if (value === '') {
      changeQuery({ statement: undefined })
    } else {
      changeQuery({ statement: value })
    }
  }

  return (
    <View style={styles.bottomMargin}>
      <Title level={4}>Report</Title>
      <Radio.Group
        buttonStyle="solid"
        onChange={onChangeSource}
        options={sourcesOptions}
        optionType="button"
        value={selectedSource}
        style={styles.bottomMargin}
      />
      <View style={[styles.row, styles.bottomMargin]}>
        <View style={styles.reportRowItem}>
          <Title level={5}>Dimensions</Title>
          <SearchSelector
            options={dimensionOptions[selectedSource]}
            onChange={onChangeDimensions}
            value={selectedDimensions}
          />
        </View>
        <View style={styles.reportRowItem}>
          <Title level={5}>Metrics</Title>
          <SearchSelector
            options={metricsOptions[selectedSource]}
            onChange={onChangeMetrics}
            value={selectedMetrics}
          />
        </View>
      </View>
      <View
        style={[
          styles.reportRowItem,
          hasSelectedCustomDimension && styles.bottomMargin,
        ]}>
        <Title level={5}>Statement</Title>
        <Input
          allowClear
          placeholder="WHERE ADVERTISER_NAME IN ('Amazon Publisher Services')"
          value={statement}
          onChange={onChangeStatement}
        />
      </View>
      {hasSelectedCustomDimension && (
        <View style={styles.halfpage}>
          <Title level={5}>Custom Dimension Key Ids</Title>
          <SearchSelector
            options={customDimensionKeyIdsOptions}
            value={selectedCustomDimensionKeyIds}
            onChange={onChangeCustomDimensionKeyIds}
          />
        </View>
      )}
    </View>
  )
}

const defaultQuery = {
  dimensions: [],
  columns: [],
  dateRangeType: '',
}

const validateQuery = async (queryString) => {
  try {
    const query = JSON.parse(queryString)

    try {
      const url = 'https://gam-api.tnym.de/gam/validate'
      const response = await fetch(`${url}?${qs.stringify({ query })}`)

      if (!response.ok) {
        throw new Error(
          `Request Failed: ${response.statusText}\n${await response.text()}`
        )
      }

      return undefined
    } catch (e) {
      return e.message
    }
  } catch (e) {
    return 'No valid JSON'
  }
}

const sortAlphabetically = (array) => array.sort((a, b) => a.localeCompare(b))

export const GamQueryTool = () => {
  const dispatch = useDispatch()
  const [query, setQuery] = useState(defaultQuery)
  const [queryString, setQueryString] = useState(JSON.stringify(defaultQuery))
  const [isRunningTest, setIsRunningTest] = useState(false)
  const [errorString, setErrorString] = useState('')
  const [hasValidationSucceeded, setHasValidationSucceeded] = useState(false)

  const onChangeQuery = useCallback(({ target: { value } }) => {
    setQueryString(value)
  }, [])

  const copyQuery = useCallback(() => {
    dispatch(copyToClipboard(queryString))
  }, [dispatch, queryString])

  const changeQuery = useCallback(
    (object) => {
      const updatedQuery = { ...query, ...object }

      updatedQuery.dimensions = sortAlphabetically(updatedQuery.dimensions)
      updatedQuery.columns = sortAlphabetically(updatedQuery.columns)

      setQuery(updatedQuery)
      setQueryString(JSON.stringify(updatedQuery))
    },
    [query]
  )

  const onPressTest = async () => {
    setIsRunningTest(true)
    setHasValidationSucceeded(false)
    setErrorString('')

    const error = await validateQuery(queryString)

    setIsRunningTest(false)

    if (error) {
      setErrorString(error)
    } else {
      setHasValidationSucceeded(true)
    }
  }

  return (
    <View style={styles.container}>
      <MetaSection changeQuery={changeQuery} />
      <ReportSection changeQuery={changeQuery} />

      <Title level={4}>Query</Title>
      <View style={styles.row}>
        <Input.TextArea
          value={queryString}
          onChange={onChangeQuery}
          autoSize={{ minRows: 3 }}
          style={styles.halfpage}
        />
        <View style={styles.textareaButtonContainer}>
          <Button type="primary" onClick={copyQuery} icon={<CopyOutlined />}>
            Copy
          </Button>
          <Button
            disabled={isRunningTest}
            icon={hasValidationSucceeded ? <CheckCircleOutlined /> : undefined}
            loading={isRunningTest}
            onClick={onPressTest}
            style={{
              ...styles.buttonTest,
              ...(hasValidationSucceeded ? styles.buttonTestSuccess : {}),
            }}>
            Test
          </Button>
        </View>
        <Text type="danger" style={styles.errorText}>
          {errorString}
        </Text>
      </View>
    </View>
  )
}
