import { compose, join, map, split, trim } from 'ramda'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import {
  Alert,
  history,
  hooks,
  Input,
  Text,
  theme,
  TouchableOpacity,
  View,
} from '../../common'
import {
  refreshArtificialTells,
  refreshArtTellGroups,
  setSearchString,
  setSearchStringArtTells,
  setSearchTags,
} from '../actions'
import {
  getIsRefreshingResult,
  getSearchString,
  getSearchTags,
} from '../selectors'

const searchMethods = {
  CONTENT: 'content',
  GROUP_TAG: 'groupTag',
}

const styles = {
  searchBarContainer: {
    width: '100%',
    backgroundColor: theme.colors.background,
    boxShadow: theme.styles.shadow,
    borderRadius: 25,
    paddingRight: 24,
  },
  searchMethodsContainer: {
    marginRight: 12,
    borderRight: `1px solid ${theme.colors.border}`,
  },
  searchMethodButton_GroupTag: {
    borderBottom: `1px solid ${theme.colors.border}`,
    borderTopLeftRadius: 25,
  },
  searchMethodButtonLabel_GroupTag: {
    paddingRight: 16,
    paddingLeft: 20,
    paddingTop: 12,
    paddingBottom: 8,
    onHover: {
      color: theme.colors.primary,
    },
  },
  searchMethodButtonSelected: {
    backgroundColor: theme.colors.darkerBackground,
    boxShadow: theme.styles.shadowInset,
  },
  searchMethodButtonLabel_Content: {
    paddingRight: 8,
    paddingLeft: 20,
    paddingTop: 8,
    paddingBottom: 12,
    onHover: {
      color: theme.colors.primary,
    },
  },
}

const arrayFromTagString = compose(map(trim), split(','))

const stringFromTagArray = join(', ')

const _SearchBar = ({
  actions,
  searchString,
  searchTags,
  shouldFocusOnMount,
  _searchMethod,
  style,
}) => {
  const [searchMethod, setSearchMethod] = useState(
    _searchMethod || searchMethods.GROUP_TAG
  )
  const [inputValue, setInputValue] = useState(searchString || '')
  const [tagsValue, setTagsValue] = useState(
    searchTags ? stringFromTagArray(searchTags) : ''
  )
  const inputRef = useRef(null)
  const tagsRef = useRef(null)

  const validateInputs = () => {
    if (inputValue !== '' && inputValue.length < 3) {
      Alert.error('Search must contain at least 3 characters')

      return false
    }

    return true
  }

  const onPressSubmit = () => {
    const isValid = validateInputs()
    const {
      refreshArtTellGroups,
      refreshArtificialTells,
      setSearchString,
      setSearchStringArtTells,
    } = actions

    if (isValid) {
      const tags = tagsValue !== '' ? arrayFromTagString(tagsValue) : undefined
      const searchString = inputValue !== '' ? inputValue : undefined

      if (searchString || tags) {
        if (searchMethod === searchMethods.GROUP_TAG) {
          setSearchString(searchString)
          setSearchTags(tags)

          refreshArtTellGroups({ searchString, tags })
        } else {
          setSearchStringArtTells(searchString)

          refreshArtificialTells({ searchString })
        }

        const isSearchRoute = window.location.pathname.startsWith(
          '/artificialtells/search'
        )

        if (!isSearchRoute) {
          history.push('/artificialtells/search')
        }
      }
    }
  }

  const onPressEscape = () => {
    inputRef.current.blur()
    tagsRef.current.blur()
    setInputValue('')
    setTagsValue('')
  }

  hooks.useKeyboardShortcutToSubmit({ inputRef, onSubmit: onPressSubmit })
  hooks.useKeyboardShortcutToSubmit({
    inputRef: tagsRef,
    onSubmit: onPressSubmit,
  })
  hooks.useEscapeKey({ inputRef, onPress: onPressEscape })
  hooks.useEscapeKey({ inputRef: tagsRef, onPress: onPressEscape })

  useEffect(() => {
    if (shouldFocusOnMount) {
      inputRef.current.focus()
    }
  }, [])

  return (
    <View style={[styles.searchBarContainer, style]}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <View style={styles.searchMethodsContainer}>
          <TouchableOpacity
            onPress={() => setSearchMethod(searchMethods.GROUP_TAG)}
            style={[
              styles.searchMethodButton_GroupTag,
              searchMethod === searchMethods.GROUP_TAG &&
                styles.searchMethodButtonSelected,
            ]}>
            <Text style={styles.searchMethodButtonLabel_GroupTag}>
              Group/Tag
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => setSearchMethod(searchMethods.CONTENT)}
            style={[
              { borderBottomLeftRadius: 25 },
              searchMethod === searchMethods.CONTENT &&
                styles.searchMethodButtonSelected,
            ]}>
            <Text style={styles.searchMethodButtonLabel_Content}>Content</Text>
          </TouchableOpacity>
        </View>
        {searchMethod === searchMethods.CONTENT ? (
          <View style={{ flex: 1 }}>
            <Input
              forwardRef={inputRef}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Search for Content..."
              value={inputValue}
              style={{ marginBottom: 4, flex: 1 }}
            />
          </View>
        ) : (
          <View style={{ flex: 1 }}>
            <Input
              forwardRef={inputRef}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Search for Group..."
              value={inputValue}
              style={{ marginBottom: 4, flex: 1 }}
            />
            <Input
              forwardRef={tagsRef}
              onChangeText={setTagsValue}
              placeholder="Search for tag1, tag2..."
              value={tagsValue}
              style={{ marginBottom: 0, flex: 1, fontSize: 14 }}
            />
          </View>
        )}
        {(inputValue !== '' || tagsValue !== '') && (
          <Text onPress={onPressSubmit} color={theme.colors.primary}>
            Search
          </Text>
        )}
      </View>
    </View>
  )
}

const mapStateToProps = createStructuredSelector({
  isRefreshing: getIsRefreshingResult,
  searchString: getSearchString,
  searchTags: getSearchTags,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      refreshArtTellGroups,
      refreshArtificialTells,
      setSearchStringArtTells,
      setSearchString,
      setSearchTags,
    },
    dispatch
  ),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const enhancer = withConnect

export const SearchBar = enhancer(_SearchBar)
