import { DatePicker, Select, Table } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import { Box, Text } from '../../common'
import { convertToOptions } from '../../common/helpers'
import { useNotionDbQuery } from '../queries'

const styles = {
  queryInput: {
    width: 260,
    marginRight: 12,
  },
  label: { marginBottom: 8, marginLeft: 8 },
  note: { fontStyle: 'italic' },
}

const rowKey = (record) => record.name

const onRow = (record) => ({
  style: {
    fontWeight: record.name === 'Sum' ? 'bold' : 'normal',
  },
})

const candidatesDbId = '1c7510384b3643519ee8bed3bd9949d5'

const jobPositionDbId = '10bd9eeab5c846fc8a90230b5d5ebb53'

const jobDefaultOptions = convertToOptions(['All'])

const defaultQueryConfig = {
  startDate: dayjs().subtract(30, 'day'),
  job: jobDefaultOptions[0].value,
}

const DATA_TYPE = {
  CONVERSION: 'Conversion',
  REASON: 'Cancellation Reason',
  CW: 'Calendar Week',
}

const dataTypeOptions = convertToOptions(Object.values(DATA_TYPE))

const BoldText = ({ name }) => (
  <Text semibold type="small">
    {name}
  </Text>
)

const ComponentWithLabel = ({ children, label }) => (
  <Box>
    <Text semibold type="small" style={styles.label}>
      {label}
    </Text>
    {children}
  </Box>
)

/**
 * Select dropdown that only allows predefined values
 */
const SelectWithLabel = (props) => (
  <Box>
    <Text semibold type="small" style={styles.label}>
      {props.label}
    </Text>
    <Select style={styles.queryInput} {...props} />
  </Box>
)

const SelectJob = ({ onChange, value }) => {
  const { data, isFetching } = useNotionDbQuery({
    database_id: jobPositionDbId,
  })

  const options = React.useMemo(() => {
    if (typeof data === 'undefined') {
      return jobDefaultOptions
    }

    const jobOptions = data.map((page) => ({
      label: page.properties.Name.title[0].plain_text,
      value: page.id,
    }))

    return [...jobDefaultOptions, ...jobOptions]
  }, [data])

  return (
    <SelectWithLabel
      label="Job"
      disabled={isFetching}
      onChange={onChange}
      options={options}
      value={value}
    />
  )
}

const dateInThePast = (date) => dayjs().isSameOrBefore(date)

const alphabeticalSorter = (key) => (a, b) => a[key].localeCompare(b[key])
const numberSorter = (key) => (a, b) => a[key] - b[key]

const columns = {
  [DATA_TYPE.CONVERSION]: [
    {
      title: <BoldText name="Country" />,
      dataIndex: 'name',
      width: 120,
      sorter: alphabeticalSorter('name'),
    },
    {
      title: 'Created',
      dataIndex: 'count',
      width: 50,
      sorter: numberSorter('count'),
    },
    {
      title: 'Declined',
      dataIndex: 'declined',
      width: 50,
      sorter: numberSorter('declined'),
    },
    {
      title: 'Stage 1',
      dataIndex: 'stage1',
      width: 50,
      sorter: numberSorter('stage1'),
    },
    {
      title: 'Stage 2',
      dataIndex: 'stage2',
      width: 50,
      sorter: numberSorter('laterStage'),
    },
    {
      title: 'Test Task',
      dataIndex: 'testTask',
      width: 50,
      sorter: numberSorter('laterStage'),
    },
    {
      title: 'Stage 3',
      dataIndex: 'stage3',
      width: 50,
      sorter: numberSorter('laterStage'),
    },
    {
      title: 'Stage 4',
      dataIndex: 'stage4',
      width: 50,
      sorter: numberSorter('laterStage'),
    },
    {
      title: 'Offer',
      dataIndex: 'offer',
      width: 50,
      sorter: numberSorter('offer'),
    },
  ],
  [DATA_TYPE.REASON]: [
    {
      title: <BoldText name="Cancellation Reason" />,
      dataIndex: 'name',
      width: 120,
      sorter: alphabeticalSorter('name'),
    },
    {
      title: 'Amount',
      dataIndex: 'count',
      width: 50,
      sorter: numberSorter('count'),
    },
  ],
}

const buildQueryConfig = ({ job, startDate }) => {
  const sorts = [
    {
      timestamp: 'created_time',
      direction: 'descending',
    },
  ]

  const filters = []

  if (job !== 'All') {
    filters.push({ property: 'Position', relation: { contains: job } })
  }

  return {
    database_id: candidatesDbId,
    sorts,
    filter: {
      timestamp: 'created_time',
      created_time: {
        on_or_after: dayjs(startDate).format('YYYY-MM-DD'),
      },
      and: filters,
    },
  }
}

/**
 * Sums up all keys that have a number value and adds them to a new object.
 *
 * @param {Array} data - Array of objects
 * @returns {Object} - Object with all keys from objects summed up
 */
const getSumRow = (data) => {
  const sumRow = data.reduce(
    (acc, curr) =>
      Object.keys(curr).reduce((acc, key) => {
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(Number(curr[key]))) {
          acc[key] = (acc[key] ?? 0) + curr[key]
        }

        return acc
      }, acc),
    { name: 'Sum' }
  )

  return sumRow
}

const offerStatus = [
  'Negotiation',
  'Request: Prepare Offer',
  'Accepted',
  'Reached Out',
]

const sumByCountry = (data, dataType) => {
  const countryData = data.reduce((acc, curr) => {
    const { Location, Status, Stage } = curr.properties
    const { multi_select: values } = Location

    const valueToUse = values.length > 0 ? values : [{ name: 'Not set' }]

    valueToUse.forEach(({ name: country = 'Not set' }) => {
      if (!acc[country]) {
        acc[country] = {
          name: country,
          totalEntries: data.length, // This is used in SumRow to calculate duplicates
          dataType, // This is used in SumRow to select columns
          count: 0,
          declined: 0,
          offer: 0,
          stage1: 0,
          stage2: 0,
          stage3: 0,
          stage4: 0,
          testTask: 0,
        }
      }

      acc[country].count += 1

      if (Status?.select?.name.includes('Decline')) {
        acc[country].declined += 1
      } else if (offerStatus.includes(Status?.select?.name)) {
        acc[country].offer += 1
      } else if (Stage.select?.name === 'Stage 1') {
        acc[country].stage1 += 1
      } else if (Stage.select?.name === 'Stage 2') {
        acc[country].stage2 += 1
      } else if (Stage.select?.name === 'Stage 3') {
        acc[country].stage3 += 1
      } else if (Stage.select?.name === 'Stage 4') {
        acc[country].stage4 += 1
      } else if (Stage.select?.name === 'Test Task') {
        acc[country].testTask += 1
      }
    })

    return acc
  }, {})

  return Object.values(countryData)
}

const sumByCancellationReason = (data, dataType) => {
  const countryData = data.reduce((acc, curr) => {
    const { 'Reason for cancellation': cancellationReason, Status } =
      curr.properties
    const { multi_select: values } = cancellationReason

    const valueToUse = values.length > 0 ? values : [{ name: 'Not set' }]

    valueToUse.forEach(({ name: reason = 'Not set' }) => {
      if (!acc[reason]) {
        acc[reason] = {
          name: reason,
          totalEntries: data.length, // This is used in SumRow to calculate duplicates
          dataType, // This is used in SumRow to select columns
          count: 0,
        }
      }

      if (Status?.select?.name.includes('Decline')) {
        acc[reason].count += 1
      }
    })

    return acc
  }, {})

  return Object.values(countryData)
}

const sumByCw = (data, dataType) => {
  const cwData = data.reduce(
    (acc, curr) => {
      const { Status, Stage } = curr.properties

      const week = dayjs(curr.created_time).week()
      const year = dayjs(curr.created_time).format('YY')
      const displayYear = dayjs().format('YY') === year ? undefined : year
      const cw = `CW ${week}${displayYear ? `-${displayYear}` : ''}`

      acc.count[cw] = (acc.count[cw] ?? 0) + 1

      if (Status?.select?.name.includes('Decline')) {
        acc.declined[cw] = (acc.declined[cw] ?? 0) + 1
      } else if (offerStatus.includes(Status?.select?.name)) {
        acc.offer[cw] = (acc.offer[cw] ?? 0) + 1
      } else if (Stage.select?.name === 'Stage 1') {
        acc.stage1[cw] = (acc.stage1[cw] ?? 0) + 1
      } else if (Stage.select?.name === 'Stage 2') {
        acc.stage2[cw] = (acc.stage2[cw] ?? 0) + 1
      } else if (Stage.select?.name === 'Stage 3') {
        acc.stage3[cw] = (acc.stage3[cw] ?? 0) + 1
      } else if (Stage.select?.name === 'Stage 4') {
        acc.stage4[cw] = (acc.stage4[cw] ?? 0) + 1
      } else if (Stage.select?.name === 'Test Task') {
        acc.testTask[cw] = (acc.testTask[cw] ?? 0) + 1
      }

      return acc
    },
    {
      count: { dataType, name: 'Created' },
      declined: { dataType, name: 'Declined' },
      stage1: { dataType, name: 'Stage 1' },
      stage2: { dataType, name: 'Stage 2' },
      testTask: { dataType, name: 'Test Task' },
      stage3: { dataType, name: 'Stage 3' },
      stage4: { dataType, name: 'Stage 4' },
      offer: { dataType, name: 'Offer' },
    }
  )

  return Object.keys(cwData).map((key) => ({ name: key, ...cwData[key] }))
}

const getTableColumns = (tableData, dataType) => {
  switch (dataType) {
    case DATA_TYPE.CONVERSION:
    case DATA_TYPE.REASON:
      return columns[dataType]

    case DATA_TYPE.CW: {
      if (tableData.length === 0) {
        return []
      }

      return Object.keys(tableData[0]).reduce(
        (acc, key) => {
          if (!key.startsWith('CW')) {
            return acc
          }

          acc.push({
            title: key,
            dataIndex: key,
            width: 50,
          })

          return acc
        },
        [{ title: 'Data', dataIndex: 'name', width: 120 }]
      )
    }

    default:
      return []
  }
}

const getTableData = (data, dataType) => {
  if (typeof data === 'undefined') {
    return []
  }

  switch (dataType) {
    case DATA_TYPE.CONVERSION: {
      return sumByCountry(data, dataType)
    }

    case DATA_TYPE.REASON: {
      return sumByCancellationReason(data, dataType)
    }

    case DATA_TYPE.CW: {
      return sumByCw(data, dataType)
    }

    default: {
      return []
    }
  }
}

const SumRow = (pageData) => {
  const sumData = React.useMemo(() => getSumRow(pageData), [pageData])

  const duplicatesCount = sumData?.count - pageData?.[0]?.totalEntries ?? 0
  const tableColumns = columns[pageData?.[0]?.dataType] ?? []

  return (
    <Table.Summary fixed>
      <Table.Summary.Row>
        {tableColumns.map((column, index) => (
          <Table.Summary.Cell index={index} key={column.dataIndex}>
            <BoldText name={index === 0 ? 'Sum' : sumData[column.dataIndex]} />
          </Table.Summary.Cell>
        ))}
      </Table.Summary.Row>
      {duplicatesCount > 0 && (
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={pageData.length}>
            <Text
              type="note"
              style={
                styles.note
              }>{`The summed values include ${duplicatesCount} duplicates since Location in notion can have multiple values which is mainly used for Country + City combination.`}</Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      )}
    </Table.Summary>
  )
}

export const TabJobConversion = () => {
  const [queryConfig, setQueryConfig] = React.useState(defaultQueryConfig)
  const [dataType, setDataType] = React.useState(DATA_TYPE.CONVERSION)

  const processedQueryConfig = React.useMemo(
    () => buildQueryConfig(queryConfig),
    [queryConfig]
  )

  const { data, isFetching } = useNotionDbQuery(processedQueryConfig)

  const tableData = React.useMemo(() => getTableData(data, dataType), [data])

  const tableColumns = React.useMemo(
    () => getTableColumns(tableData, dataType),
    [dataType]
  )

  const onChangeDataType = (value) => {
    setDataType(value)
  }

  const onChangeDate = (date) => {
    setQueryConfig({ ...queryConfig, startDate: date })
  }

  const onChangeJob = (job) => {
    setQueryConfig({ ...queryConfig, job })
  }

  return (
    <Box>
      <Box marginBottom={24} flexDirection="row">
        <SelectWithLabel
          label="Type of data"
          options={dataTypeOptions}
          onChange={onChangeDataType}
          value={dataType}
        />

        <ComponentWithLabel label="Start Date">
          <DatePicker
            allowClear={false}
            disabled={isFetching}
            disabledDate={dateInThePast}
            format="YYYY-MM-DD"
            onChange={onChangeDate}
            value={queryConfig.startDate}
            style={styles.queryInput}
          />
        </ComponentWithLabel>

        <SelectJob onChange={onChangeJob} value={queryConfig.job} />
      </Box>

      <Table
        columns={tableColumns}
        dataSource={tableData}
        loading={isFetching}
        onRow={onRow}
        pagination={false}
        rowKey={rowKey}
        scroll={{ x: 1 }}
        sticky={{ offsetHeader: 50 }}
        summary={SumRow}
      />
    </Box>
  )
}
