import { CloseOutlined } from '@ant-design/icons'
import { Line } from '@ant-design/plots'
import { colors } from '@tellonym/core/common/colorSystem'
import { getRandomInt } from '@tellonym/core/helpers'
import {
  Button,
  DatePicker,
  Input,
  Radio,
  Select,
  Spin,
  Typography,
} from 'antd'
import dayjs from 'dayjs'
import { compose } from 'ramda'
import React from 'react'
import { Box, View } from '../../common'
import { convertToOptions } from '../../common/helpers'
import * as hooks from '../../common/hooks'
import { hexWithAlpha } from '../../common/services'
import {
  useAnalyticsEventNamesQuery,
  useAnalyticsEventPropsQuery,
} from '../../tools/queries'
import { useChEventDataMutation } from '../queries'

const lineColors = [
  '#00B7FF',
  '#004DFF',
  '#00FFFF',
  '#826400',
  '#580041',
  '#FF00FF',
  '#00FF00',
  '#C500FF',
  '#B4FFD7',
  '#FFCA00',
  '#969600',
  '#B4A2FF',
  '#C20078',
  '#0000C1',
  '#FF8B00',
  '#FFC8FF',
  '#666666',
  '#FF0000',
  '#CCCCCC',
  '#009E8F',
  '#D7A870',
  '#8200FF',
  '#960000',
  '#BBFF00',
  '#FFFF00',
  '#006F00',
]

const { Title } = Typography

const defaultEntries = [
  ['event_time', '>', dayjs().subtract(25, 'hour').unix()],
  ['event_time', '<', dayjs().unix()],
  [],
]

const hoursFormatting = (v) => {
  const string = dayjs(v).format('HH:mm')

  if (string === '00:00') {
    return dayjs(v).format('DD.MM.')
  }

  return string
}

const timeIntervalFormat = {
  minute: {
    group: 'YYYY-MM-DD HH:mm',
    format: hoursFormatting,
  },
  hour: {
    group: 'YYYY-MM-DD HH:[00]',
    format: hoursFormatting,
  },
  day: { group: 'YYYY-MM-DD', format: (v) => dayjs(v).format('DD.MM.') },
}

const timeIntervalOptions = convertToOptions(Object.keys(timeIntervalFormat))

const LINE_CONFIG = {
  padding: 'auto',
  xField: 'date',
  yField: 'value',
  seriesField: 'dataType',
  connectNulls: false,
}

const GRAPH_DEFAULTS = {
  dataSource: [],
  lineConfig: LINE_CONFIG,
}

const Graph = ({ data, comparisonData, groupBy, isLoading, timeInterval }) => {
  const { dataSource, lineConfig } = React.useMemo(
    () => {
      if (
        typeof data === 'undefined' ||
        (!groupBy && typeof comparisonData === 'undefined')
      ) {
        return GRAPH_DEFAULTS
      }

      if (!groupBy) {
        const dataByTimeInterval = {}

        for (let i = 0; i < (data?.eventData?.length ?? 0); i++) {
          const pointEvent = data.eventData[i]
          const pointComparison = comparisonData.eventData[i]

          const date = dayjs(pointEvent.time).format(
            timeIntervalFormat[timeInterval].group
          )

          const keyEvent = `${date}-event`
          const keyComparison = `${date}-comparison`

          dataByTimeInterval[keyEvent] = dataByTimeInterval[keyEvent] ?? {
            date,
            value: 0,
            dataType: 'event',
          }

          dataByTimeInterval[keyComparison] = dataByTimeInterval[
            keyComparison
          ] ?? { date, value: 0, dataType: '7 days ago' }

          dataByTimeInterval[keyEvent].value += Number(pointEvent.amount)

          if (pointComparison) {
            dataByTimeInterval[keyComparison].value += Number(
              pointComparison.amount
            )
          }
        }

        return {
          dataSource: Object.values(dataByTimeInterval),
          lineConfig: {
            ...LINE_CONFIG,
            color: [colors.saphire[6], hexWithAlpha(colors.saphire[6], 0.5)],
            xAxis: {
              label: {
                formatter: timeIntervalFormat[timeInterval].format,
              },
            },
          },
        }
      }

      const dataTypes = new Set()

      const dataSource = Object.values(
        data.eventData.reduce((acc, { time, amount, ...groupByObject }) => {
          const date = dayjs(time).format(
            timeIntervalFormat[timeInterval].group
          )
          const [, groupByValue] = Object.entries(groupByObject)[0] ?? []
          const dataType = String(groupByValue)
          const key = `${date}-${dataType}`

          if (dataTypes.has(dataType) === false) {
            dataTypes.add(dataType)
          }

          acc[key] = acc[key] ?? { date, value: 0, dataType }
          acc[key].value += Number(amount)

          return acc
        }, {})
      )

      return {
        dataSource,
        lineConfig: {
          ...LINE_CONFIG,
          color: Array.from(dataTypes).map((_, i) => {
            if (i < lineColors.length) {
              return lineColors[i]
            }

            return hexWithAlpha(
              lineColors[i % lineColors.length],
              (getRandomInt(9) + 1) / 10
            )
          }),
          xAxis: {
            label: {
              formatter: timeIntervalFormat[timeInterval].format,
            },
          },
        },
      }
    },
    /**
     * We don't include groupBy as we only want to change the graph after the query has run.
     */
    [comparisonData, data, timeInterval]
  )

  return (
    <Spin spinning={isLoading}>
      <Line data={dataSource} {...lineConfig} />
    </Spin>
  )
}

const OptionsInput = ({
  onChange,
  isDisabled,
  options,
  placeholder,
  value,
  style,
}) => {
  const [internalValue, setInternalValue] = React.useState(value ? [value] : [])

  const onChangeInternal = (v) => {
    const nextValue = v[0] ? [v[v.length - 1]] : []

    setInternalValue(nextValue)
    onChange?.(nextValue[0])
  }

  React.useEffect(() => {
    if (value !== internalValue[0]) {
      setInternalValue([value])
    }
  }, [value])

  return (
    <Select
      disabled={isDisabled}
      mode="tags"
      maxTagCount="responsive"
      placeholder={placeholder}
      options={options}
      onChange={onChangeInternal}
      value={internalValue}
      style={{ ...style, fontSize: 12 }}
    />
  )
}

const operatorOptions = convertToOptions([
  '>',
  '<',
  '==',
  '!=',
  'IN',
  'NOT IN',
  'has',
  '!has',
  'IS NULL',
  'IS NOT NULL',
])

const QueryRow = ({
  entry,
  isDisabled,
  keyOptions,
  keyType,
  onChange,
  onPressRemove,
}) => {
  const [key, operator, values = ''] = entry

  const onChangeKey = (v) => {
    onChange?.([v, operator, values])
  }

  const onChangeOperator = (v) => {
    onChange?.([key, v, values])
  }

  const onChangeValues = (e) => {
    switch (keyType) {
      case 'DateTime':
        onChange?.([key, operator, e.unix()])
        break

      default:
        onChange?.([key, operator, e.target.value])
    }
  }

  const InputComponent = React.useMemo(() => {
    switch (true) {
      case keyType === 'DateTime' && ['>', '<', '==', '!='].includes(operator):
        return (props) => (
          <DatePicker
            {...props}
            showTime
            format="YYYY-MM-DD HH:mm"
            value={dayjs.unix(values)}
            // style={{ ...props.style, fontSize: 12 }}
          />
        )

      default:
        return Input
    }
  }, [keyType, operator, values])

  return (
    <Box flexDirection="row" marginBottom={12} alignItems="center">
      <Radio disabled={!key || key === 'event_time'} value={key} />
      <OptionsInput
        isDisabled={isDisabled}
        onChange={onChangeKey}
        options={keyOptions}
        placeholder="Key"
        value={key}
        style={{ width: '30%', marginRight: 12 }}
      />
      <OptionsInput
        isDisabled={isDisabled}
        onChange={onChangeOperator}
        placeholder="Operation"
        options={operatorOptions}
        value={operator}
        style={{ width: 70, marginRight: 12 }}
      />
      <InputComponent
        disabled={isDisabled}
        onChange={onChangeValues}
        placeholder="values"
        value={values}
        style={{ flex: 1 }}
      />
      <CloseOutlined
        onClick={onPressRemove}
        style={{
          fontSize: 12,
          marginLeft: 4,
          padding: 8,
          alignSelf: 'center',
        }}
      />
    </Box>
  )
}

const QueryBuilder = ({
  entries,
  groupBy,
  isDisabled,
  onChange,
  setGroupBy,
  tablePropsData,
}) => {
  const keyOptions = React.useMemo(() => {
    if (typeof tablePropsData === 'undefined') {
      return undefined
    }

    return convertToOptions(tablePropsData?.properties?.map(({ name }) => name))
  }, [tablePropsData])

  const onChangeRow = (index) => (row) => {
    const newEntries = [...entries]

    newEntries[index] = row

    onChange(newEntries)
  }

  const onPressRemoveRow = (index) => () => {
    if (entries[index]?.[0] === groupBy) {
      setGroupBy(null)
    }

    onChange((entries) => entries.filter((_, i) => i !== index))
  }

  const onSelectGroupBy = (ev) => {
    if (ev.target.value === groupBy) {
      setGroupBy(null)
    } else {
      setGroupBy(ev.target.value)
    }
  }

  React.useEffect(() => {
    // if the last entry is filled, add a new one
    const lastEntry = entries[entries.length - 1]

    if (typeof lastEntry?.[0] !== 'undefined') {
      onChange([...entries, []])
    }
  }, [entries])

  return (
    <>
      <Title level={5}>Query</Title>
      <Radio.Group
        disabled={isDisabled}
        value={groupBy}
        onChange={onSelectGroupBy}
        buttonStyle="solid"
        size="large">
        <Box marginBottom={12}>
          <Radio value={null}>No grouping</Radio>
        </Box>
        {entries.map((entry, index) => (
          <QueryRow
            key={index}
            onPressRemove={onPressRemoveRow(index)}
            isDisabled={isDisabled}
            entry={entry}
            keyOptions={keyOptions}
            keyType={
              tablePropsData?.properties?.find(({ name }) => name === entry[0])
                ?.type
            }
            onChange={onChangeRow(index)}
          />
        ))}
      </Radio.Group>
    </>
  )
}

const getChangedStartDate =
  ({ days, operator }) =>
  (filters) => {
    // filters look like this: [column, operator, value]

    const index = filters.findIndex(
      (f) => f[0] === 'event_time' && f[1] === operator
    )

    if (index === -1) {
      return filters
    }

    const dateUnix = filters[index][2]

    const dateAgo = dayjs.unix(dateUnix).subtract(days, 'day').unix()

    const newFilters = window.structuredClone(filters)

    newFilters[index][2] = dateAgo

    return newFilters
  }

const changeFilterToDaysAgo = (days, filters) =>
  compose(
    getChangedStartDate({ days, operator: '>' }),
    getChangedStartDate({ days, operator: '<' })
  )(filters)

export const PageClickhouseEvents = () => {
  const containerStyle = hooks.usePageContainerStyle()

  const prevTimeInterval = React.useRef(null)
  const prevSelectedTable = React.useRef(null)

  const [selectedTable, setSelectedTable] = React.useState(undefined)
  const [timeInterval, setTimeInterval] = React.useState('minute')
  const [entries, setEntries] = React.useState(defaultEntries)
  const [groupBy, setGroupBy] = React.useState(null)

  const { isLoading: isLoadingTables, data: availableEventNames } =
    useAnalyticsEventNamesQuery()

  const { isFetching: isLoadingProps, data: tablePropsData } =
    useAnalyticsEventPropsQuery({
      tableName: selectedTable,
    })

  const { data, isLoading: isLoadingData, mutate } = useChEventDataMutation()

  const { data: comparisonData, mutate: mutateComparison } =
    useChEventDataMutation()

  const onChangeTable = (v) => {
    setSelectedTable(v)
  }

  const onChangeTimeInterval = (e) => {
    setTimeInterval(e.target.value)
  }

  const onChangeEntries = (v) => {
    setEntries(v)
  }

  const onPressFetch = () => {
    const sanitisedEntries = entries.filter(
      (entry) =>
        entry.length === 3 && entry.every((v) => typeof v !== 'undefined')
    )

    const hasGroupBy = typeof groupBy === 'string'

    mutate({
      tableName: selectedTable,
      filters: sanitisedEntries,
      groupBy: hasGroupBy ? groupBy : undefined,
      interval: timeInterval,
    })

    if (hasGroupBy) {
      return
    }

    const comparisonFilters = changeFilterToDaysAgo(7, sanitisedEntries)

    mutateComparison({
      tableName: selectedTable,
      filters: comparisonFilters,
      interval: timeInterval,
    })
  }

  React.useEffect(() => {
    const hasChangedTimeInterval =
      typeof data !== 'undefined' && prevTimeInterval.current !== timeInterval

    const hasSetTable =
      prevSelectedTable.current === null && typeof selectedTable !== 'undefined'

    if (hasChangedTimeInterval) {
      prevTimeInterval.current = timeInterval
      onPressFetch()
    } else if (hasSetTable) {
      prevSelectedTable.current = selectedTable
      onPressFetch()
    }
  }, [data, timeInterval, prevTimeInterval, selectedTable])

  return (
    <View style={containerStyle}>
      <Box padding={32} backgroundColor={colors.background}>
        <Typography.Title>Clickhouse Events</Typography.Title>
        <Box flexDirection="row">
          <Box padding={12} flex={2} marginRight={24}>
            <Graph
              comparisonData={comparisonData}
              data={data}
              groupBy={groupBy}
              isLoading={isLoadingData}
              timeInterval={timeInterval}
            />
          </Box>

          <Box flex={1}>
            <Box marginBottom={24}>
              <Title level={5}>Table</Title>
              <OptionsInput
                isDisabled={isLoadingTables}
                placeholder="Select table..."
                options={availableEventNames}
                onChange={onChangeTable}
                value={selectedTable}
              />
            </Box>

            <Box marginBottom={48}>
              <Title level={5}>Config</Title>
              <Box flexDirection="row">
                <Radio.Group
                  onChange={onChangeTimeInterval}
                  optionType="button"
                  options={timeIntervalOptions}
                  value={timeInterval}
                />

                <Box flex={1} alignItems="flex-end">
                  <Button
                    disabled={typeof selectedTable === 'undefined'}
                    type="primary"
                    onClick={onPressFetch}>
                    Run Query
                  </Button>
                </Box>
              </Box>
            </Box>

            <Box marginBottom={24}>
              <Spin spinning={isLoadingProps}>
                <QueryBuilder
                  entries={entries}
                  groupBy={groupBy}
                  setGroupBy={setGroupBy}
                  isDisabled={typeof selectedTable === 'undefined'}
                  onChange={onChangeEntries}
                  onPressRun={onPressFetch}
                  tablePropsData={tablePropsData}
                />
              </Spin>
            </Box>
          </Box>
        </Box>
      </Box>
    </View>
  )
}
