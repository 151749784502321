import { Progress, Select, Table } from 'antd'
import React from 'react'
import { Box, Text } from '../../common'
import { convertToOptions } from '../../common/helpers'
import { useNotionDbQuery } from '../queries'

const styles = {
  dbId: {
    marginBottom: 24,
    width: '50%',
  },
  queryInput: {
    marginRight: 12,
    width: 200,
  },
}

const rowKey = (record) => record.id

const onRow = (record) => ({
  style: {
    fontWeight: record.name === 'Sum' ? 'bold' : 'normal',
  },
})

const dbOptions = [
  {
    label: 'Cycle I 2023',
    value: '4b6aa19844c6452c8ed38a677e9b44dd',
  },
]

const cycleOptions = convertToOptions([
  '2023 Cycle I',
  '2023 Cycle II',
  '2023 Cycle III',
])

const teamOptions = convertToOptions(['Dev', 'Product', 'Admin', 'Growth'])

const excludeStateOptions = convertToOptions([
  'Frozen',
  'Uncandidated',
  'Committed',
  'Candidate',
])

const PROGRESS_TYPE = {
  ALL: 'All',
  NOT_DONE: 'Not done',
  NOT_STARTED: 'Not started',
  DONE: 'Done',
}

const progressOptions = convertToOptions(Object.values(PROGRESS_TYPE))

const defaultQueryConfig = {
  dbId: [dbOptions[0].value],
  cycle: [cycleOptions[0].value],
  team: [teamOptions[0].value],
  excludeState: [excludeStateOptions[0].value],
  progress: progressOptions[0].value,
}

const BoldText = ({ name }) => (
  <Text semibold type="small">
    {name}
  </Text>
)

const ClickableName = (text, record) => {
  const onPressName = () => {
    if (typeof record.url !== 'undefined') {
      window.open(record.url, '_blank')
    }
  }

  return (
    <Text type="small" onPress={onPressName}>
      {text}
    </Text>
  )
}

const tableColumns = [
  {
    title: <BoldText name="Name" />,
    dataIndex: 'name',
    width: 120,
    render: ClickableName,
  },
  {
    title: <BoldText name="% Done" />,
    dataIndex: 'percentageDone',
    width: 80,
    render: (percentageDone) => <Progress percent={Number(percentageDone)} />,
  },
  {
    title: <BoldText name="T1 Effort" />,
    dataIndex: 't1Effort',
    width: 50,
  },
  {
    title: <BoldText name="T2 Effort" />,
    dataIndex: 't2Effort',
    width: 50,
  },
  {
    title: <BoldText name="T1 Days Left" />,
    dataIndex: 'daysLeftT1',
    width: 50,
  },
  {
    title: <BoldText name="T2 Days Left" />,
    dataIndex: 'daysLeftT2',
    width: 50,
  },
  {
    title: <BoldText name="Days Left Combined" />,
    dataIndex: 'daysLeftCombined',
    width: 50,
  },
]

const getDaysLeft = (effort, percentageDone) =>
  Math.floor(effort * (1 - percentageDone / 100) * 100) / 100

const filterRelevantProps = (data) => {
  const filteredData = data.reduce((acc, page) => {
    const {
      Name,
      'T1 Effort': T1_Effort,
      'T2 Effort': T2_Effort,
      '% Done': Percentage_Done,
    } = page.properties

    const name = Name.title[0].plain_text
    const t1Effort = Number(T1_Effort.number)
    const t2Effort = Number(T2_Effort.number)
    const percentageDone = Number(Percentage_Done.number)
    const daysLeftT1 = Number(getDaysLeft(t1Effort, percentageDone))
    const daysLeftT2 = Number(getDaysLeft(t2Effort, percentageDone))
    const daysLeftCombined = Number(
      getDaysLeft(t1Effort + t2Effort, percentageDone)
    )

    acc.push({
      id: page.id,
      name,
      t1Effort,
      t2Effort,
      percentageDone,
      daysLeftT1,
      daysLeftT2,
      daysLeftCombined,
      url: page.url,
    })

    return acc
  }, [])

  return filteredData
}

const getSumRow = (data) => {
  const sumRow = data.reduce(
    (acc, curr) => {
      acc.t1Effort = (acc.t1Effort ?? 0) + curr.t1Effort
      acc.t2Effort = (acc.t2Effort ?? 0) + curr.t2Effort
      acc.daysLeftT1 = (acc.daysLeftT1 ?? 0) + curr.daysLeftT1
      acc.daysLeftT2 = (acc.daysLeftT2 ?? 0) + curr.daysLeftT2
      acc.daysLeftCombined = (acc.daysLeftCombined ?? 0) + curr.daysLeftCombined

      return acc
    },
    { id: 'sum', name: 'Sum' }
  )

  const totalEffort = sumRow.t1Effort + sumRow.t2Effort

  sumRow.percentageDone = Math.floor(
    ((totalEffort - sumRow.daysLeftCombined) / totalEffort) * 100
  )

  return sumRow
}

const getTableData = (data) => {
  if (typeof data === 'undefined') {
    return []
  }

  const processedData = filterRelevantProps(data)
  const sumRow = getSumRow(processedData)

  return [...processedData, sumRow]
}

const buildQueryConfig = (config) => {
  const dbId = config.dbId?.[0]
  const team = config.team?.[0]
  const cycle = config.cycle?.[0]
  const excludeState = config.excludeState?.[0]
  const { progress } = config

  const sorts = [
    {
      property: 'Prio',
      direction: 'ascending',
    },
  ]

  const filters = []

  if (typeof team !== 'undefined') {
    filters.push({
      property: 'Team',
      select: {
        equals: team,
      },
    })
  }

  if (typeof cycle !== 'undefined') {
    filters.push({
      property: 'Cycle',
      select: {
        equals: cycle,
      },
    })
  }

  if (typeof excludeState !== 'undefined') {
    filters.push({
      property: 'Planning State',
      select: {
        does_not_equal: excludeState,
      },
    })
  }

  if (typeof progress !== 'undefined') {
    switch (progress) {
      case PROGRESS_TYPE.NOT_DONE: {
        filters.push({
          property: '% Done',
          number: {
            less_than: 100,
          },
        })
        break
      }

      case PROGRESS_TYPE.DONE: {
        filters.push({
          property: '% Done',
          number: {
            equals: 100,
          },
        })
        break
      }

      case PROGRESS_TYPE.NOT_STARTED: {
        filters.push({
          property: '% Done',
          number: {
            equals: 0,
          },
        })
        break
      }

      case PROGRESS_TYPE.ALL:
      default:
        break
    }
  }

  if (filters.length > 0) {
    return { database_id: dbId, sorts, filter: { and: filters } }
  }

  return { database_id: dbId, sorts }
}

/**
 * Select dropdown that only allows predefined values
 */
const SelectWithLabel = (props) => (
  <Box>
    <Text semibold type="small" style={styles.label}>
      {props.label}
    </Text>
    <Select style={styles.queryInput} {...props} />
  </Box>
)

/**
 * Select dropdown that also allows custom value by typing
 */
const SelectInputWithLabel = (props) => (
  <Box>
    <Text semibold type="small" style={styles.label}>
      {props.label}
    </Text>
    <Select mode="tags" maxTagCount={1} style={styles.queryInput} {...props} />
  </Box>
)

export const TabCyclePlanning = () => {
  const [queryConfig, setQueryConfig] = React.useState(defaultQueryConfig)

  const processedQueryConfig = React.useMemo(
    () => buildQueryConfig(queryConfig),
    [queryConfig]
  )

  const { data, isFetching } = useNotionDbQuery(processedQueryConfig)

  const tableData = React.useMemo(() => getTableData(data), [data])

  const onChangeQueryConfig = (key) => (value) => {
    // We only want to have 1 value for database_id so we take the latest input
    const lastItemInArray = value.slice(-1)

    setQueryConfig({ ...queryConfig, [key]: lastItemInArray })
  }

  const onChangeProgress = (progress) => {
    setQueryConfig({ ...queryConfig, progress })
  }

  return (
    <Box>
      <SelectInputWithLabel
        label="Notion database id"
        onChange={onChangeQueryConfig('dbId')}
        options={dbOptions}
        value={queryConfig.dbId}
        style={styles.dbId}
      />

      <Box flexDirection="row" marginBottom={36}>
        <SelectInputWithLabel
          label="Cycle"
          onChange={onChangeQueryConfig('cycle')}
          options={cycleOptions}
          value={queryConfig.cycle}
        />
        <SelectInputWithLabel
          label="Team"
          onChange={onChangeQueryConfig('team')}
          options={teamOptions}
          value={queryConfig.team}
        />
        <SelectInputWithLabel
          label="Exclude state"
          onChange={onChangeQueryConfig('excludeState')}
          options={excludeStateOptions}
          value={queryConfig.excludeState}
        />
        <SelectWithLabel
          label="Progress"
          onChange={onChangeProgress}
          options={progressOptions}
          value={queryConfig.progress}
        />
      </Box>

      <Table
        columns={tableColumns}
        dataSource={tableData}
        loading={isFetching}
        rowKey={rowKey}
        scroll={{ x: 1 }}
        sticky={{ offsetHeader: 50 }}
        pagination={false}
        onRow={onRow}
      />
    </Box>
  )
}
