import { prop } from 'ramda'
import { createSelector } from 'reselect'
import { name } from './constants'

const getState = prop('dashboards')

export const getRecentAnswers = createSelector(getState, prop('recentAnswers'))

export const get_rerenderItem = (state) =>
  state[name].recentAnswers._rerenderItem

export const getIsRefreshing = (state) => state[name].recentAnswers.isRefreshing

export const getIsFetching = (state) => state[name].recentAnswers.isFetching

export const getHasMore = (state) => state[name].recentAnswers.hasMore

export const getRecentAnswersData = createSelector(
  getRecentAnswers,
  prop('data')
)
