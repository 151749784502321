import { ARTIFICIAL_TELL_VARIANCE_STATUS } from '@tellonym/enums/lib/Tell'
import { compose, mergeRight, omit, pick, prop, propOr } from 'ramda'
import { createSelector } from 'reselect'
import { name } from './constants'
import { groupInitialState } from './reducer'

const emptyDataIds = { data: {}, ids: [] }

const getState = (state) => state[name]

export const getError = createSelector(getState, prop('error'))

export const getHasSearchedGroups = createSelector(
  getState,
  prop('hasSearchedGroups')
)

export const getIsSaving = createSelector(getState, prop('isSaving'))

export const getSelectedGroupId = createSelector(
  getState,
  prop('selectedGroupId')
)

export const getSelectedLanguage = createSelector(
  getState,
  prop('selectedLanguage')
)

const getAnswers = createSelector(getState, prop('answers'))

export const getIsRefreshingAnswers = (state) =>
  state[name].answers.isRefreshing

export const getIsFetchingAnswers = (state) => state[name].answers.isFetching

export const getHasMoreAnswers = (state) => state[name].answers.hasMore

export const getAnswersData = createSelector(getAnswers, prop('data'))

export const getAnswersArtificialTellInfo = createSelector(
  getAnswers,
  prop('artificialTell')
)

const getGroups = createSelector(getState, prop('groups'))

const getEnGroupsData = createSelector(
  getGroups,
  compose(pick(['data', 'ids']), propOr(emptyDataIds, 'en'))
)

const getDeGroupsData = createSelector(
  getGroups,
  compose(pick(['data', 'ids']), propOr(emptyDataIds, 'de'))
)

/**
 * Artificial Tells
 */

const getArtTells = createSelector(getState, prop('artificialTells'))

export const getHasSearched = createSelector(getState, prop('hasSearched'))

export const getHasMoreArtTells = createSelector(getArtTells, prop('hasMore'))

export const getIsRefreshingArtTells = createSelector(
  getArtTells,
  prop('isRefreshing')
)

export const getIsFetchingArtTells = createSelector(
  getArtTells,
  prop('isFetching')
)

export const getSearchStringArtTells = createSelector(
  getArtTells,
  prop('searchString')
)

export const getDataArtTells = createSelector(getArtTells, prop('data'))

const getGroupsForCurrentLanguage = createSelector(
  getSelectedLanguage,
  getGroups,
  compose(mergeRight(groupInitialState), prop)
)

export const getGroupsOrderTypes = createSelector(getGroups, prop('orderTypes'))

export const getHasMoreGroups = createSelector(
  getGroupsForCurrentLanguage,
  prop('hasMore')
)

export const getHasRefreshedGroups = createSelector(
  getGroupsForCurrentLanguage,
  propOr(false, 'hasRefreshed')
)

export const getIsRefreshingGroups = createSelector(
  getGroupsForCurrentLanguage,
  prop('isRefreshing')
)

export const getIsFetchingGroups = createSelector(
  getGroupsForCurrentLanguage,
  prop('isFetching')
)

export const getGroupsData = createSelector(
  getGroupsForCurrentLanguage,
  pick(['data', 'ids'])
)

export const getGroupsSuggestions = createSelector(
  getGroupsForCurrentLanguage,
  prop('suggestions')
)

export const getHasMoreGroupSuggestions = createSelector(
  getGroupsSuggestions,
  prop('hasMore')
)

export const getGroupsSuggestionsAmount = createSelector(
  getGroupsSuggestions,
  prop('amount')
)

const getGroupDetails = createSelector(
  getSelectedGroupId,
  getGroupsData,
  (groupId, groups) => mergeRight(groupInitialState, groups.data[groupId])
)

export const getGroupDetailsData = createSelector(
  getGroupDetails,
  compose(mergeRight(emptyDataIds), pick(['data', 'ids']))
)

export const getGroupDetailsInfo = createSelector(
  getGroupDetails,
  omit(['data', 'ids', 'hasMore', 'isFetching', 'isRefreshing'])
)

export const getHasMoreGroupDetails = createSelector(
  getGroupDetails,
  prop('hasMore')
)

export const getIsRefreshingGroupDetails = createSelector(
  getGroupDetails,
  prop('isRefreshing')
)

export const getIsFetchingGroupDetails = createSelector(
  getGroupDetails,
  prop('isFetching')
)

export const getGroupDetailsSuggestions = createSelector(
  getSelectedLanguage,
  getSelectedGroupId,
  getEnGroupsData,
  getDeGroupsData,
  (language, groupId, groupsEn, groupsDe) => {
    const filterDeclinedAndDeletedSuggestions = (suggestions) => {
      if (!suggestions || suggestions.length === 0) {
        return []
      }

      return Object.keys(suggestions).reduce((acc, sugId) => {
        const item = suggestions[sugId]

        if (item.type === ARTIFICIAL_TELL_VARIANCE_STATUS.DECLINED) {
          return acc
        }

        return [...acc, item.id]
      }, [])
    }

    if (language === 'en') {
      const _deGroupDataFiltered =
        groupsDe &&
        groupsDe.data &&
        groupsDe.data[groupId] &&
        groupsDe.data[groupId].data
          ? {
              ...groupsDe.data[groupId],
              ids: filterDeclinedAndDeletedSuggestions(
                groupsDe.data[groupId].data
              ),
            }
          : {}

      return mergeRight(groupInitialState, _deGroupDataFiltered)
    }

    const _enGroupDataFiltered =
      groupsEn &&
      groupsEn.data &&
      groupsEn.data[groupId] &&
      groupsEn.data[groupId].data
        ? {
            ...groupsEn.data[groupId],
            ids: filterDeclinedAndDeletedSuggestions(
              groupsEn.data[groupId].data
            ),
          }
        : {}

    return mergeRight(groupInitialState, _enGroupDataFiltered)
  }
)

const getArtTellGroups = createSelector(getState, prop('artificialTellGroups'))

export const getIsRefreshingResult = (state) =>
  state[name].artificialTellGroups.isRefreshing

export const getIsFetchingResult = (state) =>
  state[name].artificialTellGroups.isFetching

export const getHasMoreResult = (state) =>
  state[name].artificialTellGroups.hasMore

export const getArtTellGroupsData = createSelector(
  getArtTellGroups,
  prop('data')
)

export const getSearchString = createSelector(
  getArtTellGroups,
  prop('searchString')
)

export const getSearchTags = createSelector(
  getArtTellGroups,
  prop('searchTags')
)

export const getIsRefreshingTags = (state) => state[name].tags.isRefreshing

export const getIsFetchingTags = (state) => state[name].tags.isFetching

export const getHasMoreTags = (state) => state[name].tags.hasMore

export const getTagArray = (state) => state[name].tags.tags ?? []

export const getTagGroups = (state) => state[name].tags.groups

export const getSelectedTagIds = (state) => state[name].tags.selectedTagIds

export const getTableState = (state) => state[name].table

export const getTagNames = createSelector(getTagArray, (tags) =>
  tags.map(({ tag }) => tag)
)
