import { Button, Input, Spin, Typography, message } from 'antd'
import { compose } from 'ramda'
import React from 'react'
import { Box, Link, Text, View, colors } from '../../common'
import { ProfileCardUser } from '../../common/components/ProfileCardUser'
import * as hooks from '../../common/hooks'
import { useProfilesMutation } from '../../profile/queries'

const styles = {
  textarea: { maxWidth: '60%' },
  typeCounterText: { marginTop: 2, marginLeft: 12 },
}

const parseInput = (string) => {
  try {
    const regex = /[\n, ]+/

    const numberStrings = string
      .replace(/"/gm, '')
      .split(regex)
      .filter(Boolean)
      .filter((s) => !isNaN(s)) // eslint-disable-line no-restricted-globals

    const numbers = numberStrings.map(Number)

    return numbers ?? []
  } catch (e) {
    console.error(e)

    message.error(e.message)

    return []
  }
}

const stringifyInput = (array) => array?.join(', ')

const sanitizeInput = compose(stringifyInput, parseInput)

const defaultQueryParams = {
  userIds: '',
}

export const PageProfileVisualiser = () => {
  const containerStyle = hooks.usePageContainerStyle()
  const prevTextInput = React.useRef('')
  const [params, setQueryParams] = hooks.useQueryParams(defaultQueryParams, 300)
  const [textInput, setTextInput] = React.useState(params.userIds)

  const { isLoading, mutate, data } = useProfilesMutation()

  const onPressRefresh = React.useCallback(() => {
    if (textInput === '') {
      return
    }

    const userIds = parseInput(textInput)

    mutate({ userIds })
  }, [mutate, textInput])

  const onChangeInput = (e) => {
    setTextInput(e.target.value)
  }

  React.useEffect(() => {
    const isBulkInsert =
      Math.abs((prevTextInput.current?.length ?? 0) - textInput.length) > 3

    if (isBulkInsert) {
      onPressRefresh()
    }

    if (prevTextInput.current !== textInput) {
      prevTextInput.current = textInput

      setQueryParams({ userIds: sanitizeInput(textInput) })
    }
  }, [onPressRefresh, setQueryParams, textInput])

  const inputIdsCount = React.useMemo(
    () =>
      params.userIds === '' ? '-' : parseInput(params.userIds)?.length ?? 0,
    [params.userIds]
  )

  return (
    <View style={containerStyle}>
      <Box padding={16} backgroundColor={colors.background}>
        <Typography.Title>Profile Visualiser</Typography.Title>
        <Box marginTop={24} flexDirection="row">
          <Input.TextArea
            allowClear
            onChange={onChangeInput}
            placeholder="Insert user ids to be visualised. Can be separated by comma, space or line breaks."
            rows={8}
            value={textInput}
            style={styles.textarea}
          />
          <Box justifyContent="flex-end" marginLeft={8}>
            <Button type="primary" onClick={onPressRefresh}>
              Refresh
            </Button>
          </Box>
        </Box>

        <Box marginTop={48}>
          <Spin size="large" spinning={isLoading}>
            <Box flexDirection="row">
              <Typography.Title level={4}>
                Results {`(${data?.users?.length ?? '-'} / ${inputIdsCount})`}
              </Typography.Title>
              <Text style={styles.typeCounterText}>{`banned: ${
                data?.bannedCount ?? '-'
              }`}</Text>
              <Text style={styles.typeCounterText}>{`deleted: ${
                data?.deletedCount ?? '-'
              }`}</Text>
            </Box>
            <Box flexDirection="row" flexWrap="wrap">
              {data?.users?.map?.((profile) => (
                <Box key={profile.id} marginRight={24} marginBottom={24}>
                  <Link to={`/user/${profile.id}`}>
                    <ProfileCardUser user={profile} />
                  </Link>
                </Box>
              ))}
            </Box>
          </Spin>
        </Box>
      </Box>
    </View>
  )
}
