import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons'
import { Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { config } from '../../../config'
import {
  FlatList,
  history,
  Icon,
  styleSheets,
  Text,
  TextTranslatable,
  theme,
  TouchableOpacity,
  View,
} from '../../common'
import { fetchAnswers, refreshAnswers } from '../actions'
import {
  getAnswersArtificialTellInfo,
  getAnswersData,
  getHasMoreAnswers,
  getIsFetchingAnswers,
  getIsRefreshingAnswers,
} from '../selectors'
import { ButtonBack } from './ButtonBack'

const styles = {
  tableHeadContainer: {
    flexDirection: 'row',
    backgroundColor: theme.colors.gray98,
    borderWidth: 0,
    borderBottomWidth: 1,
    borderColor: theme.colors.borderGrey,
    borderStyle: 'solid',
    paddingTop: 16,
    paddingBottom: 16,
  },
  tableHeadCell: {
    borderWidth: 0,
    borderLeftWidth: 1,
    borderColor: theme.colors.borderGrey,
    borderStyle: 'solid',
    paddingRight: 16,
    paddingLeft: 16,
    fontWeight: '500',
  },
  tableItemContainer: {
    flexDirection: 'row',
    backgroundColor: theme.colors.background,
    borderWidth: 0,
    borderColor: theme.colors.borderLightGrey,
    borderStyle: 'solid',
    paddingVertical: 12,
    onHover: { backgroundColor: theme.colors.gray98 },
  },
  tableItemCell: {
    paddingLeft: 16,
    paddingRight: 16,
  },
}

const GroupHeader = ({
  artificialTell,
  onChangeShouldTranslate,
  shouldTranslate,
}) => (
  <React.Fragment>
    <View style={{ marginBottom: 12, paddingHorizontal: 20, marginTop: 8 }}>
      <ButtonBack onPress={() => history.goBack()} />
    </View>
    <View
      style={{
        backgroundColor: theme.colors.background,
        padding: 24,
        marginBottom: 12,
        flexDirection: 'row',
      }}>
      <View style={styleSheets.flex[1]}>
        <Text
          color={theme.colors.placeholder}
          onPress={() =>
            history.push(
              `/artificialtells/${artificialTell.language}/group/${artificialTell.groupId}`
            )
          }
          style={{
            fontStyle: 'italic',
            marginBottom: 4,
            alignSelf: 'flex-start',
            onHover: { color: theme.colors.primary },
          }}>
          {artificialTell.groupName}
        </Text>
        <Text bold type="h1" style={styleSheets.margin.bottom[12]}>
          {artificialTell.id}
        </Text>
        <Text>{artificialTell.content}</Text>
      </View>
    </View>
    <View style={styles.tableHeadContainer}>
      <Text center style={[styles.tableHeadCell, styles.flex1]}>
        Id
      </Text>
      <Text style={[styles.tableHeadCell, styles.flex6]}>Content</Text>
      <View
        style={[
          styles.tableHeadCell,
          styleSheets.flex[1],
          { justifyContent: 'space-between', flexDirection: 'row' },
        ]}>
        <Text center>Translate</Text>
        <Switch
          checked={shouldTranslate}
          checkedChildren={<Icon icon={faGlobeEurope} />}
          unCheckedChildren={<Icon icon={faGlobeEurope} />}
          onChange={onChangeShouldTranslate}
        />
      </View>
    </View>
  </React.Fragment>
)

const Item = ({ hasSeparator, item, shouldTranslate: shouldTranslateAll }) => {
  const [shouldTranslate, setShouldTranslate] = useState(shouldTranslateAll)
  const [isLoadingTranslation, setIsLoadingTranslation] = useState(false)

  const onChange = (isChecked, e) => {
    e.stopPropagation()
    setShouldTranslate(isChecked)
  }

  const onLoadTranslation = (isLoading) => {
    setIsLoadingTranslation(isLoading)
  }

  const onPress = () => {
    window.location = `${config.webUrl}/unknown/answer/${item.id}`
  }

  const preventOnPress = (e) => {
    e.stopPropagation()
  }

  useEffect(() => {
    setShouldTranslate(shouldTranslateAll)
  }, [shouldTranslateAll])

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        styles.tableItemContainer,
        { borderBottomWidth: hasSeparator ? 1 : 0 },
      ]}>
      <Text
        type="small"
        center
        style={[styles.tableItemCell, styleSheets.flex[1]]}>
        {item.id}
      </Text>
      <TextTranslatable.Pure
        onLoadChange={onLoadTranslation}
        shouldTranslate={shouldTranslate}
        style={[styles.tableItemCell, styleSheets.flex[6]]}>
        {item.content}
      </TextTranslatable.Pure>
      <View
        onPress={preventOnPress}
        style={[
          styles.tableItemCell,
          styleSheets.flex[1],
          { alignItems: 'flex-end' },
        ]}>
        <Switch
          checked={shouldTranslate}
          checkedChildren={<Icon icon={faGlobeEurope} />}
          unCheckedChildren={<Icon icon={faGlobeEurope} />}
          loading={isLoadingTranslation}
          onClick={onChange}
        />
      </View>
    </TouchableOpacity>
  )
}

const _PageArtificialTellsAnswers = ({ artificialTell, match, ...props }) => {
  const [shouldTranslate, setShouldTranslate] = useState(false)
  const { artificialTellId } = match.params || {}

  const onChangeShouldTranslate = (isChecked) => {
    setShouldTranslate(isChecked)
  }

  useEffect(() => {
    props.actions.refresh({ artificialTellId })
  }, [artificialTellId])

  return (
    <FlatList
      _rerenderItem={props._rerenderItem}
      actions={{
        fetch: (payload) => {
          props.actions.fetch({ artificialTellId, ...payload })
        },
        refresh: (payload) =>
          props.actions.refresh({
            artificialTellId,
            ...payload,
          }),
      }}
      component={Item}
      extraData={{
        hasMore: props.hasMore,
        isFetching: props.isFetching,
        isRefreshing: props.isRefreshing,
      }}
      extraProps={{ shouldTranslate }}
      hasMore={props.hasMore}
      isFetching={props.isFetching}
      isRefreshing={props.isRefreshing}
      items={props.data}
      onEndReachedThreshold={0.5}
      renderHeader={(props) => (
        <GroupHeader
          artificialTell={artificialTell}
          onChangeShouldTranslate={onChangeShouldTranslate}
          {...props}
        />
      )}
    />
  )
}

const mapStateToProps = createStructuredSelector({
  artificialTell: getAnswersArtificialTellInfo,
  data: getAnswersData,
  hasMore: getHasMoreAnswers,
  isFetching: getIsFetchingAnswers,
  isRefreshing: getIsRefreshingAnswers,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetch: fetchAnswers,
      refresh: refreshAnswers,
    },
    dispatch
  ),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const PageArtificialTellsAnswers = withConnect(
  _PageArtificialTellsAnswers
)
