import { config } from '../config'

export const log = (...params) => {
  if (!__DEV__) {
    return
  }

  console.log(...params) // eslint-disable-line no-console
}

export const pretty = (...params) => {
  if (!__DEV__) {
    return
  }

  console.log(...params.map((param) => JSON.stringify(param, null, 2))) // eslint-disable-line no-console
}

class CustomError extends Error {
  constructor(message, payload) {
    super(message)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError)
    }

    this.name = 'CustomError'
    this.payload = payload
  }
}

/**
 * @param {exception|string} e exception to be logged to sentry
 * @param {object.<string, string|number>} [extras] object of additional data to be logged to this error
 */
export const capture = (e, extras) => {
  if (!e) {
    return
  }

  const error = typeof e === 'string' ? new CustomError(e) : e

  if (error instanceof Error === false) {
    return
  }

  const isRateLimited =
    Boolean(extras?.sampleRate) && extras.sampleRate < Math.random()

  if (typeof config.underscore.onCapture === 'function') {
    config.underscore.onCapture(error, {
      ...extras,
      isRateLimited,
    })
  }

  if (__DEV__ || isRateLimited) {
    return
  }

  config.underscore.capture(e, extras)
}

/**
 * @param {object|string} breadcrumb
 */
export const crumb = (breadcrumb) => {
  let payload = breadcrumb

  if (typeof payload === 'string') {
    payload = {
      category: 'generic',
      message: payload,
    }
  }

  config.underscore.crumb(payload)
}
